import React from "react";

const MagicWandIconPurple = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98078 6.24228C9.28442 6.13717 9.28442 5.95031 8.98078 5.83353L7.33413 5.20289C7.04217 5.09778 6.7035 4.7591 6.58672 4.45546L5.95609 2.80879C5.85098 2.50514 5.66413 2.50514 5.54734 2.80879L4.91671 4.45546C4.81161 4.74742 4.47294 5.0861 4.1693 5.20289L2.52265 5.83353C2.21901 5.93864 2.21901 6.12549 2.52265 6.24228L4.1693 6.87292C4.46126 6.97803 4.79993 7.3167 4.91671 7.62035L5.54734 9.26702C5.65245 9.57066 5.8393 9.57066 5.95609 9.26702L6.58672 7.62035C6.69182 7.32838 7.03049 6.9897 7.33413 6.87292L8.98078 6.24228ZM17.0042 12.0116C17.9385 11.778 17.9385 11.3926 17.0042 11.159L14.9372 10.6452C14.0029 10.4116 13.0453 9.45396 12.8117 8.51967L12.2979 6.45257C12.0643 5.51829 11.6789 5.51829 11.4453 6.45257L10.9315 8.51967C10.6979 9.45396 9.74029 10.4116 8.80602 10.6452L6.73895 11.159C5.80469 11.3926 5.80469 11.778 6.73895 12.0116L8.80602 12.5254C9.74029 12.759 10.6979 13.7166 10.9315 14.6509L11.4453 16.718C11.6789 17.6523 12.0643 17.6523 12.2979 16.718L12.8117 14.6509C13.0453 13.7166 14.0029 12.759 14.9372 12.5254L17.0042 12.0116Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default MagicWandIconPurple;
