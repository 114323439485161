import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import BathroomIcon from "./Icons/hero-icons/BathroomIcon.jsx";
import BedIcon from "./Icons/hero-icons/BedIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";

// Images
import Bouchra from "./Images/Bouchra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish2.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish2.png";
import BeforeBedroomStaging from "./Images/curtain-effect-hero/BeforeBedroomStaging.png";
import AfterBedroomStaging from "./Images/curtain-effect-hero/AfterBedroomStaging.png";
import BeforeTerraceStaging from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterTerraceStaging from "./Images/curtain-effect-hero/AfterTerraceStaging.png";
import BeforeBathroomStaging from "./Images/curtain-effect-hero/BeforeBathroomStaging.png";
import AfterBathroomStaging from "./Images/curtain-effect-hero/AfterBathroomStaging.png";
import BeforeStagingExample1 from "./Images/curtain-effect/BeforeStagingExample1.png";
import AfterStagingExample1 from "./Images/curtain-effect/AfterStagingExample1.png";
import BeforeStagingExample2 from "./Images/curtain-effect/BeforeStagingExample2.png";
import AfterStagingExample2 from "./Images/curtain-effect/AfterStagingExample2.png";
import BeforeStagingExample3 from "./Images/curtain-effect/BeforeStagingExample3.png";
import AfterStagingExample3 from "./Images/curtain-effect/AfterStagingExample3.png";
import BeforeStagingExample4 from "./Images/curtain-effect/BeforeStagingExample4.png";
import AfterStagingExample4 from "./Images/curtain-effect/AfterStagingExample4.png";
import BeforeStagingExample5 from "./Images/curtain-effect/BeforeStagingExample5.png";
import AfterStagingExample5 from "./Images/curtain-effect/AfterStagingExample5.png";
import BeforeStagingExample6 from "./Images/curtain-effect/BeforeStagingExample6.png";
import AfterStagingExample6 from "./Images/curtain-effect/AfterStagingExample6.png";
import BeforeStagingExample7 from "./Images/curtain-effect/BeforeStagingExample7.png";
import AfterStagingExample7 from "./Images/curtain-effect/AfterStagingExample7.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStaging() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);

  // Images in the staging examples hero

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
    } else if (example === "Bedroom") {
      setExample({
        name: "Bedroom",
        beforeImage: BeforeBedroomStaging,
        afterImage: AfterBedroomStaging,
      });
    } else if (example === "Terrace") {
      setExample({
        name: "Terrace",
        beforeImage: BeforeTerraceStaging,
        afterImage: AfterTerraceStaging,
      });
    } else {
      setExample({
        name: "Bathroom",
        beforeImage: BeforeBathroomStaging,
        afterImage: AfterBathroomStaging,
      });
    }
  };

  // Images in the staging examples body

  const stagingExamples = [
    {
      name: "stagingExample1",
      image: BeforeStagingExample1,
    },
    {
      name: "stagingExample2",
      image: BeforeStagingExample2,
    },
    {
      name: "stagingExample3",
      image: BeforeStagingExample3,
    },
    {
      name: "stagingExample4",
      image: BeforeStagingExample4,
    },
    {
      name: "stagingExample5",
      image: BeforeStagingExample5,
    },
    {
      name: "stagingExample6",
      image: BeforeStagingExample6,
    },
    {
      name: "stagingExample7",
      image: BeforeStagingExample7,
    },
  ];

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeStagingExample1,
    afterImage: AfterStagingExample1,
  });

  const handleStagingExample = (example) => {
    if (example === "stagingExample1") {
      setStagingExample({
        name: "stagingExample1",
        beforeImage: BeforeStagingExample1,
        afterImage: AfterStagingExample1,
      });
    } else if (example === "stagingExample2") {
      setStagingExample({
        name: "stagingExample2",
        beforeImage: BeforeStagingExample2,
        afterImage: AfterStagingExample2,
      });
    } else if (example === "stagingExample3") {
      setStagingExample({
        name: "stagingExample3",
        beforeImage: BeforeStagingExample3,
        afterImage: AfterStagingExample3,
      });
    } else if (example === "stagingExample4") {
      setStagingExample({
        name: "stagingExample4",
        beforeImage: BeforeStagingExample4,
        afterImage: AfterStagingExample4,
      });
    } else if (example === "stagingExample5") {
      setStagingExample({
        name: "stagingExample5",
        beforeImage: BeforeStagingExample5,
        afterImage: AfterStagingExample5,
      });
    } else if (example === "stagingExample6") {
      setStagingExample({
        name: "stagingExample6",
        beforeImage: BeforeStagingExample6,
        afterImage: AfterStagingExample6,
      });
    } else if (example === "stagingExample7") {
      setStagingExample({
        name: "stagingExample7",
        beforeImage: BeforeStagingExample7,
        afterImage: AfterStagingExample7,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "O que é home staging virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "O home staging virtual é uma técnica de marketing imobiliário que utiliza ferramentas digitais para preparar e apresentar uma propriedade de forma atrativa para potenciais compradores ou inquilinos. Diferente do home staging tradicional, que envolve mudanças físicas na propriedade, o home staging virtual é feito por meio de edição de imagens e renderização digital para melhorar a aparência dos ambientes.",
        },
      },
      {
        "@type": "Question",
        name: "O que consiste o home staging virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: `O home staging virtual consiste em editar imagens da propriedade usando ferramentas digitais. Isso inclui:

- Edição de imagens: Retocar e melhorar fotos usando IA, removendo objetos indesejados, ajustando iluminação e adicionando móveis ou decoração virtual.
- Renderização de design: Criar representações digitais de como a propriedade poderia parecer quando decorada ou renovada.
- Economia de tempo e custos: É mais rápido e econômico do que o home staging tradicional, pois não exige mudanças físicas na propriedade.`,
        },
      },
      {
        "@type": "Question",
        name: "Quais são as etapas do processo de Home Staging Virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: `O processo de home staging virtual inclui as seguintes etapas:

1. Enviar as fotos do projeto.
2. Selecionar opções de edição de home staging virtual, como esvaziar os ambientes ou adicionar móveis virtuais.
3. Baixar o projeto após as alterações serem feitas.`,
        },
      },
      {
        "@type": "Question",
        name: "Quanto tempo leva para concluir um projeto de Home Staging Virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "O tempo para concluir um projeto de home staging virtual depende do número de imagens e do tempo necessário para editá-las. No entanto, o envio dos arquivos e o download dos projetos são rápidos, e os resultados são obtidos instantaneamente.",
        },
      },
      {
        "@type": "Question",
        name: "O Home Staging Virtual pode ser feito em propriedades vazias?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Sim! O home staging virtual é ideal para propriedades vazias. Você pode mobiliar os ambientes com IA ou manualmente, adicionando os móveis que desejar, o que ajuda a visualizar o potencial dos espaços e melhora a apresentação da propriedade.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home staging virtual</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  O melhor home staging virtual ao seu alcance
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rápido:</strong> Resultados em segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Fácil:</strong> Intuitivo desde o início
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualidade:</strong> Resultados hiper-realistas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Ajuda:</strong> Assistência humana quando necessário
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Experimente Pedra grátis <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        1 imagem grátis
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Diretor de Fotografia na aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Corretor de Imóveis na Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO na Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO na Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Mais de 100 depoimentos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Antes do home staging virtual"}
                  altAfter={"Depois do home staging virtual"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Sala de Estar
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bedroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bedroom")}
                    >
                      <BedIcon />
                      Quarto
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Bathroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bathroom")}
                    >
                      <BathroomIcon />
                      Banheiro
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Terrace" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Terrace")}
                    >
                      <PurpleEnhanceIcon />
                      Terraço
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MAIS DE 20.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtual para{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            vender mais rápido
          </span>{" "}
          qualquer tipo de imóvel.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Mobiliar virtualmente em 1 clique
                </h3>
                <div className="feature-text">
                  Crie home staging automático em 1 clique
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Legendas</h3>
                <div className="feature-text">
                  Indique que sua imagem é um home staging virtual com seu
                  próprio texto
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Melhore e corrija fotos</h3>
                <div className="feature-text">
                  Aprimore a qualidade e perspectiva das fotos para um melhor
                  home staging virtual
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Adicione seus móveis</h3>
                <div className="feature-text">
                  Adicione personalidade incluindo seus próprios móveis no home
                  staging virtual
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renove virtualmente</h3>
                <div className="feature-text">
                  Crie propostas de renovação para seus imóveis usados
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Apague objetos ou espaços vazios
                </h3>
                <div className="feature-text">
                  Remova objetos ou marcas d'água para um home staging virtual
                  mais limpo
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemplos do nosso home staging virtual.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Antes do home staging virtual"}
            altAfter={"Depois do home staging virtual"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            Mais de 100
          </span>{" "}
          depoimentos sobre nosso home staging virtual.
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Diretor de Fotografia na aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Diretor de Fotografia na aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            A Pedra está indo muito bem. Acho que o melhor da ferramenta é{" "}
            <span className="text-highlight">
              a velocidade com que gera as imagens.
            </span>
          </q>
          <div className="testimonial-author-container-date">
            3 de julho de 2024
          </div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Consultora Imobiliária na iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Consultora Imobiliária na iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Pedra é a descoberta do século
            </span>
          </q>
          <div className="testimonial-author-container-date">
            3 de julho de 2024
          </div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Agente Imobiliário na iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Agente Imobiliário na iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Impressive.
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Além disso,{" "}
              <span className="text-highlight">
                O espaço específico que eu queria esvaziar parecia mais
                complicado porque era profundo, mas fez isso de forma brilhante.
              </span>
            </div>
          </q>
          <div className="testimonial-author-container-date">
            26 de junho de 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Gerente na Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Gerente na Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Algumas casas foram vendidas graças ao Pedra.
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              Coloquei propostas de como o terraço poderia ser mobiliado na foto
              de capa e muitas pessoas começaram a clicar no anúncio
            </div>
          </q>
          <div className="testimonial-author-container-date">
            7 de junho de 2024
          </div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Agente Imobiliária
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Com o Pedra, consegui{" "}
              <a
                className="article-link"
                href="https://www.instagram.com/p/C4A6kCmIeMy/"
                rel="nofollow"
              >
                vender uma propriedade de €500k
              </a>{" "}
              em menos de um dia.{" "}
            </span>{" "}
            O apartamento que eu tinha à venda era uma PROPRIEDADE A SER
            RENOVADA e com o Pedra, obtive renders que permitiram visualizar o
            potencial dos espaços após a renovação, mas sem a renovação!
          </q>
          <div className="testimonial-author-container-date">
            2 de junho de 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Consultora Imobiliária na Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Consultora Imobiliária na Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Estou muito satisfeita com os resultados
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Além disso, eles têm melhorado o produto de acordo com o
              feedback que compartilhei!
            </div>
          </q>
          <div className="testimonial-author-container-date">
            4 de junho de 2024
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Como funciona?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 1
            </h5>
            <h2>Criar um projeto e enviar uma imagem</h2>
            <div className="value-prop-text">
              Envie uma ou mais imagens para o Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Como enviar arquivos para criar um home staging virtual no Pedra – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 2
            </h5>
            <h2>Escolha uma opção para criar seu home staging virtual</h2>
            <div className="value-prop-text">
              Envie uma imagem e clique na opção apropriada para criar seu home
              staging virtual.
              <p>
                Você poderá mobiliar espaços, adicionar objetos específicos,
                renová-los, remover partes e muito mais!
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Como escolher opções para criar um home staging virtual – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 3
            </h5>
            <h2>Aguarde e baixe o home staging virtual</h2>
            <div className="value-prop-text">
              Home stagings virtuais podem levar de 15 segundos a alguns minutos
              para serem gerados.
              <p>Uma vez gerado, você pode continuar editando ou baixá-los.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Como baixar um home staging virtual – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Os home stagings virtuais que você precisa por €29 por mês. <br />{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            E sem compromisso.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plano Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                por mês
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclui</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#F8792A" }}>
                100 gerações de imagem
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mês</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovar e redecorar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Salas vazias
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Mobiliário
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remover objetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumentar a resolução
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Trocar pisos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Melhorar a fotografia
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Gerar vídeos virtuais
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plantas baixas ilimitadas
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartilhamento ilimitado de projetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Adicionar marcas d'água
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Suporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente o Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancelamento a qualquer momento.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compre mais imagens por €0,20 cada se precisar delas.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Perguntas e Respostas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">O que é home staging?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                O home staging é uma técnica de marketing imobiliário que
                envolve preparar uma propriedade (casa, apartamento, etc.) para
                venda ou aluguel, com o objetivo de torná-la mais atraente para
                compradores ou inquilinos em potencial. Isso é alcançado através
                de uma apresentação estratégica e decoração do espaço para
                destacar suas características mais positivas e minimizar seus
                defeitos.
                <br />
                <br />
                O processo de home staging envolve limpeza, despersonalização e
                organização da propriedade para permitir que compradores ou
                inquilinos em potencial visualizem facilmente como seria viver
                naquele espaço. Ele também pode incluir a reorganização de
                móveis, a adição de elementos decorativos e, em alguns casos,
                pequenas reformas ou reparos para melhorar a aparência geral da
                propriedade.
                <br />
                <br />O objetivo do home staging é gerar uma impressão positiva
                nos visitantes e aumentar as chances de vender ou alugar a
                propriedade de forma rápida e a um preço favorável. É uma
                estratégia cada vez mais popular no mercado imobiliário devido à
                sua capacidade de melhorar o apelo visual e emocional de uma
                propriedade.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                O que é o home staging virtual?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  O home staging virtual é uma variante do home staging
                  tradicional que usa ferramentas digitais e tecnologia para
                  apresentar uma propriedade de maneira atraente para
                  compradores ou inquilinos potenciais. Em vez de fazer mudanças
                  físicas na propriedade, como reorganizar móveis ou adicionar
                  elementos decorativos, o home staging virtual é realizado
                  principalmente das seguintes maneiras:
                </div>
                <h4>Edição de imagens:</h4>
                Com o Pedra, você pode editar imagens com IA para retocar e
                melhorar fotografias da propriedade. Isso pode envolver a
                remoção de objetos indesejados, ajuste de iluminação e cor e até
                a adição de móveis ou decoração virtuais.
                <br />
                <br />
                <h4>Renderização de design:</h4>
                Crie representações digitais de como a propriedade poderia ficar
                após ser decorada ou renovada. Isso pode ajudar os compradores a
                visualizar o potencial do espaço e inspirar ideias para sua
                própria decoração.
                <br />
                <br />
                <h4>Economia de tempo e custo:</h4>
                O home staging virtual é mais rápido e econômico do que o home
                staging tradicional, pois não requer aluguel de móveis ou
                acessórios físicos ou fazer mudanças físicas na propriedade.
                Isso pode ser especialmente benéfico para propriedades vazias ou
                em situações onde o tempo é um fator crítico.
                <br />
                <br />
                <h4>Flexibilidade e personalização:</h4>
                Com o home staging virtual, é possível experimentar diferentes
                estilos de decoração e configurações de espaço rapidamente e sem
                compromisso. Isso permite adaptar a apresentação da propriedade
                de acordo com o mercado-alvo e as preferências de compradores ou
                inquilinos potenciais.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Dicas de home staging virtual</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Conheça seu público-alvo:</h4>
                Compreender as necessidades e preferências do seu público-alvo
                ajudará a criar conteúdo de home staging virtual que ressoe com
                eles. Considere quem são os potenciais compradores ou inquilinos
                e adapte sua abordagem de acordo.
                <br />
                <br />
                <h4>Seja honesto e transparente:</h4>
                Certifique-se de que o conteúdo de home staging virtual reflita
                com precisão a aparência e as características da propriedade.
                Evite editar excessivamente ou manipular demais as imagens, pois
                isso pode criar expectativas irreais.
                <br />
                <br />
                <h4>Múltiplos ângulos:</h4>
                Capture a propriedade de vários ângulos para fornecer uma visão
                completa. Isso permite que os visualizadores tenham uma ideia
                melhor do layout do espaço e das características únicas da
                propriedade.
                <br />
                <br />
                <h4>Mantenha a consistência estilística:</h4>
                Use um estilo de decoração coerente em todas as imagens e tours
                virtuais para criar uma experiência visual uniforme. Isso ajuda
                a manter o interesse dos visualizadores e facilita a
                visualização da propriedade como um todo.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Preço do home staging virtual</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                O preço do Pedra é de €29 mais IVA por mês.
                <br />
                <br />
                Por esse preço, você pode gerar até 100 imagens por mês.
                <br />
                <br />
                Não há compromisso, então você pode cancelar quando não precisar
                mais.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                Quais são as etapas do processo de Home Staging Virtual?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                O processo de home staging é bem rápido e simples. Aqui estão as
                etapas para criar home staging virtual com Pedra:
                <br />
                <br />
                1. <strong>Faça o upload das fotos do projeto:</strong> Ao criar
                um projeto, você pode fazer o upload de uma ou mais imagens para
                editar.
                <br />
                <br />
                2. <strong>Opções de Home Staging Virtual:</strong> Depois de
                selecionar as fotos, no menu suspenso "Editar com IA", você terá
                todas as opções que precisar, desde esvaziar salas até adicionar
                móveis ao seu espaço, renovar ambientes e muito mais.
                <br />
                <br />
                3. <strong>Baixe seu projeto:</strong> Após fazer todas as
                mudanças e editar as imagens, basta baixar as imagens do seu
                Home Staging
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                O Home Staging Virtual pode ser feito em propriedades vazias?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  Sim! Uma das funções da nossa ferramenta de home staging
                  virtual é mobiliar o ambiente. Você pode usar a opção
                  "Mobiliar" para mobiliar automaticamente o espaço com IA, ou
                  se preferir, pode adicionar manualmente os móveis que desejar
                  com a opção "Adicionar objeto".
                </p>
                <p>
                  Com qualquer uma dessas opções, nossa ferramenta de Home
                  Staging Virtual ajuda você a visualizar o potencial dos seus
                  espaços, facilitando a tomada de decisões e melhorando a
                  apresentação da propriedade.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStaging;
