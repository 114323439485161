import React, { useState, useEffect } from "react";
import CheckIcon from "../Icons/CheckIcon.jsx";
import MagicWandIconPurple from "../Icons/MagicWandIconPurple.jsx";
import EmptyIcon from "../Icons/EmptyIcon.jsx";
import HammerIconPricing from "../Icons/HammerIconPricing.jsx";
import BrushIconPricing from "../Icons/BrushIconPricing.jsx";
import HDIconPricing from "../Icons/HDIconPricing.jsx";
import RemoveObjectPricingIcon from "../Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "../Icons/FurnishPricingIcon.jsx";
import PhotographyPricingIcon from "../Icons/PhotographyIconPricing.jsx";
import WallChangePricingIcon from "../Icons/WallChangePricingIcon.jsx";
import VideoPricingIcon from "../Icons/VideoPricingIcon.jsx";
import CustomerLogos from "../CustomerLogos.js";
import ArrowIcon from "../Icons/ArrowIcon.jsx";
import DoorPricingIcon from "../Icons/DoorPricingIcon.jsx";
import HumanPricingIcon from "../Icons/HumanPricingIcon.jsx";

// Format price based on currency
const formatPrice = (pricing) => {
  const amount = pricing.amount;
  const currency = pricing.currency;

  switch (currency) {
    case "$":
      return `${currency}${amount}`; // USD: $29
    case "€":
      return `${amount}€`; // EUR: 29€
    case "MXN":
      return `$${amount} ${currency}`; // MXN: $399.00 MXN
    case "S/":
      return `${currency} ${amount}`; // PEN: S/ 76.00
    case "COP":
      return `$${amount} ${currency}`; // COP: $85,000 COP
    case "Bs.":
      return `${currency} ${amount}`; // BOB: Bs. 132.00
    case "CLP":
      return `$${amount} ${currency}`; // CLP: $19,500 CLP
    default:
      return `${currency}${amount}`;
  }
};

// Pricing data for different regions
const regionPricing = {
  default: {
    amount: "29",
    currency: "€",
    manualService: { amount: "6", currency: "€" },
    extraImage: { amount: "0.20", currency: "€" },
  },
  US: {
    amount: "29",
    currency: "$",
    manualService: { amount: "6.50", currency: "$" },
    extraImage: { amount: "0.22", currency: "$" },
  },
  MX: {
    amount: "399.00",
    currency: "MXN",
    manualService: { amount: "131.72", currency: "MXN" },
    extraImage: { amount: "4.37", currency: "MXN" },
  },
  PE: {
    amount: "76.00",
    currency: "S/",
    manualService: { amount: "23.32", currency: "S/" },
    extraImage: { amount: "0.79", currency: "S/" },
  },
  CO: {
    amount: "85,000",
    currency: "COP",
    manualService: { amount: "27,700", currency: "COP" },
    extraImage: { amount: "0.93", currency: "COP" },
  },
  BO: {
    amount: "132.00",
    currency: "Bs.",
    manualService: { amount: "27.700", currency: "Bs." },
    extraImage: { amount: "1.40", currency: "Bs." },
  },
  CL: {
    amount: "19,500",
    currency: "CLP",
    manualService: { amount: "42.92", currency: "CLP" },
    extraImage: { amount: "160", currency: "CLP" },
  },
  // Add more countries as needed
};

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function PricingCard() {
  const [pricing, setPricing] = useState(regionPricing.default);

  useEffect(() => {
    const detectUserLocation = async () => {
      try {
        // Using a service that supports CORS
        const response = await fetch("https://get.geojs.io/v1/ip/country.json");
        const data = await response.json();

        if (data && data.country_code) {
          setPricing(regionPricing[data.country_code] || regionPricing.default);
        } else {
          setPricing(regionPricing.default);
        }
      } catch (error) {
        console.error("Error detecting location:", error);
        setPricing(regionPricing.default);
      }
    };

    detectUserLocation();
  }, []);

  const formattedPrice = formatPrice(pricing);

  return (
    <div className="modal-product-tiers-container">
      <div className="modal-product-tier-current">
        <div className="modal-product-plan-description">
          <div className="modal-product-plan-description-text-1">Pro Plan</div>
          <div className="modal-product-plan-description-text-2-3-container">
            <div className="modal-product-plan-description-text-2">
              {formattedPrice}
            </div>
            <div className="modal-product-plan-description-text-3">
              per month
            </div>
          </div>
        </div>

        <div className="modal-product-plan-description">
          <div className="modal-product-plan-description-text-4">Includes</div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />{" "}
            <span style={{ fontWeight: "500", color: "#F8792A" }}>
              100 image generations
            </span>{" "}
            <MagicWandIconPurple />
            <div style={{ marginLeft: "2px" }}>per month</div>
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <HammerIconPricing /> Renovate and redecorate
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <BrushIconPricing /> Empty rooms
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <FurnishPricingIcon /> Furnish
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <RemoveObjectPricingIcon /> Remove objects
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <HDIconPricing /> Increase resolution
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <WallChangePricingIcon /> Change floors
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <PhotographyPricingIcon /> Enhance photography
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <VideoPricingIcon /> Generate virtual videos
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <DoorPricingIcon /> Render floor plans
          </div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />
            Draw unlimited floor plans
          </div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />
            Unlimited before/after videos
          </div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />
            Unlimited support
          </div>
          <div className="modal-product-plan-description-text-4">
            <HumanPricingIcon />
            Professional image editing (from{" "}
            {formatPrice(pricing.manualService)})
          </div>
          <div className="pricing-modal-button-container">
            <button className="button-top-of-page" onClick={redirectToPage}>
              Try Pedra
              <ArrowIcon />
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <div>Cancel anytime.</div>
          <div style={{ fontSize: "12px", lineHeight: "100%" }}>
            Purchase additional images at {formatPrice(pricing.extraImage)} per
            image if needed.
          </div>
        </div>
      </div>
    </div>
  );
}
export default PricingCard;
