import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeHouse from "./Images/curtain-effect-hero/BeforeHouse.png";
import AfterHouse from "./Images/curtain-effect-hero/AfterHouse.png";
import BeforeHouseStaging1 from "./Images/curtain-effect/BeforeHouseStaging1.png";
import AfterHouseStaging1 from "./Images/curtain-effect/AfterHouseStaging1.png";
import BeforeHouseStaging2 from "./Images/curtain-effect/BeforeHouseStaging2.png";
import AfterHouseStaging2 from "./Images/curtain-effect/AfterHouseStaging2.png";
import BeforeHouseStaging3 from "./Images/curtain-effect/BeforeHouseStaging3.png";
import AfterHouseStaging3 from "./Images/curtain-effect/AfterHouseStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderHouseDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeHouse,
    afterImage: AfterHouse,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeHouse,
        afterImage: AfterHouse,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeHouseStaging1,
        afterImage: AfterHouseStaging1,
      });
    } else if (example === "Colonial") {
      setExample({
        name: "Colonial",
        beforeImage: BeforeHouseStaging2,
        afterImage: AfterHouseStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeHouseStaging3,
        afterImage: AfterHouseStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was sind automatische Hausvisualisierungen?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatische Hausvisualisierungen sind KI-gestützte Visualisierungen, die Hausfotos in professionell gerenderte Bilder umwandeln. Diese Technologie ermöglicht es Ihnen, fotorealistische Visualisierungen von Immobilien mit verschiedenen Stilen, Einrichtungen und Layouts zu erstellen, ohne dass manuelles 3D-Modeling oder komplexe Software erforderlich ist.",
        },
      },
      {
        "@type": "Question",
        name: "Was sind die Vorteile von automatischen Hausvisualisierungen?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatische Hausvisualisierungen bieten mehrere Vorteile, darunter: sofortige Transformation von Immobilien, die Möglichkeit, schnell mehrere Designoptionen zu visualisieren, hochwertige fotorealistische Ergebnisse, erhebliche Zeit- und Kosteneinsparungen im Vergleich zum traditionellen Rendering und die Flexibilität, mit verschiedenen Stilen und Konfigurationen zu experimentieren.",
        },
      },
      {
        "@type": "Question",
        name: "Wie funktioniert der automatische Hausvisualisierungsprozess?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der Prozess beinhaltet das Hochladen von Fotos der aktuellen Immobilie auf unsere Plattform, die Auswahl der gewünschten Transformationen (wie Materialänderungen, Möblierung oder Layout-Änderungen) und den Erhalt fotorealistischer Renderings, die das transformierte Haus innerhalb von Minuten zeigen. Unsere KI-Technologie übernimmt automatisch alle komplexen Rendering-Arbeiten.",
        },
      },
      {
        "@type": "Question",
        name: "Wie viel kosten automatische Hausvisualisierungen?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Unser automatischer Hausvisualisierungsservice ist für 29€ plus MwSt. pro Monat verfügbar. Dieser Plan ermöglicht es Ihnen, bis zu 100 Renderings pro Monat zu generieren, ohne langfristige Verpflichtung.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeHouseStaging1,
    afterImage: AfterHouseStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeHouseStaging1,
      afterImage: AfterHouseStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeHouseStaging2,
      afterImage: AfterHouseStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeHouseStaging3,
      afterImage: AfterHouseStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Hausvisualisierungen</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Erstellen Sie fotorealistische Hausvisualisierungen
                  automatisch
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Sofort:</strong> Generieren Sie Visualisierungen in
                    Sekunden
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Keine technischen Kenntnisse
                    erforderlich
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realistisch:</strong> Fotorealistische Ergebnisse
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Support:</strong> Experten-Unterstützung verfügbar
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 für 100 Visualisierungen
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Direktor für Fotografie bei aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO bei Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO bei Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Über 100 Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Vor der Hausvisualisierung"
                  altAfter="Nach der Hausvisualisierung"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Modernes Haus
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Traditioneller Stil
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Colonial" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Colonial")}
                    >
                      Kolonialstil
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Zeitgenössischer Look
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          VON ÜBER 20.000 PROFIS VERTRAUT
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Erstellen Sie professionelle Hausvisualisierungen{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatisch
          </span>{" "}
          in Sekunden.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Häuser sofort transformieren</h3>
                <div className="feature-text">
                  Generieren Sie fotorealistische Visualisierungen mit einem
                  Klick
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Mehrere Architekturstile</h3>
                <div className="feature-text">
                  Visualisieren Sie verschiedene Designs und Außenbereiche
                  einfach
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perfekte Perspektiven</h3>
                <div className="feature-text">
                  Automatische Korrektur und Optimierung der Kamerawinkel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Natürliche Beleuchtung</h3>
                <div className="feature-text">
                  Perfekte Beleuchtung in jeder Visualisierung
                </div>
              </div>
            </div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Materialien ändern</h3>
                <div className="feature-text">
                  Aktualisieren Sie Außenbereiche, Fassaden und Oberflächen
                  automatisch
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Saubere Szenengestaltung</h3>
                <div className="feature-text">
                  Entfernen Sie unerwünschte Objekte automatisch
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Beispiele unserer automatischen Hausvisualisierungen.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Vor der Hausvisualisierung"
            altAfter="Nach der Hausvisualisierung"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Wie funktionieren automatische Hausvisualisierungen?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Laden Sie Ihr Hausfoto hoch</h2>
            <div className="value-prop-text">
              Laden Sie ein beliebiges Foto Ihres Hauses auf die Pedra-Plattform
              hoch.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Hausfotos für automatische Visualisierung in Pedra hochlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>Wählen Sie Ihre Visualisierungsoptionen</h2>
            <div className="value-prop-text">
              Wählen Sie aus, wie Sie Ihr Haus transformieren möchten.
              <p>
                Ändern Sie Materialien, aktualisieren Sie die Beleuchtung,
                modifizieren Sie die Architektur, fügen Sie
                Landschaftsgestaltung hinzu und mehr.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Wie man Optionen für automatische Hausvisualisierung auswählt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>Erhalten Sie Ihre fotorealistischen Visualisierungen</h2>
            <div className="value-prop-text">
              Erhalten Sie Ihre professionell visualisierten Hausbilder in
              Sekunden.
              <p>
                Laden Sie Ihre Visualisierungen sofort herunter und verwenden
                Sie sie.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Wie man automatische Hausvisualisierungen herunterlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Professionelle Hausvisualisierungen für €29 pro Monat. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Keine Vertragsbindung.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Enthält</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#F8792A" }}>
                100 Visualisierungen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Häuser sofort transformieren
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Materialien und Oberflächen ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Landschaftsdetails hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Automatische Szenenbereinigung
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Hochauflösende Ausgabe
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Mehrere Architekturstile
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Natürliche Beleuchtung
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Projekte
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Teilen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Benutzerdefinierte Wasserzeichen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Prioritäts-Support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Jederzeit kündbar.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Zusätzliche Visualisierungen bei Bedarf für je €0,20 verfügbar.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Fragen zu automatischen Hausvisualisierungen
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Was sind automatische Hausvisualisierungen?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Automatische Hausvisualisierungen nutzen fortschrittliche
                KI-Technologie, um normale Hausfotos in professionelle,
                fotorealistische Visualisierungen umzuwandeln. Diese Technologie
                ermöglicht es Ihnen, hochwertige Visualisierungen ohne manuelles
                3D-Modeling oder komplexe Rendering-Software zu erstellen.
                <br />
                <br />
                Das System verarbeitet automatisch Außenbeleuchtung,
                Materialien, Perspektiven und andere technische Aspekte, um
                sofort professionelle Ergebnisse zu liefern.
              </div>
            )}
          </div>
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was sind die Vorteile von automatischen Hausvisualisierungen?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Automatische Hausvisualisierungen bieten zahlreiche Vorteile:
                <br />
                <br />
                1. Geschwindigkeit: Generieren Sie Visualisierungen in Sekunden,
                nicht in Stunden oder Tagen
                <br />
                2. Kostengünstig: Keine teure 3D-Modellierungssoftware
                erforderlich
                <br />
                3. Einfache Bedienung: Keine technischen Vorkenntnisse notwendig
                <br />
                4. Mehrere Optionen: Testen Sie verschiedene Architekturstile
                schnell
                <br />
                5. Fotorealistische Ergebnisse: Professionelle Qualität
                <br />
                6. Flexibilität: Nehmen Sie sofort Änderungen vor
                <br />
                7. Konsistenz: Zuverlässige Ergebnisse jedes Mal
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Was kann ich mit Pedras Hausvisualisierungen machen?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Pedras automatisches Hausvisualisierungssystem bietet eine
                Vielzahl von Möglichkeiten:
                <br />
                <br />
                1. Visualisierungen erstellen: Erstellen Sie sofort
                fotorealistische Visualisierungen aus jedem Hausfoto.
                <br />
                2. Lichtoptimierung: Automatische Verbesserung von natürlichem
                Licht und Schatten für professionelle Ergebnisse.
                <br />
                3. Materialänderungen: Modifizieren Sie Außenbereiche, Fassaden
                und Gebäudeoberflächen mit einem Klick.
                <br />
                4. Elemente hinzufügen: Fügen Sie automatisch neue
                Landschaftsgestaltung, architektonische Merkmale und dekorative
                Elemente ein.
                <br />
                5. Szenenbereinigung: Entfernen Sie automatisch unerwünschte
                Objekte und Unordnung.
                <br />
                6. Designvisualisierung: Testen Sie schnell verschiedene
                Architekturstile.
                <br />
                <br />
                Alle Transformationen werden automatisch von unserer KI
                durchgeführt und liefern professionelle Ergebnisse in Sekunden.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderHouseDe;
