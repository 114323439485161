import React from "react";

function ApiDocsDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Pedra.so API-Dokumentation</h1>
          <h2 className="title-secondary gray">
            API zur Erstellung von virtuellen Home-Staging-Bildern
          </h2>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <p>
              Willkommen bei der offiziellen Dokumentation der Pedra.so API.
              Unsere API bietet leistungsstarke Bildverarbeitungsfunktionen für
              verschiedene Raumverbesserungs- und Designaufgaben.
            </p>
            <br />
            <h2 className="article-subtitleh2">Basis-URLs</h2>
            <p>Die API ist über die folgende Basis-URL erreichbar:</p>
            <ul>
              <li>
                Produktion: <code>https://app.pedra.so/api</code>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Authentifizierung</h2>
            <p>
              Alle API-Aufrufe erfordern einen <code>apiKey</code> Parameter zur
              Authentifizierung. Senden Sie eine E-Mail an felix@pedra.so, um
              Ihren API-Schlüssel anzufordern.
            </p>
            <br />
            <h2 className="article-subtitleh2">Allgemeine Parameter</h2>
            <p>Die folgenden Parameter sind für alle Endpunkte gleich:</p>
            <ul>
              <li>
                <code>apiKey</code> (String, erforderlich): Ihr einzigartiger
                API-Schlüssel zur Authentifizierung.
              </li>
              <li>
                <code>imageUrl</code> (String, erforderlich): URL des zu
                verarbeitenden Bildes.
              </li>
              <li>
                <code>creativity</code> (String, erforderlich): Grad der
                kreativen Freiheit für die KI.
                <ul>
                  <li>
                    <strong>Niedrig</strong>: Bewahrt die ursprüngliche
                    Architektur mit minimalen Variationen.
                  </li>
                  <li>
                    <strong>Mittel</strong>: Behält die ursprüngliche
                    Architektur mit ausgewogenen Änderungen bei.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Endpunkte</h2>

            <h3 className="article-subtitle">Enhance ausführen</h3>
            <p>
              Verbessert die Qualität, Farbe, Licht und Auflösung des
              bereitgestellten Bildes.
            </p>
            <p>
              <strong>Endpunkt:</strong> <code>/enhance</code>
            </p>
            <p>
              <strong>Methode:</strong> POST
            </p>
            <p>
              <strong>Parameter:</strong> Allgemeine Parameter (siehe oben)
            </p>
            <p>
              <strong>Erwartete Antwortzeit:</strong> Zwischen 25 Sekunden und 1
              Minute
            </p>

            <br />
            <h3 className="article-subtitle">Empty Room ausführen</h3>
            <p>
              Entfernt Möbel und Unordnung aus dem Raum im bereitgestellten
              Bild.
            </p>
            <p>
              <strong>Endpunkt:</strong> <code>/empty_room</code>
            </p>
            <p>
              <strong>Methode:</strong> POST
            </p>
            <p>
              <strong>Parameter:</strong> Allgemeine Parameter (siehe oben)
            </p>
            <p>
              <strong>Erwartete Antwortzeit:</strong> Zwischen 2 und 3 Minuten
            </p>
            <br />
            <h3 className="article-subtitle">Furnish ausführen</h3>
            <p>
              Möbliert (erstellt ein virtuelles Staging) einen Raum gemäß den
              angegebenen Parametern.
            </p>
            <p>
              <strong>Endpunkt:</strong> <code>/furnish</code>
            </p>
            <p>
              <strong>Methode:</strong> POST
            </p>
            <p>
              <strong>Parameter:</strong>
            </p>
            <ul>
              <li>Allgemeine Parameter (siehe oben)</li>
              <li>
                <code>roomType</code> (String, erforderlich): Art des zu
                möblierenden Raums.
              </li>
              <li>
                <code>style</code> (String, erforderlich): Anzuwendender
                Designstil.
              </li>
            </ul>
            <p>
              <strong>Erwartete Antwortzeit:</strong> Zwischen 2 und 3 Minuten
            </p>

            <h4>
              Mögliche Werte für <code>roomType</code>:
            </h4>
            <ul>
              <li>"Schlafzimmer"</li>
              <li>"Wohnzimmer"</li>
              <li>"Küche"</li>
              <li>"Büro"</li>
              <li>"Terrasse"</li>
              <li>"Esszimmer"</li>
              <li>"Küche + Wohnzimmer"</li>
              <li>"Ess- + Wohnzimmer"</li>
              <li>"Eingang"</li>
            </ul>

            <h4>
              Mögliche Werte für <code>style</code>:
            </h4>
            <ul>
              <li>"Traditionell"</li>
              <li>"Minimalistisch"</li>
              <li>"Skandinavisch"</li>
              <li>"Mid-Century"</li>
              <li>"Bohemian"</li>
              <li>"Industrial"</li>
              <li>"Mediterran"</li>
              <li>"Modern"</li>
              <li>"Pyrenäen"</li>
            </ul>
            <br />
            <h3 className="article-subtitle">Renovation ausführen</h3>
            <p>Renoviert einen Raum gemäß dem angegebenen Stil.</p>
            <p>
              <strong>Endpunkt:</strong> <code>/renovation</code>
            </p>
            <p>
              <strong>Methode:</strong> POST
            </p>
            <p>
              <strong>Parameter:</strong>
            </p>
            <ul>
              <li>Allgemeine Parameter (siehe oben)</li>
              <li>
                <code>style</code> (String, erforderlich): Anzuwendender
                Designstil für die Renovierung.
              </li>
              <li>
                <code>preserveWindows</code> (Boolean, erforderlich): Ob
                bestehende Fenster während der Renovierung erhalten bleiben
                sollen.
              </li>
            </ul>
            <p>
              <strong>Erwartete Antwortzeit:</strong> Zwischen 25 Sekunden und 2
              Minuten
            </p>
            <br />
            <h2 className="article-subtitleh2">Antwortformat</h2>
            <p>
              Alle API-Endpunkte geben JSON-Antworten zurück. Eine erfolgreiche
              Antwort hat einen Statuscode von 200 und enthält die verarbeiteten
              Bildinformationen.
            </p>
            <br />
            <h2 className="article-subtitleh2">Fehlerbehandlung</h2>
            <p>
              Im Fehlerfall gibt die API einen entsprechenden HTTP-Statuscode
              zusammen mit einer JSON-Antwort zurück, die Fehlerdetails enthält.
            </p>
            <br />
            <h2 className="article-subtitleh2">Support</h2>
            <p>
              Bei Fragen oder Problemen mit der API wenden Sie sich bitte an
              unser Support-Team unter felix@pedra.so.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApiDocsDe;
