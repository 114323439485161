import React from "react";

const RemoveObjectPricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M16 8L12 4L7.5 8.5L11.5 12.5L16 8Z" fill="#F8792A"></path>
      <path d="M6 10L10 14V15.5H5.5L3 13L6 10Z" fill="#F8792A"></path>
      <path
        d="M16.5 15.5H10M10 15.5H5.5L3 13L6 10L10 14V15.5ZM12 4L16 8L11.5 12.5L7.5 8.5L12 4Z"
        stroke="#F8792A"
      ></path>
    </svg>
  );
};

export default RemoveObjectPricingIcon;
