// HomeEs.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";
import GoogleIcon from "./Icons/GoogleIcon.jsx";

// Images
import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import PepeGomez from "./Images/Pepe Gomez image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomeStep1 from "./Images/HomeStep1.png";
import HomeStep2 from "./Images/HomeStep2.png";
import HomeStep3 from "./Images/HomeStep3.png";

// Icons
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import ArrowIconOrange from "./Icons/ArrowIconOrange.jsx";

// Update the animation styles
const progressBarStyle = `
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fade-transition {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-transition.fade-out {
    opacity: 0;
  }
`;

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function SoftwareHomeStagingVirtualDe(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);
  const [FAQ7, setFAQ7] = useState(false);
  const [FAQ8, setFAQ8] = useState(false);
  const [FAQ9, setFAQ9] = useState(false);

  const examples = [
    {
      name: "Furnish",
      beforeImage: BeforeFurnish,
      afterImage: AfterFurnish,
      label: "Möblieren",
    },
    {
      name: "Empty",
      beforeImage: BeforeEmpty,
      afterImage: AfterEmpty,
      label: "Leeren",
    },
    {
      name: "Renovate",
      beforeImage: BeforeRenovate,
      afterImage: AfterRenovate,
      label: "Renovieren",
    },
    {
      name: "Enhance",
      beforeImage: BeforeEnhance,
      afterImage: AfterEnhance,
      label: "Verschönern",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Add styling for header
    const header = document.querySelector("header");
    if (header) {
      // Reset background color first
      header.style.backgroundColor = "#fff";

      // Update based on current path
      if (window.location.pathname.startsWith("/es")) {
        header.style.backgroundColor = "#f7f7f7";
      }
    }

    // Preload all example images
    examples.forEach((example) => {
      const beforeImg = new Image();
      const afterImg = new Image();
      beforeImg.src = example.beforeImage;
      afterImg.src = example.afterImage;
    });

    // Cleanup function to reset header style when component unmounts
    return () => {
      if (header) {
        header.style.backgroundColor = "#fff";
      }
    };
    // eslint-disable-next-line
  }, []); // Re-run when pathname changes

  // Add loading priority to first images
  const [example, setExample] = useState(examples[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progressKey, setProgressKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Start the interval when component mounts
    const newIntervalId = setInterval(() => {
      handleNextImage();
    }, 4000);
    setIntervalId(newIntervalId);

    // Cleanup interval on component unmount
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array means this only runs on mount/unmount

  const handleNextImage = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setExample((currentExample) => {
        const currentIndex = examples.findIndex(
          (ex) => ex.name === currentExample.name
        );
        const nextIndex = (currentIndex + 1) % examples.length;
        return examples[nextIndex];
      });
      setIsTransitioning(false);
      setProgressKey((prev) => prev + 1);
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Pedra",
              "url": "https://pedra.so/de",
              "sameAs": "https://maps.app.goo.gl/uYEqAdxAUWjnucKk9",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "50"
              }
            }
          `}
        </script>
      </Helmet>
      <style>{progressBarStyle}</style>

      {/* NEW HERO */}
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px 20px 20px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <h1 className="new-hero-header">Virtuelles Home Staging Software</h1>

        <h2 className="new-hero-paragraph">
          Die beste virtuelle Home Staging Software für Immobilienmakler und
          Immobilienbüros
        </h2>

        <button
          className="button-top-of-page"
          onClick={redirectToPage}
          style={{
            margin: "0 auto 16px",
            padding: "12px 32px",
            fontSize: "20px",
          }}
        >
          Kostenlos testen
          <ArrowIcon />
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginBottom: "12px",
          }}
        >
          <div className="new-hero-reviews-container">
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <GoogleIcon />
              <span
                style={{ color: "#333", fontWeight: "400", fontSize: "16px" }}
              >
                5.0
              </span>
              <div style={{ color: "#FFD700", letterSpacing: "2px" }}>
                ★★★★★
              </div>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <img
                className="testimonials-on-header-image"
                src={VictorLaviosa}
                alt="Victor Laviosa, Director of Photography at aProperties"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={Soma}
                alt="Francisco Somacarrera, Real Estate Agent at Huspy"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={DanieldelaMata}
                alt="Daniel de la Mata, CEO at Actívox"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={MariaJoseGilart}
                alt="Maria José Gilart, CEO at Gilart Consultors"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Emanuela Cosma"
              ></img>
            </div>
            <span style={{ color: "#333" }}> Über 20.000 vertrauen uns</span>
          </div>
        </div>
      </div>
      <div
        className="section-one-column-left"
        style={{ margin: "20px 24px", padding: "20px 24px" }}
      >
        <div className="customer-logo-container" style={{ color: "#878787" }}>
          Diese Immobilienunternehmen vertrauen bereits auf Pedra:
          <CustomerLogos />
        </div>
      </div>

      <div
        style={{
          background: "linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2
              className="title-container"
              style={{
                position: "relative",
                display: "inline",
                background: "linear-gradient(90deg, #FF6B33 0%, #FF8A33 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
              }}
            >
              Funktionen unserer virtuellen Home Staging Software
            </h2>
            <div className="title-secondary">
              Laden Sie Ihre Bilder in Pedra hoch und nutzen Sie unsere
              KI-gestützten virtuellen Home Staging Tools, um Bilder und Videos
              zu erstellen, die Ihre Verkäufe beschleunigen.
            </div>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="section-one-column-left" style={{ width: "100%" }}>
            <div
              className="feature-container-two-features"
              style={{ width: "100%" }}
            >
              {/* Row 1 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/hilfe/wie-man-hauser-virtuell-renoviert"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Renovierung
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Renovierung in Ihrem Stil
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/virtuelles-home-staging"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Möblieren
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>
              </div>

              {/* Row 2 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/hilfe/wie-man-raume-virtuell-leert"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Räume leeren
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/hilfe/wie-man-objekte-entfernt"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Objekt entfernen
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Objekt hinzufügen
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 3 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/hilfe/wie-man-3d-objekte-hinzufugt"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        3D-Objekt hinzufügen
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/immobilienfotografie"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Blauer Himmel
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/immobilienfotografie"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Bild verschönern
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>
              </div>

              {/* Row 4 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/immobilienfotografie"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        In HD umwandeln
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/de/rendering-vom-grundriss"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Rendering vom Grundriss
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Virtuelles Video
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 5 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={
                        "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt"
                      }
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Vorhang-Effekt
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Wasserzeichen entfernen
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Boden ändern
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 6 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Perspektive korrigieren
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Wasserzeichen hinzufügen
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Bildunterschrift
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Mit Pedra konnte ich eine Immobilie im Wert von 500.000 € in
              weniger als einem Tag verkaufen. Die Wohnung war
              renovierungsbedürftig und mit Pedra konnte ich Renderings
              erstellen, die das Potenzial der Räume nach der Renovierung
              zeigten - ohne tatsächliche Renovierung!"
            </h2>
            <div className="quote-author-block">
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Foto von Emanuela Cosma"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Emanuela Cosma</div>
                <div className="quote-author-company">Immobilienmaklerin</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              Wie funktioniert es?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Schritt 1 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Bilder hochladen
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Laden Sie ein oder mehrere Bilder der Immobilie hoch.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Schritt 2 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transforma
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Klicken Sie auf "Mit KI bearbeiten", um die Bilder zu
                    transformieren.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Schritt 3 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Warten und herunterladen
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Laden Sie Ihre KI-bearbeiteten Bilder herunter.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Ich musste eine renovierungsbedürftige Wohnung verkaufen und
              nutzte Pedra, um in Sekundenschnelle Renderings zu erstellen. Die
              Ergebnisse waren unglaublich! Es half mir, sie viel schneller zu
              verkaufen, jetzt empfehle ich es jedem."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="EXP Logo für Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="Foto von José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Immobilienmakler</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Für 29€ pro Monat. <br />{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
              }}
            >
              Ohne Vertragsbindung.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Pro Plan
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">29€</div>
                <div className="modal-product-plan-description-text-3">
                  pro Monat
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Enthält
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#F8792A" }}>
                  100 Bildgenerierungen
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>pro Monat</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Renovieren und neu gestalten
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Räume leeren
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Möblieren
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Objekte entfernen
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Auflösung erhöhen
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Böden ändern
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Fotografie verbessern
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <VideoPricingIcon /> Virtuelle Videos generieren
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenzte Grundrisse
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenztes Teilen von Projekten
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Wasserzeichen hinzufügen
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenzter Support
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Pedra testen
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Jederzeit kündbar.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Kaufen Sie bei Bedarf weitere Bilder für 0,20€ pro Bild.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Pedra testen <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Gründer von Pedra"
              />
              <div>
                Fragen? Senden Sie uns eine E-Mail an{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                oder vereinbaren Sie eine 15-minütige Demo mit unserem Gründer
                Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  hier
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoftwareHomeStagingVirtualDe;
