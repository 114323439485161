import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageRoomUnderConstruction.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToRenovateFrame1Es.jpg";

function HowToVirtualStagingConstructionEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo transformar imágenes de obra nueva en construcción
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear home stagings virtuales atractivos de
            pisos en construcción
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo mejorar la presentación de una obra en construcción?
            </h2>
            <p className="article-text">
              Con Pedra puedes transformar imágenes de pisos en construcción en
              home stagings virtuales atractivos en pocos clics. Para comenzar,
              accede al menú de{" "}
              <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
              selecciona <span style={{ fontWeight: "500" }}>"Renovación"</span>
              .
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Cómo crear un home staging virtual de piso en construcción – Ejemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              Pasos para transformar una imagen de obra nueva en construcción
            </h2>
            <p className="article-text">
              Para conseguir una visualización atractiva de tu obra en
              construcción, sigue estos sencillos pasos:
            </p>
            <ol>
              <li>
                Sube la imagen del espacio en construcción que quieres
                transformar
              </li>
              <li>Selecciona la imagen para empezar a editarla</li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                luego en <span style={{ fontWeight: "500" }}>"Renovación"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menú de renovación de obra en construcción"
            />
            <p className="article-text">
              En el menú de{" "}
              <span style={{ fontWeight: "500" }}>"Renovación"</span> deberás
              seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona un <span style={{ fontWeight: "500" }}>estilo</span>{" "}
                como referencia para el acabado final
              </li>
              <li>
                Activa la opción{" "}
                <span style={{ fontWeight: "500" }}>"Preservar ventanas"</span>{" "}
                para mantener la estructura original
              </li>
              <li>
                Activa <span style={{ fontWeight: "500" }}>"Amueblar"</span>{" "}
                para incluir mobiliario en la imagen
              </li>
              <li>
                Selecciona el{" "}
                <span style={{ fontWeight: "500" }}>tipo de espacio</span> según
                la estancia (dormitorio, salón, etc.)
              </li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Generar imagen"</span> y
                espera unos minutos a que se complete el proceso
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para mejores resultados:
                <ul>
                  <li>
                    Asegúrate de que la fotografía muestre claramente la
                    estructura del espacio
                  </li>
                  <li>
                    Puedes primero "Amueblar" y luego "Renovar" para obtener
                    mejores resultados
                  </li>
                  <li>
                    Escoge un estilo que se ajuste al perfil del comprador
                    objetivo
                  </li>
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              ¿Por qué transformar imágenes de obra nueva?
            </h2>
            <p className="article-text">
              Presentar imágenes de obra en construcción puede dificultar la
              venta de un inmueble. Al transformar estas imágenes en renders
              atractivos conseguirás:
            </p>
            <ul>
              <li>Ayudar a los compradores a visualizar el resultado final</li>
              <li>Generar más leads cualificados</li>
              <li>Acelerar el proceso de venta</li>
              <li>Destacar frente a otras promociones de obra nueva</li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Video de cómo crear un home staging virtual para pisos en
              construcción
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/2iD8RoljZ2c?si=ZUhv2qwGeo6VHGXR"
                  title="Tutorial de Home Staging Virtual para Pisos en Construcción"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingConstructionEs;
