import React from "react";
import Article1Frame from "./Images/Article1Frame.png";
import Article4Frame from "./Images/Article4Frame.png";
import Article5Frame from "./Images/Article5Frame.jpeg";
import Article6Frame from "./Images/Article6Frame.jpeg";
import Article7Frame from "./Images/Article7Frame.jpg";
import Article8Frame from "./Images/Article8Frame.png";
import Article9Frame from "./Images/Article9Frame.png";
import Article10Frame from "./Images/Article10Frame.png";
import Article11Frame from "./Images/Article11Frame.png";
import Article12Frame from "./Images/Article12Frame.png";
import Article13Frame from "./Images/Article13Frame.png";
import Article14Frame from "./Images/Article14Frame.png";
import Article15Frame from "./Images/Article15Frame.png";
import Article16Frame from "./Images/Article16Frame.png";
import Article17Frame from "./Images/Article17Frame.png";
import Article18Frame from "./Images/Article18Frame.png";
import Article19Frame from "./Images/Article19Frame.png";
import Article20Frame from "./Images/Article20Frame.png";
import Article21Frame from "./Images/Article21Frame.png";
import Article22Frame from "./Images/Article22Frame.png";
import Article23Frame from "./Images/Article23Frame.png";
import Article24Frame from "./Images/Article24Frame.png";
import Article25Frame from "./Images/Article25Frame.png";
import Article26Frame from "./Images/Article26Frame.png";
import Article27Frame from "./Images/Article27Frame.png";
import Article28Frame from "./Images/Article28Frame.png";
import Article29Frame from "./Images/Article29Frame.png";
import Article30Frame from "./Images/Article30Frame.png";
import Article31Frame from "./Images/Article31Frame.png";
import Article32Frame from "./Images/Article32Frame.png";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Blog</h1>
          <h2 className="title-secondary">
            Welcome to Pedra's blog <br />
            In this blog you will learn how to use AI to help you in the real
            estate world.
          </h2>
        </div>
      </div>

      <br />
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>December 24, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/property-listing-optimization"
              >
                Property Listing Optimization: Key Strategies for Success in
                2025
              </Link>
            </h2>
            <div className="value-prop-text">
              How to optimize your property listings for success in 2025
            </div>
          </div>
          <div className="picture">
            <img
              src={Article32Frame}
              alt="Essential Real Estate Photography Tips – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>December 17, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-photography-tips"
              >
                Unlocking Success: Essential Real Estate Photography Tips for
                Stunning Property Listings
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover essential real estate photography tips for stunning
              property listings. Enhance your skills today!
            </div>
          </div>
          <div className="picture">
            <img
              src={Article31Frame}
              alt="Essential Real Estate Photography Tips – Decorative illustration"
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>December 10, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/roi-virtual-staging"
              >
                ROI of Virtual Staging: Real Numbers from Top Real Estate
                Agencies
              </Link>
            </h2>
            <div className="value-prop-text">
              Learn about the ROI of virtual staging in real estate with
              concrete data and insights from top agencies across market
              segments.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article30Frame}
              alt="ROI of Virtual Staging: Real Numbers from Top Real Estate Agencies – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>December 2, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/traditional-home-staging-vs-virtual-home-staging"
              >
                Traditional vs Virtual Home Staging: Which is Better for Selling
                Your Property?
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover the key differences between traditional and virtual home
              staging, and learn which option best suits your property. Compare
              costs, benefits, and effectiveness for different types of real
              estate.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article29Frame}
              alt="Traditional vs Virtual Home Staging – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>November 4, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-sell-a-house-to-renovate"
              >
                How to Sell a House to Renovate: Complete Guide
              </Link>
            </h2>
            <div className="value-prop-text">
              Learn effective strategies for selling a house that needs
              renovation. Tips on highlighting potential, setting the right
              price, and attracting buyers interested in renovation projects.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article28Frame}
              alt="How to sell a house to renovate – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 25, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/buildable-land">
                Buildable Land: What It Is and Types of Land
              </Link>
            </h2>
            <div className="value-prop-text">
              We explain what buildable land is, the different types of
              buildable land available, and how to prepare them for sale.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article27Frame}
              alt="Buildable land – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 23, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-trust"
              >
                What is a real estate trust and what types are there?
              </Link>
            </h2>
            <div className="value-prop-text">
              A real estate trust is a legal contract between a trustor who
              transfers ownership of certain assets to a trustee who is
              responsible for managing them.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article26Frame}
              alt="Real estate trust – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 18, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-phrases"
              >
                Real Estate Phrases, Examples and Tips to Attract Buyers
              </Link>
            </h2>
            <div className="value-prop-text">
              Real Estate Phrases to attract clients, highlight and describe
              properties. We teach you how to write your phrases and provide
              examples of the best real estate phrases.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article25Frame}
              alt="Real estate phrases – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 16, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/real-estate">
                Real Estate: Tips for Buying, Selling and Investing
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover everything about real estate. From tips for first-time
              buyers to investment strategies and market trends.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article24Frame}
              alt="Real estate – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 10, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/mistakes-when-selling-house"
              >
                Common Mistakes When Selling a House and How to Avoid Them
              </Link>
            </h2>
            <div className="value-prop-text">
              Common mistakes when selling a house and how to avoid them: key
              tips for achieving a successful sale without losing money or time.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article23Frame}
              alt="Common mistakes when selling a house – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 9, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/short-sale">
                Guide to Short Sale: What It Is and Its Objectives
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover what a short sale is, its benefits and requirements for
              sellers and buyers. Examples of short sales in the real estate
              market.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article22Frame}
              alt="Short sale – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 4, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/environmental-designer"
              >
                Environmental Designer: Guide to Environmental Design
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover the role of the environmental designer, their key skills,
              creative processes, and current trends in unique environmental
              design.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article21Frame}
              alt="Environmental designer – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 3, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/modernize-home">
                How to modernize your home with antique furniture?
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover how you can modernize your home with the furniture you
              have, give a second life to your antique furniture and modernize
              your home with these ideas.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article20Frame}
              alt="Modernize home – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>October 3, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/flipping">
                Real Estate Flipping | What it is and benefits of flipping
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover how to successfully flip real estate: from market
              research to selling, optimize every step to maximize your profits.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article19Frame}
              alt="Flipping – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>September 24, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/property-that-wont-sell"
              >
                Property that won't sell
              </Link>
            </h2>
            <div className="value-prop-text">
              A property that won't sell is a complicated situation. Here we
              explain what you can do to help sell a property that isn't
              selling.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article18Frame}
              alt="Property that won't sell – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>September 20, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/home-automation"
              >
                Home Automation: Its Benefits and How to Automate Your Home
              </Link>
            </h2>
            <div className="value-prop-text">
              Domotiser votre maison est un processus très simple qui offre un
              grand confort au quotidien. Nous vous montrons comment la
              domotiser et quels produits acheter.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article17Frame}
              alt="Home automation – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>September 19, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/sunken-living-rooms"
              >
                Sunken living rooms: What are they and how to design them?
              </Link>
            </h2>
            <div className="value-prop-text">
              Discover the charm of sunken living rooms, unique architectural
              spaces that combine modern design with comfort, creating cozy and
              elegant environments.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article16Frame}
              alt="Sunken living room – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>September 13, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-personalize-room"
              >
                How to Personalize My Room? Steps and Tips
              </Link>
            </h2>
            <div className="value-prop-text">
              e show you how to personalize your room, the steps to follow, and
              tips for customizing your space.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article15Frame}
              alt="How to personalize room – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>September 10, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-mentor"
              >
                What is a Real Estate Mentor and How to Choose One
              </Link>
            </h2>
            <div className="value-prop-text">
              Real estate mentors are experts in the field and provide advice to
              people interested in the real estate market. We guide you on how
              to choose your mentor.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article14Frame}
              alt="Real estate mentor – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>September 5, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-pricing"
              >
                Real Estate Advertising: How to Create It and What Types Are
                There?
              </Link>
            </h2>
            <div className="value-prop-text">
              Real estate pricing involves setting the optimal price for your
              property and maximizing its value. We teach you different methods
              of real estate pricing.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article13Frame}
              alt="Real estate pricing – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>September 3, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-advertising"
              >
                Real Estate Advertising: How to Create It and What Types Are
                There?
              </Link>
            </h2>
            <div className="value-prop-text">
              Create real estate advertising easily using different tactics and
              types of advertising with examples of creative advertising. 🏡
            </div>
          </div>
          <div className="picture">
            <img
              src={Article12Frame}
              alt="Real estate advertising – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>August 30, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-market-trends"
              >
                Current Real Estate Market Trends
              </Link>
            </h2>
            <div className="value-prop-text">
              We explore how the real estate market has evolved and what the
              current trends in the digital real estate market are.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article11Frame}
              alt="Real estate market trends – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>August 28, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-marketing"
              >
                Real Estate Marketing: What It Is and How to Apply It
              </Link>
            </h2>
            <div className="value-prop-text">
              Real estate marketing is a set of strategies and techniques
              designed to sell real estate properties. Discover the types and
              how to apply them.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article10Frame}
              alt="Real estate marketing – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>August 23, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/increase-home-value"
              >
                Increase Home Value Quickly and Easily
              </Link>
            </h2>
            <div className="value-prop-text">
              We show you how to increase the value of your home quickly and
              easily with several tips and advice to improve your home before
              selling it &#127969;
            </div>
          </div>
          <div className="picture">
            <img
              src={Article9Frame}
              alt="Increase home value – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>August 21, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/multifunctional-spaces"
              >
                Multifunctional Spaces: What Are They and How to Optimize Space?
              </Link>
            </h2>
            <div className="value-prop-text">
              Multifunctional spaces aim to maximize the use of available space
              by using the same area for different functions.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article8Frame}
              alt="Multifunctional spaces – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 August, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/home-staging-examples"
              >
                Home Staging Examples: Before and After
              </Link>
            </h2>
            <div className="value-prop-text">
              Are you looking for home staging before and after examples? To
              give you an idea of the power of this technique, here are some
              home staging examples for you.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article7Frame}
              alt="Home Staging Example – Before and After – Illustrative Cover"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 July, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-make-sky-blue-real-estate-photo"
              >
                How to Make the Sky Blue in Your Property Photos
              </Link>
            </h2>
            <div className="value-prop-text">
              Have you ever wondered how to make the sky blue in all your
              property photos? Here you'll find out how.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article6Frame}
              alt="How to turn sky blue in real estate photos - Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 July, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
              >
                How to Ensure Your Real Estate Listing Cover Photo Speeds Up the
                Sale
              </Link>
            </h2>
            <div className="value-prop-text">
              Choose and generate cover photos in your real estate listings that
              catch attention and help you sell
            </div>
          </div>
          <div className="picture">
            <img
              src={Article5Frame}
              alt="How to pick the right real estate listing cover – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 June, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/sell-more-properties-with-ai"
              >
                How Can AI Help Real Estate Agents Sell?
              </Link>
            </h2>
            <div className="value-prop-text">
              An explanation about what is AI and how it can help on the sale of
              real estate properties
            </div>
          </div>
          <div className="picture">
            <img
              src={Article4Frame}
              alt="Cover of the article about how can AI help real estate agents sell – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 April, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-sell-properties-with-ai"
              >
                How to use AI to sell real estate properties
              </Link>
            </h2>
            <div className="value-prop-text">
              In this article we will explain how to ue AI to help you sell real
              estate properties
            </div>
          </div>
          <div className="picture">
            <img
              src={Article1Frame}
              alt="How to use AI to sell properties – Decorative illustration"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
