import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageRoomUnderConstruction.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToRenovateFrame1.jpg";

function HowToVirtualStagingConstructionDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Wie man Bilder von Baustellen transformiert
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung attraktiver virtueller Home Stagings für
            Immobilien im Bau
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich die Präsentation einer Baustelle verbessern?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie Bilder von Baustellen mit wenigen Klicks in
              attraktive virtuelle Home Stagings verwandeln. Gehen Sie zunächst
              zum <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
              Menü und wählen Sie{" "}
              <span style={{ fontWeight: "500" }}>"Renovierung"</span>.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Wie man virtuelles Home Staging für Baustellen erstellt – Beispiele"
            />
            <br />
            <h2 className="article-subtitleh2">
              Schritte zur Transformation eines Baustellenbildes
            </h2>
            <p className="article-text">
              Folgen Sie diesen einfachen Schritten, um eine attraktive
              Visualisierung Ihrer Baustelle zu erstellen:
            </p>
            <ol>
              <li>
                Laden Sie das Bild der Baustelle hoch, das Sie transformieren
                möchten
              </li>
              <li>
                Wählen Sie das Bild aus, um mit der Bearbeitung zu beginnen
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Renovierung"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Renovierungsmenü für Baustellen"
            />
            <p className="article-text">
              Im <span style={{ fontWeight: "500" }}>"Renovierung"</span> Menü
              folgen Sie diesen Schritten:
            </p>
            <ol>
              <li>
                Wählen Sie einen <span style={{ fontWeight: "500" }}>Stil</span>{" "}
                als Referenz für das Endergebnis
              </li>
              <li>
                Aktivieren Sie die Option{" "}
                <span style={{ fontWeight: "500" }}>"Fenster beibehalten"</span>{" "}
                um die ursprüngliche Struktur zu erhalten
              </li>
              <li>
                Aktivieren Sie{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span> um Möbel
                im Bild einzufügen
              </li>
              <li>
                Wählen Sie den{" "}
                <span style={{ fontWeight: "500" }}>Raumtyp</span> entsprechend
                des Raums (Schlafzimmer, Wohnzimmer, etc.)
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Bild generieren"</span> und
                warten Sie einige Minuten, bis der Prozess abgeschlossen ist
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für bessere Ergebnisse:
                <ul>
                  <li>
                    Stellen Sie sicher, dass das Foto die Raumstruktur klar
                    zeigt
                  </li>
                  <li>
                    Sie können zuerst "Möblieren" und dann "Renovieren" für
                    bessere Ergebnisse
                  </li>
                  <li>
                    Wählen Sie einen Stil, der zum Profil der Zielkäufer passt
                  </li>
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Warum sollte man Baustellenbilder transformieren?
            </h2>
            <p className="article-text">
              Die Präsentation von Baustellenbildern kann den Verkauf einer
              Immobilie erschweren. Durch die Transformation dieser Bilder in
              attraktive Visualisierungen erreichen Sie:
            </p>
            <ul>
              <li>Käufern helfen, das Endergebnis zu visualisieren</li>
              <li>Mehr qualifizierte Leads generieren</li>
              <li>Den Verkaufsprozess beschleunigen</li>
              <li>Sich von anderen Neubauprojekten abheben</li>
            </ul>
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingConstructionDe;
