import React from "react";

const DoorPricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.5 15.5C15.5 13.9898 15.2025 12.4944 14.6246 11.0991C14.0467 9.7039 13.1996 8.43615 12.1317 7.36827C11.0639 6.3004 9.7961 5.45331 8.40086 4.87539C7.00562 4.29746 5.5102 4 4 4L4 15.5"
        stroke="#F8792A"
        stroke-width="1.6"
      />
    </svg>
  );
};

export default DoorPricingIcon;
