import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageRoomUnderConstruction.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToRenovateFrame1.jpg";

function HowToVirtualStagingConstructionFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment Transformer les Images de Biens en Construction
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la création de home stagings virtuels attrayants pour
            les biens en construction
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Comment puis-je améliorer la présentation d'un bien en
              construction ?
            </h2>
            <p className="article-text">
              Avec Pedra, vous pouvez transformer des images de biens en
              construction en home stagings virtuels attrayants en quelques
              clics. Pour commencer, accédez au menu{" "}
              <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
              sélectionnez{" "}
              <span style={{ fontWeight: "500" }}>"Rénovation"</span>.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Comment créer un home staging virtuel pour un bien en construction – Exemples"
            />
            <br />
            <h2 className="article-subtitleh2">
              Étapes pour transformer une image de bien en construction
            </h2>
            <p className="article-text">
              Pour obtenir une visualisation attrayante de votre bien en
              construction, suivez ces étapes simples :
            </p>
            <ol>
              <li>
                Téléchargez l'image de l'espace en construction que vous
                souhaitez transformer
              </li>
              <li>Sélectionnez l'image pour commencer à l'éditer</li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>{" "}
                puis sur <span style={{ fontWeight: "500" }}>"Rénovation"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu de rénovation pour bien en construction"
            />
            <p className="article-text">
              Dans le menu{" "}
              <span style={{ fontWeight: "500" }}>"Rénovation"</span> vous
              devrez suivre ces étapes :
            </p>
            <ol>
              <li>
                Sélectionnez un <span style={{ fontWeight: "500" }}>style</span>{" "}
                comme référence pour le résultat final
              </li>
              <li>
                Activez l'option{" "}
                <span style={{ fontWeight: "500" }}>
                  "Préserver les fenêtres"
                </span>{" "}
                pour maintenir la structure d'origine
              </li>
              <li>
                Activez <span style={{ fontWeight: "500" }}>"Meubler"</span>{" "}
                pour inclure du mobilier dans l'image
              </li>
              <li>
                Sélectionnez le{" "}
                <span style={{ fontWeight: "500" }}>type d'espace</span> selon
                la pièce (chambre, salon, etc.)
              </li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Générer l'image"</span> et
                attendez quelques minutes que le processus se termine
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Conseils pour de meilleurs résultats :
                <ul>
                  <li>
                    Assurez-vous que la photo montre clairement la structure de
                    l'espace
                  </li>
                  <li>
                    Vous pouvez d'abord "Meubler" puis "Rénover" pour obtenir de
                    meilleurs résultats
                  </li>
                  <li>
                    Choisissez un style qui correspond au profil de l'acheteur
                    cible
                  </li>
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Pourquoi transformer les images de biens en construction ?
            </h2>
            <p className="article-text">
              Présenter des images de biens en construction peut rendre la vente
              plus difficile. En transformant ces images en rendus attrayants,
              vous pourrez :
            </p>
            <ul>
              <li>Aider les acheteurs à visualiser le résultat final</li>
              <li>Générer plus de leads qualifiés</li>
              <li>Accélérer le processus de vente</li>
              <li>Se démarquer des autres projets neufs</li>
            </ul>
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingConstructionFr;
