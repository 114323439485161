// Home.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";
import GoogleIcon from "./Icons/GoogleIcon.jsx";

// Images

import REMAXLogo from "./Images/customer-logos/customer-logo-remax.png";
import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import ENGELLogo from "./Images/customer-logos/customer-logo-agenthia.png";
import HomeDecoration1 from "./Images/HomeDecoration1.png";
import HomeDecoration2 from "./Images/HomeDecoration2.png";
import TitleDecoration1 from "./Images/TitleDecoration1.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import PepeGomez from "./Images/Pepe Gomez image.png";
import CarmeHuges from "./Images/Carme Huges image.png";
import SoledadTerol from "./Images/Soledad Terol image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomePageBlueSkyBefore from "./Images/HomePageBlueSkyBefore.jpg";
import HomePageBlueSkyAfter from "./Images/HomePageBlueSkyAfter.jpg";
import HomePageEmptyBefore from "./Images/HomePageEmptyBefore.jpg";
import HomePageEmptyAfter from "./Images/HomePageEmptyAfter.jpg";
import HomePageEnhanceBefore from "./Images/HomePageEnhanceBefore.jpg";
import HomePageEnhanceAfter from "./Images/HomePageEnhanceAfter.jpg";
import HomePageEnlightBefore from "./Images/HomePageEnlightBefore.jpg";
import HomePageEnlightAfter from "./Images/HomePageEnlightAfter.jpg";
import HomePageFurnishBefore from "./Images/HomePageFurnishBefore.jpg";
import HomePageFurnishAfter from "./Images/HomePageFurnishAfter.jpg";
import HomePagePoolBefore from "./Images/HomePagePoolBefore.jpg";
import HomePagePoolAfter from "./Images/HomePagePoolAfter.jpg";
import HomePageRemoveWatermarkBefore from "./Images/HomePageRemoveWatermarkBefore.jpg";
import HomePageRemoveWatermarkAfter from "./Images/HomePageRemoveWatermarkAfter.jpg";
import HomePageRenovateBefore from "./Images/HomePageRenovateBefore.jpg";
import HomePageRenovateAfter from "./Images/HomePageRenovateAfter.jpg";
import HomePageRenovateExteriorBefore from "./Images/HomePageRenovateExteriorBefore.jpg";
import HomePageRenovateExteriorAfter from "./Images/HomePageRenovateExteriorAfter.jpg";
import HomeDrawFloorPlan from "./Images/HomeDrawFloorPlan.png";
import HomeRendersFromFloorPlan from "./Images/HomeRendersFromFloorPlan.png";
import HomeStep1 from "./Images/HomeStep1En.png";
import HomeStep2 from "./Images/HomeStep2En.png";
import HomeStep3 from "./Images/HomeStep3En.png";
import HeroArrow from "./Images/HeroArrow.png";

// Icons
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import PricingCard from "./Components/PricingCard.jsx";

// Update the animation styles
const progressBarStyle = `
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fade-transition {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-transition.fade-out {
    opacity: 0;
  }
`;

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function Home(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);
  const [FAQ7, setFAQ7] = useState(false);
  const [FAQ8, setFAQ8] = useState(false);
  const [FAQ9, setFAQ9] = useState(false);

  const examples = [
    {
      name: "Furnish",
      beforeImage: BeforeFurnish,
      afterImage: AfterFurnish,
      label: "Furnish",
    },
    {
      name: "Empty",
      beforeImage: BeforeEmpty,
      afterImage: AfterEmpty,
      label: "Empty",
    },
    {
      name: "Renovate",
      beforeImage: BeforeRenovate,
      afterImage: AfterRenovate,
      label: "Renovate",
    },
    {
      name: "Enhance",
      beforeImage: BeforeEnhance,
      afterImage: AfterEnhance,
      label: "Enhance",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Add styling for header
    const header = document.querySelector("header");
    if (header) {
      // Reset background color first
      header.style.backgroundColor = "#fff";

      // Update based on current path
      if (window.location.pathname === "/") {
        header.style.backgroundColor = "#f7f7f7";
      }
    }

    // Preload all example images
    examples.forEach((example) => {
      const beforeImg = new Image();
      const afterImg = new Image();
      beforeImg.src = example.beforeImage;
      afterImg.src = example.afterImage;
    });

    // Cleanup function to reset header style when component unmounts
    return () => {
      if (header) {
        header.style.backgroundColor = "#fff";
      }
    };
    // eslint-disable-next-line
  }, []); // Re-run when pathname changes

  // Add loading priority to first images
  const [example, setExample] = useState(examples[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progressKey, setProgressKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Start the interval when component mounts
    const newIntervalId = setInterval(() => {
      handleNextImage();
    }, 4000);
    setIntervalId(newIntervalId);

    // Cleanup interval on component unmount
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array means this only runs on mount/unmount

  const handleNextImage = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setExample((currentExample) => {
        const currentIndex = examples.findIndex(
          (ex) => ex.name === currentExample.name
        );
        const nextIndex = (currentIndex + 1) % examples.length;
        return examples[nextIndex];
      });
      setIsTransitioning(false);
      setProgressKey((prev) => prev + 1);
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Pedra",
              "url": "https://pedra.so",
              "sameAs": "https://maps.app.goo.gl/uYEqAdxAUWjnucKk9",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "50"
              }
            }
          `}
        </script>
      </Helmet>
      <style>{progressBarStyle}</style>

      {/* NEW HERO */}
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px 0px 20px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <h1 className="new-hero-header">
          Show your properties' potential in 1 click
        </h1>

        <h2 className="new-hero-paragraph">
          Create virtual home staging and enhance your photos in seconds with
          Pedra
        </h2>

        <button
          className="button-top-of-page"
          onClick={redirectToPage}
          style={{
            margin: "0 auto 16px",
            padding: "12px 32px",
            fontSize: "20px",
          }}
        >
          Try it for free <ArrowIcon />
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginBottom: "12px",
          }}
        >
          <div className="new-hero-reviews-container">
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <GoogleIcon />
              <span
                style={{ color: "#333", fontWeight: "400", fontSize: "16px" }}
              >
                5.0
              </span>
              <div style={{ color: "#FFD700", letterSpacing: "2px" }}>
                ★★★★★
              </div>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <img
                className="testimonials-on-header-image"
                src={VictorLaviosa}
                alt="Victor Laviosa, Director of Photography at aProperties"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={Soma}
                alt="Francisco Somacarrera, Real Estate Agent at Huspy"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={DanieldelaMata}
                alt="Daniel de la Mata, CEO at Actívox"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={MariaJoseGilart}
                alt="Maria José Gilart, CEO at Gilart Consultors"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Emanuela Cosma"
              ></img>
            </div>
            <span style={{ color: "#333" }}> 20,000+ trust us</span>
          </div>
        </div>

        {/* Rest of the hero section with images */}
        <div className="hero-images-container">
          <img
            src={example.beforeImage}
            alt={`Before virtually ${example.name.toLowerCase()}ing the room`}
            className={`hero-before-image fade-transition ${
              isTransitioning ? "fade-out" : ""
            }`}
            loading="eager"
            fetchPriority="high"
            decoding="async"
          />
          <div className="image-arrow-container">
            <img
              src={HeroArrow}
              alt="Arrow icon"
              className="image-arrow"
              loading="eager"
              fetchPriority="high"
            />
          </div>
          <div className="hero-after-image">
            <img
              src={example.afterImage}
              alt={`After virtually ${example.name.toLowerCase()}ing the room`}
              className={`fade-transition ${isTransitioning ? "fade-out" : ""}`}
              loading="eager"
              fetchPriority="high"
              decoding="async"
              style={{
                width: "100%",
                borderRadius: "8px 8px 0 0",
                borderTop: "3px solid #f8792a",
                borderLeft: "3px solid #f8792a",
                borderRight: "3px solid #f8792a",
                display: "block",
              }}
            />
            <div
              className={`hero-loader fade-transition ${
                isTransitioning ? "fade-out" : ""
              }`}
              style={{
                position: "absolute",
                bottom: "16px",
                left: "16px",
                padding: "8px 12px 8px 10px",
                display: "flex",
                overflow: "hidden",
                cursor: "default",
              }}
            >
              <div
                key={progressKey}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background:
                    "linear-gradient(to right, rgba(248, 121, 42, 0.1), rgba(248, 121, 42, 0.1))",
                  transform: "translateX(-100%)",
                  animation: "progress 4s linear infinite",
                }}
              />
              {example.name === "Furnish" && <PurpleFurnishIcon />}
              {example.name === "Empty" && <PurpleEmptyIcon />}
              {example.name === "Renovate" && <PurpleRenovateIcon />}
              {example.name === "Enhance" && <PurpleEnhanceIcon />}
              <span style={{ position: "relative", zIndex: 1 }}>
                {example.label}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="section-one-column-left"
        style={{ margin: "0px 24px", padding: "0px 24px" }}
      >
        <div className="customer-logo-container" style={{ color: "#878787" }}>
          Real estate teams already trust Pedra:
          <CustomerLogos />
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              How does it work?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Paso 1 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Upload images
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Upload one or more property images.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Paso 2 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transform
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Click on "Edit with AI" to transform the images.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Paso 3 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Wait and download
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Download your AI-edited images.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "I had to sell an apartment that needed renovation, and I used
              Pedra to create the renders in seconds. The results were
              incredible! It helped me sell it much faster, now I recommend it
              to everyone."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="EXP Logo for Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Real Estate Agent</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration1}
          alt="Pedra website decoration"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "1200px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                Get images that help you{" "}
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  sell more
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-10px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                    }}
                  />
                </span>
              </h2>
              <div className="title-secondary">
                Furnish, renovate, declutter, enhance, etc. The virtual home
                staging tool you need to create images that sell more in just
                one click.
              </div>
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageFurnishBefore}
                      afterImage={HomePageFurnishAfter}
                      altBefore={
                        "Before furnishing the room with Pedra's virtual home staging tool"
                      }
                      altAfter={
                        "After furnishing the room with Pedra's virtual home staging tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Furnish a room</h2>
                    <div className="feature-text home">
                      Use Pedra's AI to create virtual home staging.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEmptyBefore}
                      afterImage={HomePageEmptyAfter}
                      altBefore={
                        "Before emptying the room with Pedra's AI tool"
                      }
                      altAfter={"After emptying the room with Pedra's AI tool"}
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Empty a room</h2>
                    <div className="feature-text home">
                      Use Pedra's AI to empty rooms in 1 click.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateBefore}
                      afterImage={HomePageRenovateAfter}
                      altBefore={
                        "Before renovating the room with Pedra's AI tool"
                      }
                      altAfter={
                        "After renovating the room with Pedra's AI tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Renovate spaces</h2>
                    <div className="feature-text home">
                      Use Pedra's AI to automatically renovate spaces.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateExteriorBefore}
                      afterImage={HomePageRenovateExteriorAfter}
                      altBefore={
                        "Before renovating the exterior with Pedra's virtual home staging AI tool"
                      }
                      altAfter={
                        "After renovating the exterior with Pedra's virtual home staging AI tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Renovate exteriors</h2>
                    <div className="feature-text home">
                      Use Pedra's virtual home staging to transform facades and
                      gardens.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePagePoolBefore}
                      afterImage={HomePagePoolAfter}
                      altBefore={
                        "Before virtual home staging of the pool with Pedra's AI tool"
                      }
                      altAfter={
                        "After virtual home staging of the pool with Pedra's AI tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Fix pools</h2>
                    <div className="feature-text home">
                      Use Pedra's virtual home staging to visualize pools and
                      transform your garden.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageBlueSkyBefore}
                      afterImage={HomePageBlueSkyAfter}
                      altBefore={
                        "Before virtual home staging of the sky with Pedra's AI tool"
                      }
                      altAfter={
                        "After virtual home staging of the sky with Pedra's AI tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Make the sky blue</h2>
                    <div className="feature-text home">
                      Use Pedra's virtual home staging to improve the sky in
                      your real estate photos.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRemoveWatermarkBefore}
                      afterImage={HomePageRemoveWatermarkAfter}
                      altBefore={
                        "Before removing watermark with Pedra's virtual home staging AI tool"
                      }
                      altAfter={
                        "After removing watermark with Pedra's virtual home staging AI tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Remove watermarks</h2>
                    <div className="feature-text home">
                      Use Pedra's virtual home staging to remove watermarks from
                      your photos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnlightBefore}
                      afterImage={HomePageEnlightAfter}
                      altBefore={
                        "Before brightening the room with Pedra's virtual home staging AI tool"
                      }
                      altAfter={
                        "After brightening the room with Pedra's virtual home staging AI tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Brighten spaces</h2>
                    <div className="feature-text home">
                      Use Pedra's AI to improve the lighting in your photos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnhanceBefore}
                      afterImage={HomePageEnhanceAfter}
                      altBefore={
                        "Before improving photography and perspective with Pedra's virtual home staging AI tool"
                      }
                      altAfter={
                        "After improving photography and perspective with Pedra's virtual home staging AI tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Improve and correct photos
                    </h2>
                    <div className="feature-text home">
                      Use Pedra to correct perspectives and improve color and
                      light in photos
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Excellent virtual home staging software, very simple to use with
              great results"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src={REMAXLogo}
                alt="REMAX Logo for Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={SoledadTerol}
                alt="Soledad Terol"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Soledad Terol</div>
                <div className="quote-author-company">Real Estate Agent</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration2}
          alt=""
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "800px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                <span style={{ position: "relative" }}>
                  Accelerate
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-8px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                    }}
                  />
                </span>{" "}
                any type of real estate sale
              </h2>
              <div className="title-secondary">
                Whether you're selling off-plan, new builds or resale
                properties, with Pedra you'll ensure you can invest in selling
                your property.
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container home">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Create renders from a floor plan photo
                </h2>
                <div className="feature-text">
                  Upload a floor plan image to create photorealistic renders. No
                  architectural experience needed, and you'll have your results
                  in seconds.
                </div>
              </div>
              <img
                src={HomeRendersFromFloorPlan}
                alt="Virtual home staging tools with AI, floor plan renders - Software screenshot"
                width="1000"
                height="400"
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "2.5/1",
                }}
              />
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-small home">
                  <div className="feature-text-container ">
                    <OrangeVideoIcon />
                    <h2 className="feature-title ">
                      Virtual videos from images
                    </h2>
                    <div className="feature-text ">
                      Create reports and videos from a single image that will
                      help you sell your property better. And best of all, with
                      just 1 click.
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "30px",
                      marginBottom: "-8px",
                    }}
                  >
                    <video
                      width="100%"
                      height="auto"
                      playsInline
                      autoPlay
                      muted
                      loop
                      alt="Virtual home staging software, virtual videos - Video"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderLeft: "1px solid #EEEEEE",
                        borderRight: "1px solid #EEEEEE",
                        borderTop: "1px solid #EEEEEE",
                      }}
                    >
                      <source
                        src="https://pedraimages.s3.eu-west-3.amazonaws.com/HomeVirtualVideo.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-small home">
                  <div className="feature-text-container">
                    <OrangePaletteIcon />
                    <h2 className="feature-title ">
                      Get floor plans in seconds
                    </h2>
                    <div className="feature-text ">
                      Draw floor plans easily to better position your real
                      estate listings.
                    </div>
                  </div>
                  <img
                    src={HomeDrawFloorPlan}
                    alt="Virtual home staging tool, floor plans - Software screenshot"
                    width="600"
                    height="400"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">“Pedra is wonderful”</h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={ENGELLogo}
                alt="Engel & Völkers Logo for Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={CarmeHuges}
                alt="Carme Hugues"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Carme Hugues</div>

                <div className="quote-author-company">Real Estate Agent</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <PricingCard />
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Try Pedra <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Founder of Pedra"
              />
              <div>
                Questions? Send us an email at{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                or schedule a 15min demo with our founder Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="section-one-column-left">
        <div className="title-container">Questions and Answers</div>
      </div>
      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">What is AI rendering?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                AI rendering, in the context of architectural design and
                visualization, refers to the application of artificial
                intelligence (AI) to generate high-quality rendered images of
                architectural spaces. AI is used to enhance and optimize the
                rendering process, enabling more realistic and efficient
                results.
                <br />
                <br />
                AI in rendering allows achieving results faster than with
                traditional methods. Old methods involve hours or weeks of work
                as they require recreating 3D models of rooms and processing
                rendering systems. With AI, this has changed. Renders can be
                generated from images and create spectacular results from the
                original image with new styles applied.
                <br />
                <br />
                Artificial intelligence in real estate property image rendering
                not only saves time but also provides flexibility when imagining
                spaces for real estate agents, owners, and buyers. The
                technology helps visualize the potential of spaces in an
                unprecedented way.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">What is virtual home staging?</h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                The{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  virtual home staging
                </Link>{" "}
                is a technique used in the real estate market to present a
                property in a more attractive and appealing way to potential
                buyers or renters, but unlike traditional home staging, which
                involves physical preparation and decoration of the space,
                virtual home staging is done digitally.
                <br />
                <br />
                In virtual home staging, tools such as 3D design software,
                virtual reality (VR) and digital photography are used to create
                digital representations of a property. These representations can
                include high-quality rendered images, virtual walkthroughs of
                the property and interactive visualizations that show how the
                space would look with different configurations of furniture and
                decoration.
                <br />
                <br />
                The goal of virtual home staging is to allow potential buyers or
                renters to have a better idea of how the property would look
                once furnished and decorated, which can help to increase
                interest and the chances of selling or renting. Additionally,
                virtual home staging can be especially useful in situations
                where the property is not completely furnished or empty, as it
                can help customers to visualize the potential of the space.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Is Pedra easy to use?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Yes, Pedra is designed to be easy and quick to use.
                <br />
                <br />
                When creating a project, you can upload one or more images. You
                can then select images, which will display a bar of options. The
                options include{" "}
                <span style={{ fontWeight: "600px" }}> Edit with AI</span>,
                adding a watermark, downloading in various formats and more.
                Creating a project also gives you the option to draw a floor
                plan. The floor plan contains several figures, which you can
                edit by selecting them, moving them or through the menu.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">How much does Pedra cost?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra costs 29€ plus VAT per month.
                <br />
                <br />
                It has no commitment, so you can cancel it whenever you want.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">How do I hire Pedra?</h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                Hiring Pedra is very simple:
                <br />
                <br />
                1. Visit our website at www.pedra.so
                <br />
                2. Click on <strong>"Try Pedra"</strong> to register
                <br />
                3. Once inside the platform, select{" "}
                <strong>"Upgrade to Pro"</strong> and then{" "}
                <strong>"Subscribe"</strong>
                <br />
                <br />
                Payment is made by credit or debit card. The subscription costs
                €29 + VAT monthly and you can cancel it at any time.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">Can I try Pedra before hiring?</h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                Of course! Send us an email at felix@pedra.so and we'll be happy
                to help you get to know the platform. We usually respond in less
                than 30 minutes and can schedule a personalized demo.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ7(!FAQ7)}>
              <h3 className="question-title">
                Does the subscription have a commitment period?
              </h3>
              {FAQ7 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ7 && (
              <div className="answer-container">
                No, our subscription is completely flexible with no commitment
                periods. You can easily cancel it whenever you want directly
                from your account:
                <br />
                <br />
                1. Access your profile
                <br />
                2. Select <strong>"Edit subscription"</strong>
                <br />
                3. Click on <strong>"Cancel subscription"</strong>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ8(!FAQ8)}>
              <h3 className="question-title">
                What is the monthly image limit?
              </h3>
              {FAQ8 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ8 && (
              <div className="answer-container">
                Your subscription includes 100 image generations per monthly
                period. The billing cycle starts the day you hire Pedra and
                renews on the same day of the following month. For example, if
                you subscribe on January 15th, your cycle will run from January
                15th to February 14th.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ9(!FAQ9)}>
              <h3 className="question-title">
                What happens if I need more than 100 images per month?
              </h3>
              {FAQ9 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ9 && (
              <div className="answer-container">
                You have two options:
                <br />
                <br />
                1. Wait for your monthly cycle to renew to receive 100 new
                generations
                <br />
                2. Purchase additional generations for just €0.20 each
                <br />
                <br />
                Additional generations are one-time payments and don't affect
                your monthly fee. Plus, they don't expire, so you can accumulate
                them and use them when you need them.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
