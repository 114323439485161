// HomeEs.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";

// Images

import REMAXLogo from "./Images/customer-logos/customer-logo-remax.png";
import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import ENGELLogo from "./Images/customer-logos/customer-logo-agenthia.png";
import HomeDecoration1 from "./Images/HomeDecoration1.png";
import HomeDecoration2 from "./Images/HomeDecoration2.png";
import HeroArrow from "./Images/HeroArrow.png";
import TitleDecoration1 from "./Images/TitleDecoration1.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import PepeGomez from "./Images/Pepe Gomez image.png";
import CarmeHuges from "./Images/Carme Huges image.png";
import SoledadTerol from "./Images/Soledad Terol image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomePageBlueSkyBefore from "./Images/HomePageBlueSkyBefore.jpg";
import HomePageBlueSkyAfter from "./Images/HomePageBlueSkyAfter.jpg";
import HomePageEmptyBefore from "./Images/HomePageEmptyBefore.jpg";
import HomePageEmptyAfter from "./Images/HomePageEmptyAfter.jpg";
import HomePageEnhanceBefore from "./Images/HomePageEnhanceBefore.jpg";
import HomePageEnhanceAfter from "./Images/HomePageEnhanceAfter.jpg";
import HomePageEnlightBefore from "./Images/HomePageEnlightBefore.jpg";
import HomePageEnlightAfter from "./Images/HomePageEnlightAfter.jpg";
import HomePageFurnishBefore from "./Images/HomePageFurnishBefore.jpg";
import HomePageFurnishAfter from "./Images/HomePageFurnishAfter.jpg";
import HomePagePoolBefore from "./Images/HomePagePoolBefore.jpg";
import HomePagePoolAfter from "./Images/HomePagePoolAfter.jpg";
import HomePageRemoveWatermarkBefore from "./Images/HomePageRemoveWatermarkBefore.jpg";
import HomePageRemoveWatermarkAfter from "./Images/HomePageRemoveWatermarkAfter.jpg";
import HomePageRenovateBefore from "./Images/HomePageRenovateBefore.jpg";
import HomePageRenovateAfter from "./Images/HomePageRenovateAfter.jpg";
import HomePageRenovateExteriorBefore from "./Images/HomePageRenovateExteriorBefore.jpg";
import HomePageRenovateExteriorAfter from "./Images/HomePageRenovateExteriorAfter.jpg";
import HomeDrawFloorPlan from "./Images/HomeDrawFloorPlan.png";
import HomeRendersFromFloorPlan from "./Images/HomeRendersFromFloorPlanPt.png";
import HomeStep1 from "./Images/HomeStep1Pt.png";
import HomeStep2 from "./Images/HomeStep2Pt.png";
import HomeStep3 from "./Images/HomeStep3Pt.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";

// Icons
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import GoogleIcon from "./Icons/GoogleIcon.jsx";
import PricingCardPt from "./Components/PricingCardPt.jsx";

// Update the animation styles
const progressBarStyle = `
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fade-transition {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-transition.fade-out {
    opacity: 0;
  }
`;

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomePt(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  const examples = [
    {
      name: "Furnish",
      beforeImage: BeforeFurnish,
      afterImage: AfterFurnish,
      label: "Mobiliar",
    },
    {
      name: "Empty",
      beforeImage: BeforeEmpty,
      afterImage: AfterEmpty,
      label: "Esvaziar",
    },
    {
      name: "Renovate",
      beforeImage: BeforeRenovate,
      afterImage: AfterRenovate,
      label: "Renovar",
    },
    {
      name: "Enhance",
      beforeImage: BeforeEnhance,
      afterImage: AfterEnhance,
      label: "Melhorar",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Add styling for header
    const header = document.querySelector("header");
    if (header) {
      // Reset background color first
      header.style.backgroundColor = "#fff";

      // Update based on current path
      if (window.location.pathname.startsWith("/pt")) {
        header.style.backgroundColor = "#f7f7f7";
      }
    }
    // Preload all example images
    examples.forEach((example) => {
      const beforeImg = new Image();
      const afterImg = new Image();
      beforeImg.src = example.beforeImage;
      afterImg.src = example.afterImage;
    });

    // Cleanup function to reset header style when component unmounts
    return () => {
      if (header) {
        header.style.backgroundColor = "#fff";
      }
    };
    // eslint-disable-next-line
  }, []); // Re-run when pathname changes

  // Add loading priority to first images
  const [example, setExample] = useState(examples[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progressKey, setProgressKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Start the interval when component mounts
    const newIntervalId = setInterval(() => {
      handleNextImage();
    }, 4000);
    setIntervalId(newIntervalId);

    // Cleanup interval on component unmount
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array means this only runs on mount/unmount

  const handleNextImage = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setExample((currentExample) => {
        const currentIndex = examples.findIndex(
          (ex) => ex.name === currentExample.name
        );
        const nextIndex = (currentIndex + 1) % examples.length;
        return examples[nextIndex];
      });
      setIsTransitioning(false);
      setProgressKey((prev) => prev + 1);
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Pedra",
              "url": "https://pedra.so/pt",
              "sameAs": "https://maps.app.goo.gl/uYEqAdxAUWjnucKk9",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "50"
              }
            }
          `}
        </script>
      </Helmet>
      <style>{progressBarStyle}</style>

      {/* NEW HERO */}
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px 0px 20px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <h1 className="new-hero-header">
          Mostre o potencial dos seus imóveis em 1 clique
        </h1>

        <h2 className="new-hero-paragraph">
          Crie home staging virtual e melhore suas fotos em segundos com a Pedra
        </h2>

        <button
          className="button-top-of-page"
          onClick={redirectToPage}
          style={{
            margin: "0 auto 16px",
            padding: "12px 32px",
            fontSize: "20px",
          }}
        >
          Comece grátis <ArrowIcon />
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginBottom: "12px",
          }}
        >
          <div className="new-hero-reviews-container">
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <GoogleIcon />
              <span
                style={{ color: "#333", fontWeight: "400", fontSize: "16px" }}
              >
                5.0
              </span>
              <div style={{ color: "#FFD700", letterSpacing: "2px" }}>
                ★★★★★
              </div>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <img
                className="testimonials-on-header-image"
                src={VictorLaviosa}
                alt="Victor Laviosa, Director of Photography at aProperties"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={Soma}
                alt="Francisco Somacarrera, Real Estate Agent at Huspy"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={DanieldelaMata}
                alt="Daniel de la Mata, CEO at Actívox"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={MariaJoseGilart}
                alt="Maria José Gilart, CEO at Gilart Consultors"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Emanuela Cosma"
              ></img>
            </div>
            <span style={{ color: "#333" }}>
              {" "}
              Mais de 20.000 confiam em nós
            </span>
          </div>
        </div>

        {/* Rest of the hero section with images */}
        <div className="hero-images-container">
          <img
            src={example.beforeImage}
            alt={`Before virtually ${example.name.toLowerCase()}ing the room`}
            className={`hero-before-image fade-transition ${
              isTransitioning ? "fade-out" : ""
            }`}
            loading="eager"
            fetchPriority="high"
            decoding="async"
          />
          <div className="image-arrow-container">
            <img
              src={HeroArrow}
              alt="Arrow icon"
              className="image-arrow"
              loading="eager"
              fetchPriority="high"
            />
          </div>
          <div className="hero-after-image">
            <img
              src={example.afterImage}
              alt={`After virtually ${example.name.toLowerCase()}ing the room`}
              className={`fade-transition ${isTransitioning ? "fade-out" : ""}`}
              loading="eager"
              fetchPriority="high"
              decoding="async"
              style={{
                width: "100%",
                borderRadius: "8px 8px 0 0",
                borderTop: "3px solid #f8792a",
                borderLeft: "3px solid #f8792a",
                borderRight: "3px solid #f8792a",
                display: "block",
              }}
            />
            <div
              className={`hero-loader fade-transition ${
                isTransitioning ? "fade-out" : ""
              }`}
              style={{
                position: "absolute",
                bottom: "16px",
                left: "16px",
                padding: "8px 12px 8px 10px",
                display: "flex",
                overflow: "hidden",
                cursor: "default",
              }}
            >
              <div
                key={progressKey}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background:
                    "linear-gradient(to right, rgba(248, 121, 42, 0.1), rgba(248, 121, 42, 0.1))",
                  transform: "translateX(-100%)",
                  animation: "progress 4s linear infinite",
                }}
              />
              {example.name === "Furnish" && <PurpleFurnishIcon />}
              {example.name === "Empty" && <PurpleEmptyIcon />}
              {example.name === "Renovate" && <PurpleRenovateIcon />}
              {example.name === "Enhance" && <PurpleEnhanceIcon />}
              <span style={{ position: "relative", zIndex: 1 }}>
                {example.label}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="section-one-column-left"
        style={{ margin: "0px 24px", padding: "0px 24px" }}
      >
        <div className="customer-logo-container" style={{ color: "#878787" }}>
          Equipes destas imobiliárias já confiam na Pedra:
          <CustomerLogos />
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              Como funciona?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Passo 1 da Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Envie as imagens
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Envie uma ou várias imagens do imóvel.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Passo 2 da Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transforme
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Clique em "Editar com IA" para transformar as imagens.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Passo 3 da Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Aguarde e baixe
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Baixe suas imagens editadas com IA.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Eu tinha que vender um apartamento para reforma, e usei a Pedra
              para criar os renders em questão de segundos. Os resultados foram
              incríveis! Me ajudou a vendê-lo muito mais rápido, agora recomendo
              para todo mundo."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="Logotipo de EXP para Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="Foto de José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Corretor de Imóveis</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration1}
          alt="Decoração do site da Pedra"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "1200px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                Obtenha imagens que ajudem você a{" "}
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  vender mais
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-10px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>
              </h2>
              <div className="title-secondary">
                Mobilie, renove, esvazie, melhore, etc. A ferramenta de home
                staging virtual que você precisa para{" "}
                <strong>obter imagens que vendem mais</strong> em 1 clique.
              </div>
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageFurnishBefore}
                      afterImage={HomePageFurnishAfter}
                      altBefore={
                        "Antes de mobiliar o ambiente com a ferramenta de home staging virtual da Pedra"
                      }
                      altAfter={
                        "Depois de mobiliar o ambiente com a ferramenta de home staging virtual da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Mobilie um ambiente</h2>
                    <div className="feature-text home">
                      Use a IA da Pedra para fazer home staging virtual.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEmptyBefore}
                      afterImage={HomePageEmptyAfter}
                      altBefore={
                        "Antes de esvaziar o ambiente com a ferramenta de IA da Pedra"
                      }
                      altAfter={
                        "Depois de esvaziar o ambiente com a ferramenta de IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Esvazie um ambiente</h2>
                    <div className="feature-text home">
                      Use a IA da Pedra para esvaziar ambientes em 1 clique.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateBefore}
                      afterImage={HomePageRenovateAfter}
                      altBefore={
                        "Antes de renovar o ambiente com a ferramenta de IA da Pedra"
                      }
                      altAfter={
                        "Depois de renovar o ambiente com a ferramenta de IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Renove espaços</h2>
                    <div className="feature-text home">
                      Use a IA da Pedra para renovar espaços automaticamente.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateExteriorBefore}
                      afterImage={HomePageRenovateExteriorAfter}
                      altBefore={
                        "Antes de renovar o exterior com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      altAfter={
                        "Depois de renovar o exterior com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Renove exteriores</h2>
                    <div className="feature-text home">
                      Use o home staging virtual da Pedra para transformar
                      fachadas e jardins.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePagePoolBefore}
                      afterImage={HomePagePoolAfter}
                      altBefore={
                        "Antes do home staging virtual da piscina com a ferramenta de IA da Pedra"
                      }
                      altAfter={
                        "Depois do home staging virtual da piscina com a ferramenta de IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Conserte piscinas</h2>
                    <div className="feature-text home">
                      Use o home staging virtual da Pedra para visualizar
                      piscinas e transformar seu jardim.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageBlueSkyBefore}
                      afterImage={HomePageBlueSkyAfter}
                      altBefore={
                        "Antes do home staging virtual do céu com a ferramenta de IA da Pedra"
                      }
                      altAfter={
                        "Depois do home staging virtual do céu com a ferramenta de IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Deixe o céu azul</h2>
                    <div className="feature-text home">
                      Use o home staging virtual da Pedra para melhorar o céu
                      nas suas fotos imobiliárias.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRemoveWatermarkBefore}
                      afterImage={HomePageRemoveWatermarkAfter}
                      altBefore={
                        "Antes de remover a marca d'água com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      altAfter={
                        "Depois de remover a marca d'água com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Remova marcas d'água</h2>
                    <div className="feature-text home">
                      Use o home staging virtual da Pedra para remover marcas
                      d'água das suas fotos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnlightBefore}
                      afterImage={HomePageEnlightAfter}
                      altBefore={
                        "Antes de iluminar o ambiente com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      altAfter={
                        "Depois de iluminar o ambiente com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Ilumine espaços</h2>
                    <div className="feature-text home">
                      Use a IA da Pedra para melhorar a iluminação das suas
                      fotos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnhanceBefore}
                      afterImage={HomePageEnhanceAfter}
                      altBefore={
                        "Antes de melhorar a fotografia e a perspectiva com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      altAfter={
                        "Depois de melhorar a fotografia e a perspectiva com a ferramenta de home staging virtual com IA da Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Melhore e corrija fotos
                    </h2>
                    <div className="feature-text home">
                      Use a Pedra para corrigir perspectivas e melhorar a cor e
                      luz das fotos
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Excelente software para home staging virtual, muito simples de
              usar e com ótimos resultados"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src={REMAXLogo}
                alt="Logotipo da REMAX para Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={SoledadTerol}
                alt="Foto de Soledad Terol"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Soledad Terol</div>
                <div className="quote-author-company">Corretor de Imóveis</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration2}
          alt=""
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "800px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                <span style={{ position: "relative" }}>
                  Acelere
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-4px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>{" "}
                qualquer tipo de venda
              </h2>
              <div className="title-secondary">
                Seja vendendo na planta, imóveis novos ou usados, com a Pedra
                você garante poder investir na venda do seu imóvel.
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container home">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Criar renders a partir da foto de uma planta
                </h2>
                <div className="feature-text">
                  Envie a imagem de uma planta para criar renders
                  fotorrealistas. Não é necessária nenhuma experiência em
                  arquitetura, e em poucos segundos você terá seus resultados.
                </div>
              </div>
              <img
                src={HomeRendersFromFloorPlan}
                alt="Ferramenta de home staging virtual com IA, renders de plantas – Captura de tela do software"
                width="1000"
                height="400"
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "2.5/1",
                }}
              />
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-small home">
                  <div className="feature-text-container ">
                    <OrangeVideoIcon />
                    <h2 className="feature-title ">
                      Vídeos virtuais a partir de imagens
                    </h2>
                    <div className="feature-text ">
                      Crie reportagens e vídeos a partir de uma imagem que
                      ajudarão você a vender seu imóvel melhor. E o melhor de
                      tudo, em apenas 1 clique.
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "30px",
                      marginBottom: "-8px",
                    }}
                  >
                    <video
                      width="100%"
                      height="auto"
                      playsInline
                      autoPlay
                      muted
                      loop
                      alt="Software para home staging virtual, vídeos virtuais – Vídeo"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderLeft: "1px solid #EEEEEE",
                        borderRight: "1px solid #EEEEEE",
                        borderTop: "1px solid #EEEEEE",
                      }}
                    >
                      <source
                        src="https://pedraimages.s3.eu-west-3.amazonaws.com/HomeVirtualVideo.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-small home">
                  <div className="feature-text-container">
                    <OrangePaletteIcon />
                    <h2 className="feature-title ">
                      Obtenha plantas em segundos
                    </h2>
                    <div className="feature-text ">
                      Desenhe plantas de maneira simples para posicionar melhor
                      seus anúncios imobiliários.
                    </div>
                  </div>
                  <img
                    src={HomeDrawFloorPlan}
                    alt="Ferramenta de home staging virtual, plantas – Captura de tela do software"
                    width="600"
                    height="400"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">"A Pedra é maravilhosa"</h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={ENGELLogo}
                alt="Logotipo da Engel & Völkers para Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={CarmeHuges}
                alt="Foto de Carme Hugues"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Carme Hugues</div>

                <div className="quote-author-company">Corretor de Imóveis</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <PricingCardPt />
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Experimente Pedra <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Fundador da Pedra"
              />
              <div>
                ¿Preguntas? Envíanos un email a{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                o agende uma demo de 15min com nosso fundador Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  aqui
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="section-one-column-left">
        <div className="title-container">Perguntas e respostas</div>
      </div>
      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">O que é um render IA?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Um render IA, no contexto do design e visualização
                arquitetônica, refere-se à aplicação da inteligência artificial
                (IA) para gerar imagens renderizadas de alta qualidade de
                espaços arquitetônicos. A inteligência artificial é utilizada
                para melhorar e otimizar o processo de renderização, permitindo
                resultados mais realistas e eficientes.
                <br />
                <br />
                A IA na renderização permite obter resultados de maneira mais
                rápida que com o método tradicional. Os métodos antigos envolvem
                horas ou semanas de trabalho, pois é necessário recriar modelos
                3D dos ambientes e processar os sistemas de renderização. Com a
                IA isso mudou. Os renders podem ser gerados a partir de imagens
                e criar resultados espetaculares a partir da imagem original com
                novos estilos aplicados.
                <br />
                <br />A inteligência artificial na renderização de imagens de
                imóveis não só economiza tempo como também dá flexibilidade na
                hora de imaginar espaços para os corretores, proprietários e
                compradores. A tecnologia ajuda a visualizar o potencial dos
                espaços de uma maneira nunca vista.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">O que é home staging virtual?</h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                O{" "}
                <Link className="article-link" to="/pt/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
                é uma técnica utilizada no mercado imobiliário para apresentar
                um imóvel de maneira mais atraente e agradável aos possíveis
                compradores ou locatários, mas diferentemente do home staging
                tradicional, que implica na preparação física e decoração do
                espaço, o home staging virtual é realizado de forma digital.
                <br />
                <br />
                No home staging virtual, são utilizadas ferramentas como
                software de design 3D, realidade virtual (VR) e fotografia
                digital para criar representações digitais realistas de um
                imóvel. Essas representações podem incluir imagens renderizadas
                de alta qualidade, tours virtuais pelo imóvel e visualizações
                interativas que mostram como o espaço ficaria com diferentes
                configurações de móveis e decoração.
                <br />
                <br />O objetivo do home staging virtual é permitir que os
                possíveis compradores ou locatários tenham uma melhor ideia de
                como o imóvel ficaria uma vez mobiliado e decorado, o que pode
                ajudar a aumentar o interesse e as possibilidades de venda ou
                locação. Além disso, o home staging virtual pode ser
                especialmente útil em situações onde o imóvel não está
                completamente mobiliado ou está vazio, pois pode ajudar os
                clientes a visualizar o potencial do espaço.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">A Pedra é fácil de usar?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Sim, a Pedra foi projetada para ter um uso simples e ágil.
                <br />
                <br />
                Ao criar um projeto você poderá enviar uma ou mais imagens. Você
                poderá clicar para selecionar imagens, o que exibirá uma barra
                de opções. As opções incluem{" "}
                <span style={{ fontWeight: "600px" }}> Editar com IA</span>,
                adicionar marca d'água, baixar em vários formatos e mais.
                Criando um projeto você também terá a opção de desenhar uma
                planta. A planta contém várias figuras, as quais você poderá
                editar selecionando-as, arrastando-as ou através do menu.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Quanto custa a Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                A Pedra custa 29€ mais impostos por mês.
                <br />
                <br />
                Não tem fidelidade, então você pode cancelar quando não precisar
                mais.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePt;
