import React from "react";
import { Link } from "react-router-dom";

function HelpEs() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Bienvenido al Centro de Ayuda de Pedra
            </h1>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Aprende a transformar tus propiedades inmobiliarias con nuestras
                herramientas de home staging virtual.
              </div>
              <div style={{ marginTop: "10px" }}>
                Ya seas nuevo en{" "}
                <Link className="article-link" to="/es">
                  Pedra
                </Link>{" "}
                o quieras dominar nuestras funcionalidades, tenemos guías
                completas para ayudarte a tener éxito.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Primeros pasos con el{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  Home Staging Virtual
                </span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Nuestras herramientas de{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
                te permiten transformar espacios vacíos en habitaciones
                amuebladas, crear propuestas de renovación y mejorar las fotos
                de propiedades - todo sin staging físico.
              </div>
              <div style={{ marginTop: "10px" }}>
                Comienza con estos tutoriales esenciales:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-home-staging-virtual"
                    >
                      Cómo crear Home Staging Virtual
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-vaciar-habitacion-virtualmente"
                    >
                      Cómo vaciar habitaciones virtualmente
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-amueblar-virtualmente"
                    >
                      Cómo amueblar virtualmente
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-anadir-objetos-3d"
                    >
                      Cómo añadir objetos 3D
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-borrar-objeto"
                    >
                      Cómo borrar objetos
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Tutoriales por{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  tipo de habitación
                </span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Aprende a realizar staging y crear renders para diferentes tipos
                de habitaciones:
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Guías de Home Staging Virtual:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-home-staging-virtual-salon"
                    >
                      Guía de Home Staging para Salón
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-home-staging-virtual-dormitorio"
                    >
                      Guía de Home Staging para Dormitorio
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-home-staging-virtual-cocina"
                    >
                      Guía de Home Staging para Cocina
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-home-staging-virtual-bano"
                    >
                      Guía de Home Staging para Baño
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-home-staging-virtual-terraza"
                    >
                      Guía de Home Staging para Terraza
                    </Link>
                  </li>
                </ul>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Guías de Renders:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-render-sala-estar"
                    >
                      Guía de Renders para Salón
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-render-dormitorio"
                    >
                      Guía de Renders para Dormitorio
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-render-cocina"
                    >
                      Guía de Renders para Cocina
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-render-bano"
                    >
                      Guía de Renders para Baño
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-render-terraza"
                    >
                      Guía de Renders para Terraza
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Funciones{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Avanzadas</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Lleva tu staging al siguiente nivel con nuestras herramientas
                avanzadas:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-crear-render-exterior-casa"
                    >
                      Crear Renders de Exteriores
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-dibujar-plano"
                    >
                      Dibujar Planos
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/como-renovar-casas-virtualmente"
                    >
                      Guía de Renovación Virtual
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/es/help/staging-virtual-obra-en-construccion"
                    >
                      Cómo transformar imágenes de obra nueva en construcción
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                ¿Necesitas{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Ayuda?</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                ¿No encuentras lo que buscas? Nuestro equipo de soporte está
                aquí para ayudarte. Contáctanos en felix@pedra.so.
              </div>
              <br />
              <br />
              <div style={{ marginTop: "10px" }}>
                ¿Listo para empezar a transformar tus anuncios inmobiliarios?
              </div>
              <div style={{ marginTop: "10px" }}>
                Elige cualquier tutorial de arriba para comenzar, o visita
                nuestra{" "}
                <Link className="article-link" to="/es/pricing">
                  página de precios
                </Link>{" "}
                para conocer más sobre nuestros planes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpEs;
