import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageRoomUnderConstruction.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToRenovateFrame1.jpg";

function HowToVirtualStagingConstructionPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como transformar imagens de obras em construção
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como criar home stagings virtuais atraentes de
            imóveis em construção
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Como posso melhorar a apresentação de uma obra em construção?
            </h2>
            <p className="article-text">
              Com a Pedra você pode transformar imagens de imóveis em construção
              em home stagings virtuais atraentes em poucos cliques. Para
              começar, acesse o menu{" "}
              <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
              selecione <span style={{ fontWeight: "500" }}>"Renovação"</span>.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Como criar um home staging virtual de imóvel em construção – Exemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              Passos para transformar uma imagem de obra em construção
            </h2>
            <p className="article-text">
              Para conseguir uma visualização atraente da sua obra em
              construção, siga estes passos simples:
            </p>
            <ol>
              <li>
                Faça upload da imagem do espaço em construção que você quer
                transformar
              </li>
              <li>Selecione a imagem para começar a editar</li>
              <li>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                depois em <span style={{ fontWeight: "500" }}>"Renovação"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu de renovação de obra em construção"
            />
            <p className="article-text">
              No menu de <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
              você deverá seguir estes passos:
            </p>
            <ol>
              <li>
                Selecione um <span style={{ fontWeight: "500" }}>estilo</span>{" "}
                como referência para o acabamento final
              </li>
              <li>
                Ative a opção{" "}
                <span style={{ fontWeight: "500" }}>"Preservar janelas"</span>{" "}
                para manter a estrutura original
              </li>
              <li>
                Ative <span style={{ fontWeight: "500" }}>"Mobiliar"</span> para
                incluir mobiliário na imagem
              </li>
              <li>
                Selecione o{" "}
                <span style={{ fontWeight: "500" }}>tipo de espaço</span> de
                acordo com o ambiente (quarto, sala, etc.)
              </li>
              <li>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Gerar imagem"</span> e
                aguarde alguns minutos até que o processo seja concluído
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Dicas para melhores resultados:
                <ul>
                  <li>
                    Certifique-se de que a fotografia mostre claramente a
                    estrutura do espaço
                  </li>
                  <li>
                    Você pode primeiro "Mobiliar" e depois "Renovar" para obter
                    melhores resultados
                  </li>
                  <li>
                    Escolha um estilo que se adeque ao perfil do comprador alvo
                  </li>
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Por que transformar imagens de obras em construção?
            </h2>
            <p className="article-text">
              Apresentar imagens de obras em construção pode dificultar a venda
              de um imóvel. Ao transformar essas imagens em renders atraentes
              você conseguirá:
            </p>
            <ul>
              <li>Ajudar os compradores a visualizar o resultado final</li>
              <li>Gerar mais leads qualificados</li>
              <li>Acelerar o processo de venda</li>
              <li>Destacar-se frente a outros lançamentos imobiliários</li>
            </ul>
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingConstructionPt;
