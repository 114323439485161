import React from "react";
import CheckIcon from "./Icons/CheckIcon";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple";
import EmptyIcon from "./Icons/EmptyIcon";
import HammerIconPricing from "./Icons/HammerIconPricing";
import BrushIconPricing from "./Icons/BrushIconPricing";
import HDIconPricing from "./Icons/HDIconPricing";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon";
import VideoPricingIcon from "./Icons/VideoPricingIcon";
import CustomerLogos from "./CustomerLogos";
import ArrowIcon from "./Icons/ArrowIcon";
import PricingCardPt from "./Components/PricingCardPt";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function PricingPt() {
  return (
    <div
      className="section-one-column-left"
      style={{ display: "flex", flexDirection: "column", gap: "0px" }}
    >
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container" style={{ gap: "0px" }}>
            <div
              className="section-first-title"
              style={{ paddingBottom: "0px" }}
            >
              Experimente nosso plano Pro
            </div>
            <h1 className="section-first-text" style={{ fontSize: "20px" }}>
              Pague por mês,
              <span style={{ color: "#F8792A" }}> cancele quando quiser.</span>
            </h1>
            <div className="section-first-button-container"></div>
          </div>
        </section>
      </section>

      <PricingCardPt />

      <br />
      <br />
      <br />

      <div className="section-one-column-left" style={{ marginBottom: "0px" }}>
        <div className="customer-logo-container">
          MAIS DE 20.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Vocês pensaram em tudo! A Pedra tem tudo o que precisamos!"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Logo da Vives Homes para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingPt;
