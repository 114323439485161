import React from "react";

const ArrowIconOrange = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.76333 15.7633L13.6517 10.875H2.93V9.125H13.6517L8.76333 4.23667L10 3L17 10L10 17L8.76333 15.7633Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default ArrowIconOrange;
