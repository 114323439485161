import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Keep all the existing image imports
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeKitchen from "./Images/curtain-effect/BeforeRenderFromFloorPlan1.png";
import AfterKitchen from "./Images/curtain-effect/AfterRenderFromFloorPlan1.png";
import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeRenderFromFloorPlan2.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterRenderFromFloorPlan2.png";
import BeforeKitchenStaging2 from "./Images/curtain-effect/BeforeRenderFromFloorPlan3.png";
import AfterKitchenStaging2 from "./Images/curtain-effect/AfterRenderFromFloorPlan3.png";
import BeforeKitchenStaging3 from "./Images/curtain-effect/BeforeRenderFromFloorPlan4.png";
import AfterKitchenStaging3 from "./Images/curtain-effect/AfterRenderFromFloorPlan4.png";
import VirtualStagingExample1 from "./Images/HowToFloorPlanRender1En.png";
import VirtualStagingExample2 from "./Images/HowToFloorPlanRender2En.png";
import VirtualStagingExample3 from "./Images/HowToFloorPlanRender3En.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderFromFloorPlan() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeKitchen,
    afterImage: AfterKitchen,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeKitchen,
        afterImage: AfterKitchen,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeKitchenStaging1,
        afterImage: AfterKitchenStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeKitchenStaging2,
        afterImage: AfterKitchenStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeKitchenStaging3,
        afterImage: AfterKitchenStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is floor plan rendering?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Floor plan rendering is an AI-powered service that transforms 2D floor plans into photorealistic room visualizations. This technology allows you to see how each room in your floor plan would look in real life, complete with furniture, materials, and lighting.",
        },
      },
      {
        "@type": "Question",
        name: "What are the benefits of automatic floor plan rendering?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatic floor plan rendering offers several benefits: quick visualization of spaces, the ability to try different styles instantly, high-quality photorealistic results, significant time savings compared to traditional rendering, and the flexibility to experiment with different room layouts and designs.",
        },
      },
      {
        "@type": "Question",
        name: "How does the floor plan rendering process work?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The process is simple: upload your floor plan to our platform, select your preferred styles and customization options for each room, and receive photorealistic renders within minutes. Our AI technology handles all the complex visualization work automatically.",
        },
      },
      {
        "@type": "Question",
        name: "How much does floor plan rendering cost?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Our floor plan rendering service is available for €29 plus VAT per month. This plan allows you to generate up to 100 renders per month, with no long-term commitment required.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeKitchenStaging1,
      afterImage: AfterKitchenStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeKitchenStaging2,
      afterImage: AfterKitchenStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeKitchenStaging3,
      afterImage: AfterKitchenStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Render from Floor Plan</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Transform your floor plans into realistic room visuals
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Quick:</strong> Results in 1 minute
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simple:</strong> Just a few clicks needed
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Professional:</strong> High-quality renders
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Flexible:</strong> Any style you want
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Try Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 for 100 renders
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Before floor plan render"
                  altAfter="After floor plan render"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Kitchen
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Bedroom
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Living room
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Bathroom
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          TRUSTED BY OVER 20,000 PROFESSIONALS
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Transform floor plans into realistic visuals{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatically
          </span>{" "}
          in minutes.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Automatic rendering</h3>
                <div className="feature-text">
                  Generate visuals from floor plans in a few clicks
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Multiple styles</h3>
                <div className="feature-text">
                  Choose from various interior design styles
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Quick results</h3>
                <div className="feature-text">
                  Get your renders in just 1 minute
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">AI editing tools</h3>
                <div className="feature-text">
                  Further enhance your renders with our AI tools
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Any format</h3>
                <div className="feature-text">
                  Download your images in your preferred format
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Stunning quality</h3>
                <div className="feature-text">
                  Professional-grade imagery from your floor plans
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Examples of our floor plan renders.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Before floor plan render"
            altAfter="After floor plan render"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          How does floor plan rendering work?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 1
            </h5>
            <h2>Upload your floor plan</h2>
            <div className="value-prop-text">
              Upload your floor plan to Pedra's platform.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="How to upload floor plans for automatic rendering in Pedra – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 2
            </h5>
            <h2>Choose your rendering options</h2>
            <div className="value-prop-text">
              Select your preferred style and customization options.
              <p>Pick the number of images you want and the style.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="How to choose options for floor plan rendering – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 3
            </h5>
            <h2>Get your room renders</h2>
            <div className="value-prop-text">
              Receive photorealistic renders of each room in your floor plan.
              <p>
                Download and use your renders immediately or continue editing
                them.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="How to download floor plan renders – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Professional room renders for €29 per month. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            No commitment required.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                per month
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">
              Includes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#F8792A" }}>
                100 image generations
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>per month</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Render images from floor plans
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Empty rooms
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Furnish
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remove objects
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Increase resolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Change walls and floors
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Improve photography
            </div>

            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancel anytime.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Additional renders available at €0.20 each if needed.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions about floor plan rendering
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">What is floor plan rendering?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Floor plan rendering is an AI-powered service that transforms 2D
                floor plans into photorealistic room visualizations. Our
                technology allows you to see how each room in your floor plan
                would look in real life, complete with furniture, materials, and
                lighting.
                <br />
                <br />
                The system automatically handles all aspects of visualization to
                produce professional results in just minutes.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What are the benefits of automatic floor plan rendering?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Automatic floor plan rendering offers numerous advantages:
                <br />
                <br />
                1. Speed: Generate renders in just 1 minute
                <br />
                2. Cost-effective: No need for expensive 3D modeling
                <br />
                3. Easy to use: Simple upload and customize process
                <br />
                4. Multiple styles: Test different designs quickly
                <br />
                5. Professional quality: High-resolution output
                <br />
                6. Flexibility: Edit and update renders easily
                <br />
                7. Complete solution: Handles every room in your plan
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                What can I do with Pedra's floor plan renders?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Pedra's floor plan rendering system offers extensive
                capabilities:
                <br />
                <br />
                1. Generate renders: Create photorealistic visualizations from
                any floor plan
                <br />
                2. Style selection: Choose from various interior design styles
                <br />
                3. Room customization: Modify materials, furniture, and lighting
                <br />
                4. Format options: Download in your preferred file format
                <br />
                5. AI editing: Enhance renders with additional tools
                <br />
                6. Batch processing: Handle multiple rooms efficiently
                <br />
                <br />
                All transformations are handled automatically by our AI,
                delivering professional results in minutes.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderFromFloorPlan;
