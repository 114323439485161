// HomeEs.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";
import GoogleIcon from "./Icons/GoogleIcon.jsx";

// Images

import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import PepeGomez from "./Images/Pepe Gomez image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomeStep1 from "./Images/HomeStep1.png";
import HomeStep2 from "./Images/HomeStep2.png";
import HomeStep3 from "./Images/HomeStep3.png";

// Icons
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import ArrowIconOrange from "./Icons/ArrowIconOrange.jsx";

// Update the animation styles
const progressBarStyle = `
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fade-transition {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-transition.fade-out {
    opacity: 0;
  }
`;

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function SoftwareHomeStagingVirtualFr(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);
  const [FAQ7, setFAQ7] = useState(false);
  const [FAQ8, setFAQ8] = useState(false);
  const [FAQ9, setFAQ9] = useState(false);

  const examples = [
    {
      name: "Furnish",
      beforeImage: BeforeFurnish,
      afterImage: AfterFurnish,
      label: "Amueblar",
    },
    {
      name: "Empty",
      beforeImage: BeforeEmpty,
      afterImage: AfterEmpty,
      label: "Vaciar",
    },
    {
      name: "Renovate",
      beforeImage: BeforeRenovate,
      afterImage: AfterRenovate,
      label: "Renovar",
    },
    {
      name: "Enhance",
      beforeImage: BeforeEnhance,
      afterImage: AfterEnhance,
      label: "Embellecer",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Add styling for header
    const header = document.querySelector("header");
    if (header) {
      // Reset background color first
      header.style.backgroundColor = "#fff";

      // Update based on current path
      if (window.location.pathname.startsWith("/es")) {
        header.style.backgroundColor = "#f7f7f7";
      }
    }

    // Preload all example images
    examples.forEach((example) => {
      const beforeImg = new Image();
      const afterImg = new Image();
      beforeImg.src = example.beforeImage;
      afterImg.src = example.afterImage;
    });

    // Cleanup function to reset header style when component unmounts
    return () => {
      if (header) {
        header.style.backgroundColor = "#fff";
      }
    };
    // eslint-disable-next-line
  }, []); // Re-run when pathname changes

  // Add loading priority to first images
  const [example, setExample] = useState(examples[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progressKey, setProgressKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Start the interval when component mounts
    const newIntervalId = setInterval(() => {
      handleNextImage();
    }, 4000);
    setIntervalId(newIntervalId);

    // Cleanup interval on component unmount
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array means this only runs on mount/unmount

  const handleNextImage = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setExample((currentExample) => {
        const currentIndex = examples.findIndex(
          (ex) => ex.name === currentExample.name
        );
        const nextIndex = (currentIndex + 1) % examples.length;
        return examples[nextIndex];
      });
      setIsTransitioning(false);
      setProgressKey((prev) => prev + 1);
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Pedra",
              "url": "https://pedra.so/fr",
              "sameAs": "https://maps.app.goo.gl/uYEqAdxAUWjnucKk9",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "50"
              }
            }
          `}
        </script>
      </Helmet>
      <style>{progressBarStyle}</style>

      {/* NEW HERO */}
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px 20px 20px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <h1 className="new-hero-header">Logiciel de home staging virtuel</h1>

        <h2 className="new-hero-paragraph">
          Le meilleur logiciel de home staging virtuel pour les agents
          immobiliers et les agences immobilières
        </h2>

        <button
          className="button-top-of-page"
          onClick={redirectToPage}
          style={{
            margin: "0 auto 16px",
            padding: "12px 32px",
            fontSize: "20px",
          }}
        >
          Essayer gratuitement
          <ArrowIcon />
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginBottom: "12px",
          }}
        >
          <div className="new-hero-reviews-container">
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <GoogleIcon />
              <span
                style={{ color: "#333", fontWeight: "400", fontSize: "16px" }}
              >
                5.0
              </span>
              <div style={{ color: "#FFD700", letterSpacing: "2px" }}>
                ★★★★★
              </div>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <img
                className="testimonials-on-header-image"
                src={VictorLaviosa}
                alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={Soma}
                alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={DanieldelaMata}
                alt="Daniel de la Mata, PDG d'Actívox"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={MariaJoseGilart}
                alt="Maria José Gilart, PDG de Gilart Consultors"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Emanuela Cosma"
              ></img>
            </div>
            <span style={{ color: "#333" }}>
              {" "}
              Plus de 20 000 clients nous font confiance
            </span>
          </div>
        </div>
      </div>
      <div
        className="section-one-column-left"
        style={{ margin: "20px 24px", padding: "20px 24px" }}
      >
        <div className="customer-logo-container" style={{ color: "#878787" }}>
          Ces agences immobilières font déjà confiance à Pedra :
          <CustomerLogos />
        </div>
      </div>

      <div
        style={{
          background: "linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2
              className="title-container"
              style={{
                position: "relative",
                display: "inline",
                background: "linear-gradient(90deg, #FF6B33 0%, #FF8A33 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
              }}
            >
              Fonctionnalités de notre logiciel de home staging virtuel
            </h2>
            <div className="title-secondary">
              Téléchargez vos images sur Pedra et utilisez nos outils de home
              staging virtuel alimentés par l'IA pour obtenir des images et des
              vidéos qui accélèrent vos ventes.
            </div>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="section-one-column-left" style={{ width: "100%" }}>
            <div
              className="feature-container-two-features"
              style={{ width: "100%" }}
            >
              {/* Row 1 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/help/comment-renover-des-maisons-en-virtuel"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Rénovation
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Rénovation personnalisée
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/home-staging-virtual"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Meubler
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>
              </div>

              {/* Row 2 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/help/comment-vider-pieces-virtuellement"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Vider les pièces
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/help/comment-supprimer-un-objet"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Supprimer un objet
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Ajouter un objet
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 3 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/help/comment-ajouter-des-objets-3d"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Ajouter un objet 3D
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/real-estate-photography"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Ciel bleu
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/real-estate-photography"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Embellir l'image
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>
              </div>

              {/* Row 4 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/real-estate-photography"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Convertir en HD
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/fr/render-depuis-plan"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Rendu depuis plan
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Vidéo virtuelle
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 5 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={
                        "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel"
                      }
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Effet rideau
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Supprimer le filigrane
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Changer le sol
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 6 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Corriger la perspective
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Ajouter un filigrane
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Légende photo
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Avec Pedra, j'ai réussi à vendre un bien de 500 000 € en moins
              d'une journée. L'appartement que je devais vendre nécessitait des
              rénovations, et avec Pedra, j'ai pu créer des rendus qui ont
              permis de visualiser le potentiel des espaces après rénovation,
              mais sans les travaux !"
            </h2>
            <div className="quote-author-block">
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Photo d'Emanuela Cosma"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Emanuela Cosma</div>
                <div className="quote-author-company">Agent Immobilier</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              Comment ça marche ?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Étape 1 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Téléchargez les images
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Téléchargez une ou plusieurs images du bien.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Étape 2 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transformez
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Cliquez sur "Éditer avec l'IA" pour transformer les images.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Étape 3 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Attendez et téléchargez
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Téléchargez vos images éditées avec l'IA.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Je devais vendre un appartement à rénover, et j'ai utilisé Pedra
              pour créer des rendus en quelques secondes. Les résultats étaient
              incroyables ! Cela m'a aidé à le vendre beaucoup plus rapidement,
              maintenant je le recommande à tout le monde."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="Logo EXP pour Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="Photo de José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Agent Immobilier</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Pour 29€ par mois. <br />{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
              }}
            >
              Sans engagement.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Plan Pro
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">29€</div>
                <div className="modal-product-plan-description-text-3">
                  par mois
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Inclus
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#F8792A" }}>
                  100 générations d'images
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>par mois</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Rénover et redécorer
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Vider les pièces
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Meubler
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Supprimer des objets
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Augmenter la résolution
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Changer les sols
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Améliorer la photographie
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <VideoPricingIcon /> Générer des vidéos virtuelles
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Plans illimités
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Partage de projets illimité
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Ajouter des filigranes
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Support illimité
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Essayer Pedra
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Annulez quand vous voulez.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Achetez plus d'images à 0,20€ par image si vous en avez besoin.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Essayer Pedra <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Fondateur de Pedra"
              />
              <div>
                Des questions ? Envoyez-nous un email à{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                ou planifiez une démo de 15 minutes avec notre fondateur Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  ici
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoftwareHomeStagingVirtualFr;
