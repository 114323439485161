import React from "react";
import { Link } from "react-router-dom";

function HelpFr() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Bienvenue au Centre d'Aide Pedra
            </h1>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Apprenez à transformer vos biens immobiliers avec nos outils de
                home staging virtuel.
              </div>
              <div style={{ marginTop: "10px" }}>
                Que vous découvriez{" "}
                <Link className="article-link" to="/fr">
                  Pedra
                </Link>{" "}
                ou souhaitiez maîtriser nos fonctionnalités, nous avons des
                guides complets pour vous aider à réussir.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Premiers pas avec le{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  Home Staging Virtuel
                </span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Nos outils de home staging virtuel vous permettent de
                transformer des espaces vides en pièces meublées, de créer des
                aperçus de rénovation et d'améliorer les photos immobilières -
                le tout sans staging physique.
              </div>
              <div style={{ marginTop: "10px" }}>
                Commencez avec ces tutoriels essentiels :
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-home-staging-virtuel"
                    >
                      Comment créer un Home Staging Virtuel
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-vider-pieces-virtuellement"
                    >
                      Comment vider des pièces virtuellement
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-meubler-en-virtuel"
                    >
                      Comment meubler en virtuel
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-ajouter-des-objets-3d"
                    >
                      Comment ajouter des objets 3D
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-supprimer-un-objet"
                    >
                      Comment supprimer des objets
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Tutoriels par{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  type de pièce
                </span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Apprenez à réaliser du staging et créer des rendus pour
                différents types de pièces :
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Guides de Home Staging Virtuel :
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-home-staging-virtuel-salon"
                    >
                      Guide de Home Staging pour Salon
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-home-staging-virtuel-chambre"
                    >
                      Guide de Home Staging pour Chambre
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-home-staging-virtuel-cuisine"
                    >
                      Guide de Home Staging pour Cuisine
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-home-staging-virtuel-salle-de-bain"
                    >
                      Guide de Home Staging pour Salle de Bain
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-home-staging-virtuel-terrasse"
                    >
                      Guide de Home Staging pour Terrasse
                    </Link>
                  </li>
                </ul>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Guides de Rendus :
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-rendu-salon"
                    >
                      Guide de Rendus pour Salon
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-rendu-chambre"
                    >
                      Guide de Rendus pour Chambre
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-rendu-cuisine"
                    >
                      Guide de Rendus pour Cuisine
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-rendu-salle-de-bain"
                    >
                      Guide de Rendus pour Salle de Bain
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-rendu-terrasse"
                    >
                      Guide de Rendus pour Terrasse
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Fonctionnalités{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Avancées</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Passez au niveau supérieur avec nos outils avancés :
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-creer-rendu-exterieur-maison"
                    >
                      Créer des Rendus d'Extérieurs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-dessiner-plan"
                    >
                      Dessiner des Plans
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/comment-renover-des-maisons-en-virtuel"
                    >
                      Guide de Rénovation Virtuelle
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/fr/help/home-staging-virtuel-chantiers"
                    >
                      Home Staging Virtuel pour Chantiers
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Besoin{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>d'Aide ?</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Vous ne trouvez pas ce que vous cherchez ? Notre équipe de
                support est là pour vous aider. Contactez-nous à felix@pedra.so.
              </div>
              <br />
              <br />
              <div style={{ marginTop: "10px" }}>
                Prêt à commencer à transformer vos annonces immobilières ?
              </div>
              <div style={{ marginTop: "10px" }}>
                Choisissez n'importe quel tutoriel ci-dessus pour commencer, ou
                visitez notre{" "}
                <Link className="article-link" to="/fr/pricing">
                  page de tarifs
                </Link>{" "}
                pour en savoir plus sur nos forfaits.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpFr;
