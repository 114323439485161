import React from "react";
import Article1Frame from "./Images/Article1Frame.png";
import Article4Frame from "./Images/Article4Frame.png";
import Article5Frame from "./Images/Article5Frame.jpeg";
import Article6Frame from "./Images/Article6Frame.jpeg";
import Article7Frame from "./Images/Article7Frame.jpg";
import Article8Frame from "./Images/Article8Frame.png";
import Article9Frame from "./Images/Article9Frame.png";
import Article10Frame from "./Images/Article10Frame.png";
import Article11Frame from "./Images/Article11Frame.png";
import Article12Frame from "./Images/Article12Frame.png";
import Article13Frame from "./Images/Article13Frame.png";
import Article14Frame from "./Images/Article14Frame.png";
import Article15Frame from "./Images/Article15Frame.png";
import Article16Frame from "./Images/Article16Frame.png";
import Article17Frame from "./Images/Article17Frame.png";
import Article18Frame from "./Images/Article18Frame.png";
import Article19Frame from "./Images/Article19Frame.png";
import Article20Frame from "./Images/Article20Frame.png";
import Article21Frame from "./Images/Article21Frame.png";
import Article22Frame from "./Images/Article22Frame.png";
import Article23Frame from "./Images/Article23Frame.png";
import Article24Frame from "./Images/Article24Frame.png";
import Article25Frame from "./Images/Article25Frame.png";
import Article26Frame from "./Images/Article26Frame.png";
import Article27Frame from "./Images/Article27Frame.png";
import Article28Frame from "./Images/Article28Frame.png";
import Article29Frame from "./Images/Article29Frame.png";
import Article30Frame from "./Images/Article30Frame.png";
import Article31Frame from "./Images/Article31Frame.png";
import Article32Frame from "./Images/Article32Frame.png";
import { Link } from "react-router-dom";

function BlogPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Blog</h1>
          <h2 className="title-secondary">
            Bem-vindo ao blog da Pedra <br />
            Neste blog você aprenderá como usar IA para ajudá-lo no mundo
            imobiliário.
          </h2>
        </div>
      </div>

      <br />
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>24 de Dezembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/pt/blog/otimizacao-de-anuncios-imobiliarios"
              >
                Otimização de Anúncios Imobiliários: Estratégias Chave para o
                Sucesso em 2025
              </Link>
            </h2>
            <div className="value-prop-text">
              Como otimizar seus anúncios imobiliários para o sucesso em 2025
            </div>
          </div>
          <div className="picture">
            <img
              src={Article32Frame}
              alt="Dicas essenciais de fotografia imobiliária – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>17 de Dezembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/pt/blog/dicas-de-fotografia-imobiliaria"
              >
                Sucesso garantido: Dicas essenciais de fotografia imobiliária
                para anúncios impressionantes
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra dicas essenciais de fotografia imobiliária para criar
              anúncios impressionantes. Aprimore suas habilidades hoje!
            </div>
          </div>
          <div className="picture">
            <img
              src={Article31Frame}
              alt="Dicas essenciais de fotografia imobiliária – Ilustração decorativa"
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>10 de Dezembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/roi-virtual-staging"
              >
                ROI do Home Staging Virtual: Números Reais das Principais
                Imobiliárias
              </Link>
            </h2>
            <div className="value-prop-text">
              Aprenda sobre o ROI do home staging virtual no mercado imobiliário
              com dados concretos e insights das principais agências em
              diferentes segmentos de mercado.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article30Frame}
              alt="ROI do Home Staging Virtual: Números Reais das Principais Imobiliárias – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>2 de Dezembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/traditional-home-staging-vs-virtual-home-staging"
              >
                Home Staging Tradicional vs Virtual: Qual é Melhor para Vender
                Seu Imóvel?
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra as principais diferenças entre home staging tradicional e
              virtual, e aprenda qual opção é mais adequada para seu imóvel.
              Compare custos, benefícios e eficácia para diferentes tipos de
              imóveis.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article29Frame}
              alt="Home Staging Tradicional vs Virtual – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 de Novembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-sell-a-house-to-renovate"
              >
                Como Vender uma Casa para Reformar: Guia Completo
              </Link>
            </h2>
            <div className="value-prop-text">
              Aprenda estratégias eficazes para vender uma casa que precisa de
              reforma. Dicas sobre como destacar o potencial, definir o preço
              certo e atrair compradores interessados em projetos de reforma.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article28Frame}
              alt="Como vender uma casa para reformar – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>25 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/buildable-land">
                Terreno Edificável: O Que É e Tipos de Terreno
              </Link>
            </h2>
            <div className="value-prop-text">
              Explicamos o que é um terreno edificável, os diferentes tipos de
              terrenos edificáveis disponíveis e como prepará-los para venda.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article27Frame}
              alt="Terreno edificável – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>23 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-trust"
              >
                O que é um trust imobiliário e quais tipos existem?
              </Link>
            </h2>
            <div className="value-prop-text">
              Um trust imobiliário é um contrato legal entre um fideicomitente
              que transfere a propriedade de certos ativos para um fiduciário
              que é responsável por gerenciá-los.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article26Frame}
              alt="Trust imobiliário – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>18 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-phrases"
              >
                Frases Imobiliárias, Exemplos e Dicas para Atrair Compradores
              </Link>
            </h2>
            <div className="value-prop-text">
              Frases imobiliárias para atrair clientes, destacar e descrever
              propriedades. Ensinamos você a escrever suas frases e fornecemos
              exemplos das melhores frases imobiliárias.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article25Frame}
              alt="Frases imobiliárias – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/real-estate">
                Mercado Imobiliário: Dicas para Comprar, Vender e Investir
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra tudo sobre o mercado imobiliário. De dicas para
              compradores de primeira viagem a estratégias de investimento e
              tendências do mercado.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article24Frame}
              alt="Mercado imobiliário – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>10 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/mistakes-when-selling-house"
              >
                Erros Comuns ao Vender uma Casa e Como Evitá-los
              </Link>
            </h2>
            <div className="value-prop-text">
              Erros comuns ao vender uma casa e como evitá-los: dicas essenciais
              para alcançar uma venda bem-sucedida sem perder dinheiro ou tempo.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article23Frame}
              alt="Erros comuns ao vender uma casa – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/short-sale">
                Guia de Venda Rápida: O Que É e Seus Objetivos
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra o que é uma venda rápida, seus benefícios e requisitos
              para vendedores e compradores. Exemplos de vendas rápidas no
              mercado imobiliário.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article22Frame}
              alt="Venda rápida – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/environmental-designer"
              >
                Designer Ambiental: Guia de Design Ambiental
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra o papel do designer ambiental, suas principais
              habilidades, processos criativos e tendências atuais em design
              ambiental único.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article21Frame}
              alt="Designer ambiental – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>3 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/modernize-home">
                Como modernizar sua casa com móveis antigos?
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra como você pode modernizar sua casa com os móveis que tem,
              dar uma segunda vida aos seus móveis antigos e modernizar sua casa
              com essas ideias.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article20Frame}
              alt="Modernizar casa – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>3 de Outubro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/blog/flipping">
                Flipping Imobiliário | O que é e benefícios do flipping
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra como fazer flipping imobiliário com sucesso: da pesquisa
              de mercado à venda, otimize cada etapa para maximizar seus lucros.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article19Frame}
              alt="Flipping imobiliário – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>24 de Setembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/property-that-wont-sell"
              >
                Imóvel que não vende
              </Link>
            </h2>
            <div className="value-prop-text">
              Um imóvel que não vende é uma situação complicada. Aqui explicamos
              o que você pode fazer para ajudar a vender um imóvel que não está
              vendendo.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article18Frame}
              alt="Imóvel que não vende – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>20 de Setembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/home-automation"
              >
                Automação Residencial: Seus Benefícios e Como Automatizar Sua
                Casa
              </Link>
            </h2>
            <div className="value-prop-text">
              Automatizar sua casa é um processo muito simples que oferece
              grande conforto no dia a dia. Mostramos como automatizar e quais
              produtos comprar.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article17Frame}
              alt="Automação residencial – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Setembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/sunken-living-rooms"
              >
                Salas rebaixadas: O que são e como projetá-las?
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubra o charme das salas rebaixadas, espaços arquitetônicos
              únicos que combinam design moderno com conforto, criando ambientes
              aconchegantes e elegantes.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article16Frame}
              alt="Salas rebaixadas – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>13 de Setembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-personalize-room"
              >
                Como Personalizar Meu Quarto? Passos e Dicas
              </Link>
            </h2>
            <div className="value-prop-text">
              Mostramos como personalizar seu quarto, os passos a seguir e dicas
              para customizar seu espaço.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article15Frame}
              alt="Como personalizar quarto – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>10 de Setembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-mentor"
              >
                O que é um Mentor Imobiliário e Como Escolher Um
              </Link>
            </h2>
            <div className="value-prop-text">
              Mentores imobiliários são especialistas no setor e fornecem
              conselhos para pessoas interessadas no mercado imobiliário.
              Orientamos você sobre como escolher seu mentor.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article14Frame}
              alt="Mentor imobiliário – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>5 de Setembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-pricing"
              >
                Precificação Imobiliária: Como Criar e Quais Tipos Existem?
              </Link>
            </h2>
            <div className="value-prop-text">
              A precificação imobiliária envolve definir o preço ideal para seu
              imóvel e maximizar seu valor. Ensinamos diferentes métodos de
              precificação imobiliária.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article13Frame}
              alt="Precificação imobiliária – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>3 de Setembro, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-advertising"
              >
                Publicidade Imobiliária: Como Criar e Quais Tipos Existem?
              </Link>
            </h2>
            <div className="value-prop-text">
              Crie publicidade imobiliária facilmente usando diferentes táticas
              e tipos de publicidade com exemplos de publicidade criativa. 🏡
            </div>
          </div>
          <div className="picture">
            <img
              src={Article12Frame}
              alt="Publicidade imobiliária – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>30 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-market-trends"
              >
                Tendências Atuais do Mercado Imobiliário
              </Link>
            </h2>
            <div className="value-prop-text">
              Exploramos como o mercado imobiliário evoluiu e quais são as
              tendências atuais no mercado imobiliário digital.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article11Frame}
              alt="Tendências do mercado imobiliário – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>28 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/real-estate-marketing"
              >
                Marketing Imobiliário: O Que É e Como Aplicar
              </Link>
            </h2>
            <div className="value-prop-text">
              Marketing imobiliário é um conjunto de estratégias e técnicas
              projetadas para vender imóveis. Descubra os tipos e como
              aplicá-los.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article10Frame}
              alt="Marketing imobiliário – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>23 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/increase-home-value"
              >
                Aumente o Valor da Sua Casa Rápida e Facilmente
              </Link>
            </h2>
            <div className="value-prop-text">
              Mostramos como aumentar o valor da sua casa de forma rápida e
              fácil com várias dicas e conselhos para melhorar sua casa antes de
              vendê-la &#127969;
            </div>
          </div>
          <div className="picture">
            <img
              src={Article9Frame}
              alt="Aumentar valor da casa – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>21 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/multifunctional-spaces"
              >
                Espaços Multifuncionais: O Que São e Como Otimizar o Espaço?
              </Link>
            </h2>
            <div className="value-prop-text">
              Espaços multifuncionais visam maximizar o uso do espaço disponível
              utilizando a mesma área para diferentes funções.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article8Frame}
              alt="Espaços multifuncionais – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/home-staging-examples"
              >
                Exemplos de Home Staging: Antes e Depois
              </Link>
            </h2>
            <div className="value-prop-text">
              Está procurando exemplos de home staging antes e depois? Para te
              dar uma ideia do poder desta técnica, aqui estão alguns exemplos
              de home staging para você.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article7Frame}
              alt="Exemplos de Home Staging – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Julho, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-make-sky-blue-real-estate-photo"
              >
                Como Deixar o Céu Azul nas Suas Fotos de Imóveis
              </Link>
            </h2>
            <div className="value-prop-text">
              Já se perguntou como deixar o céu azul em todas as suas fotos de
              imóveis? Aqui você descobrirá como.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article6Frame}
              alt="Como deixar o céu azul nas fotos – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 de Julho, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
              >
                Como Garantir que a Foto de Capa do Seu Anúncio Imobiliário
                Acelere a Venda
              </Link>
            </h2>
            <div className="value-prop-text">
              Escolha e gere fotos de capa em seus anúncios imobiliários que
              chamem a atenção e ajudem você a vender
            </div>
          </div>
          <div className="picture">
            <img
              src={Article5Frame}
              alt="Foto de capa para anúncio imobiliário – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Junho, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/sell-more-properties-with-ai"
              >
                Como a IA Pode Ajudar Corretores de Imóveis a Vender?
              </Link>
            </h2>
            <div className="value-prop-text">
              Uma explicação sobre o que é IA e como ela pode ajudar na venda de
              imóveis
            </div>
          </div>
          <div className="picture">
            <img
              src={Article4Frame}
              alt="Como a IA pode ajudar corretores – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 de Abril, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-sell-properties-with-ai"
              >
                Como usar IA para vender imóveis
              </Link>
            </h2>
            <div className="value-prop-text">
              Neste artigo explicaremos como usar IA para ajudar você a vender
              imóveis
            </div>
          </div>
          <div className="picture">
            <img
              src={Article1Frame}
              alt="Como usar IA para vender imóveis – Ilustração decorativa"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogPt;
