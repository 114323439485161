import React from "react";

function ApiDocs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Pedra.so API Documentation</h1>
          <h2 className="title-secondary gray">
            API to create virtual home staging images
          </h2>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <p>
              Welcome to the official documentation for the Pedra.so API. Our
              API provides powerful image processing capabilities for various
              room enhancement and design tasks.
            </p>
            <br />
            <h2 className="article-subtitleh2">Base URLs</h2>
            <p>The API can be accessed through the following base URL:</p>
            <ul>
              <li>
                Production: <code>https://app.pedra.so/api</code>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Authentication</h2>
            <p>
              All API calls require an <code>apiKey</code> parameter for
              authentication. Send an email to felix@pedra.so to request your
              API key.
            </p>
            <br />
            <h2 className="article-subtitleh2">Common Parameters</h2>
            <p>The following parameters are common to all endpoints:</p>
            <ul>
              <li>
                <code>apiKey</code> (string, required): Your unique API key for
                authentication.
              </li>
              <li>
                <code>imageUrl</code> (string, required): URL of the image to be
                processed.
              </li>
              <li>
                <code>creativity</code> (string, required): Level of creative
                freedom for the AI.
                <ul>
                  <li>
                    <strong>Low</strong>: Preserves the original architecture
                    with minimal variations.
                  </li>
                  <li>
                    <strong>Medium</strong>: Maintains the original architecture
                    with noticeable but balanced variations.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Endpoints</h2>

            <h3 className="article-subtitle">Run Enhance</h3>
            <p>
              Enhances the quality, color, light and resolution of the provided
              image.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/enhance</code>
            </p>
            <p>
              <strong>Method:</strong> POST
            </p>
            <p>
              <strong>Parameters:</strong> Common parameters (see above)
            </p>
            <p>
              <strong>Expected response time:</strong> Between 25 seconds and 1
              minute
            </p>

            <br />
            <h3 className="article-subtitle">Run Empty Room</h3>
            <p>
              Removes furniture and clutter from the room in the provided image.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/empty_room</code>
            </p>
            <p>
              <strong>Method:</strong> POST
            </p>
            <p>
              <strong>Parameters:</strong> Common parameters (see above)
            </p>
            <p>
              <strong>Expected response time:</strong> Between 2 and 3 minutes
            </p>
            <br />
            <h3 className="article-subtitle">Run Furnish</h3>
            <p>
              Furnishes (creates a virtual staging) of a space according to
              specified parameters.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/furnish</code>
            </p>
            <p>
              <strong>Method:</strong> POST
            </p>
            <p>
              <strong>Parameters:</strong>
            </p>
            <ul>
              <li>Common parameters (see above)</li>
              <li>
                <code>roomType</code> (string, required): Type of room to
                furnish.
              </li>
              <li>
                <code>style</code> (string, required): Design style to apply.
              </li>
            </ul>
            <p>
              <strong>Expected response time:</strong> Between 2 and 3 minutes
            </p>

            <h4>
              Possible values for <code>roomType</code>:
            </h4>
            <ul>
              <li>"Bedroom"</li>
              <li>"Living room"</li>
              <li>"Kitchen"</li>
              <li>"Office"</li>
              <li>"Terrace"</li>
              <li>"Dining room"</li>
              <li>"Kitchen + Living room"</li>
              <li>"Dining + Living room"</li>
              <li>"Entrance"</li>
            </ul>

            <h4>
              Possible values for <code>style</code>:
            </h4>
            <ul>
              <li>"Traditional"</li>
              <li>"Minimalist"</li>
              <li>"Scandinavian"</li>
              <li>"Mid-century"</li>
              <li>"Bohemian"</li>
              <li>"Industrial"</li>
              <li>"Mediterranean"</li>
              <li>"Modern"</li>
              <li>"Pyrenees"</li>
            </ul>
            <br />
            <h3 className="article-subtitle">Run Renovation</h3>
            <p>Renovates a room according to the specified style.</p>
            <p>
              <strong>Endpoint:</strong> <code>/renovation</code>
            </p>
            <p>
              <strong>Method:</strong> POST
            </p>
            <p>
              <strong>Parameters:</strong>
            </p>
            <ul>
              <li>Common parameters (see above)</li>
              <li>
                <code>style</code> (string, required): Design style to apply for
                renovation.
              </li>
              <li>
                <code>preserveWindows</code> (boolean, required): Whether to
                preserve existing windows during renovation.
              </li>
            </ul>
            <p>
              <strong>Expected response time:</strong> Between 25 seconds and 2
              minutes
            </p>
            <br />
            <h2 className="article-subtitleh2">Response Format</h2>
            <p>
              All API endpoints return JSON responses. A successful response
              will have a status code of 200 and include the processed image
              information.
            </p>
            <br />
            <h2 className="article-subtitleh2">Error Handling</h2>
            <p>
              In case of an error, the API will return an appropriate HTTP
              status code along with a JSON response containing error details.
            </p>
            <br />
            <h2 className="article-subtitleh2">Support</h2>
            <p>
              For any questions or issues regarding the API, please contact our
              support team at felix@pedra.so.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApiDocs;
