import React from "react";
import { Link } from "react-router-dom";

function HelpDe() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Willkommen im Pedra Hilfezentrum
            </h1>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Erfahren Sie, wie Sie Ihre Immobilien mit unseren virtuellen
                Staging-Tools transformieren können.
              </div>
              <div style={{ marginTop: "10px" }}>
                Egal ob Sie neu bei{" "}
                <Link className="article-link" to="/">
                  Pedra
                </Link>{" "}
                sind oder unsere Funktionen beherrschen möchten, wir haben
                umfassende Anleitungen, die Ihnen zum Erfolg verhelfen.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Erste Schritte mit{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  Virtual Staging
                </span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Mit unseren virtuellen Staging-Tools können Sie leere Räume in
                schön eingerichtete Zimmer verwandeln, Renovierungsvorschauen
                erstellen und Immobilienfotos aufwerten - alles ohne physisches
                Staging.
              </div>
              <div style={{ marginTop: "10px" }}>
                Beginnen Sie mit diesen grundlegenden Tutorials:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-virtuelles-home-staging-erstellt"
                    >
                      Wie man virtuelles Home Staging erstellt
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-raume-virtuell-leert"
                    >
                      Wie man Räume virtuell leert
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-virtuell-einrichtet"
                    >
                      Wie man virtuell einrichtet
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-3d-objekte-hinzufugt"
                    >
                      Wie man 3D-Objekte hinzufügt
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-objekte-entfernt"
                    >
                      Wie man Objekte entfernt
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Raum-spezifische{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Tutorials</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Lernen Sie, wie Sie Staging und Renderings für verschiedene
                Raumtypen erstellen:
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Virtual Staging Anleitungen:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt"
                    >
                      Wohnzimmer Staging Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt"
                    >
                      Schlafzimmer Staging Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt"
                    >
                      Küchen Staging Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt"
                    >
                      Badezimmer Staging Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt"
                    >
                      Terrassen Staging Anleitung
                    </Link>
                  </li>
                </ul>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Rendering Anleitungen:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-wohnzimmer-rendering-erstellt"
                    >
                      Wohnzimmer Rendering Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-schlafzimmer-rendering-erstellt"
                    >
                      Schlafzimmer Rendering Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-kuchen-rendering-erstellt"
                    >
                      Küchen Rendering Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-badezimmer-rendering-erstellt"
                    >
                      Badezimmer Rendering Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-terrassen-rendering-erstellt"
                    >
                      Terrassen Rendering Anleitung
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Erweiterte{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Funktionen</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Bringen Sie Ihr Staging mit unseren erweiterten Tools auf die
                nächste Stufe:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt"
                    >
                      Außenansicht Renderings erstellen
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-grundriss-zeichnet"
                    >
                      Grundrisse zeichnen
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/wie-man-hauser-virtuell-renoviert"
                    >
                      Virtuelle Renovierungs-Anleitung
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/de/hilfe/virtuelles-home-staging-baustellen"
                    >
                      Virtuelles Home Staging für Baustellen
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Brauchen Sie{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Hilfe?</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Sie finden nicht, wonach Sie suchen? Unser Support-Team hilft
                Ihnen gerne weiter. Kontaktieren Sie uns unter felix@pedra.so.
              </div>
              <br />
              <br />
              <div style={{ marginTop: "10px" }}>
                Bereit, Ihre Immobilienangebote zu transformieren?
              </div>
              <div style={{ marginTop: "10px" }}>
                Wählen Sie eines der obigen Tutorials, um zu beginnen, oder
                besuchen Sie unsere{" "}
                <Link className="article-link" to="/pricing">
                  Preisseite
                </Link>{" "}
                um mehr über unsere Pläne zu erfahren.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpDe;
