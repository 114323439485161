import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images (you'll need to update these with your bedroom images)
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeBedroom from "./Images/curtain-effect-hero/BeforeBedroom.png";
import AfterBedroom from "./Images/curtain-effect-hero/AfterBedroom.png";
import BeforeBedroomStaging1 from "./Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "./Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "./Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "./Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "./Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "./Images/curtain-effect/AfterBedroomStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderBedroomPt() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeBedroom,
    afterImage: AfterBedroom,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeBedroom,
        afterImage: AfterBedroom,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeBedroomStaging1,
        afterImage: AfterBedroomStaging1,
      });
    } else if (example === "Cozy") {
      setExample({
        name: "Cozy",
        beforeImage: BeforeBedroomStaging2,
        afterImage: AfterBedroomStaging2,
      });
    } else {
      setExample({
        name: "Minimalist",
        beforeImage: BeforeBedroomStaging3,
        afterImage: AfterBedroomStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "O que são renders automáticos de quarto?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Renders automáticos de quarto são visualizações alimentadas por IA que transformam fotos de quartos em imagens renderizadas profissionalmente. Esta tecnologia permite criar visualizações fotorrealistas de quartos com diferentes estilos, móveis e layouts sem necessidade de modelagem 3D manual ou software complexo.",
        },
      },
      {
        "@type": "Question",
        name: "Quais são os benefícios dos renders automáticos de quarto?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Os renders automáticos de quarto oferecem vários benefícios, incluindo: transformação instantânea de espaços de quarto, a capacidade de visualizar várias opções de design rapidamente, resultados fotorrealistas de alta qualidade, economia significativa de tempo e custos em comparação com a renderização tradicional, e a flexibilidade para experimentar diferentes estilos e configurações.",
        },
      },
      {
        "@type": "Question",
        name: "Como funciona o processo de renderização automática de quarto?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "O processo envolve carregar fotos do quarto atual para nossa plataforma, selecionar as transformações desejadas (como alterar acabamentos, adicionar móveis ou modificar layouts), e receber renders fotorrealistas mostrando o quarto transformado em minutos. Nossa tecnologia de IA lida com todo o trabalho de renderização complexo automaticamente.",
        },
      },
      {
        "@type": "Question",
        name: "Quanto custam os renders automáticos de quarto?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Nosso serviço de renderização automática de quarto está disponível por €29 mais IVA por mês. Este plano permite gerar até 100 renders por mês, sem compromisso de longo prazo necessário.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeBedroomStaging1,
    afterImage: AfterBedroomStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeBedroomStaging1,
      afterImage: AfterBedroomStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeBedroomStaging2,
      afterImage: AfterBedroomStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeBedroomStaging3,
      afterImage: AfterBedroomStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Renderização de Quartos</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Crie renderizações fotorrealistas de quartos automaticamente
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantâneo:</strong> Gere renderizações em segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simples:</strong> Sem necessidade de conhecimentos
                    técnicos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realista:</strong> Resultados fotorrealistas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Suporte:</strong> Assistência especializada
                    disponível
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Experimente o Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por 100 renderizações
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Mais de 100 depoimentos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Before bedroom render"
                  altAfter="After bedroom render"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Quarto Moderno
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Estilo Tradicional
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Cozy" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Cozy")}
                    >
                      Design Aconchegante
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Minimalist" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Minimalist")}
                    >
                      Aparência Minimalista
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          CONFIADO POR MAIS DE 20.000 PROFISSIONAIS
          <CustomerLogos />
        </div>
      </div>
      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Crie renderizações profissionais de quartos{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automaticamente
          </span>{" "}
          em segundos.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Transforme quartos instantaneamente
                </h3>
                <div className="feature-text">
                  Gere renderizações fotorrealistas com um único clique
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Múltiplas opções de design</h3>
                <div className="feature-text">
                  Visualize diferentes estilos e configurações facilmente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perspectivas perfeitas</h3>
                <div className="feature-text">
                  Corrija e otimize ângulos de câmera automaticamente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Iluminação automática</h3>
                <div className="feature-text">
                  Ajustes profissionais de iluminação em cada renderização
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Troque materiais</h3>
                <div className="feature-text">
                  Atualize superfícies e acabamentos automaticamente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Limpeza de cena</h3>
                <div className="feature-text">
                  Remova objetos indesejados automaticamente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemplos de nossas renderizações automáticas de quartos.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Before bedroom render"
            altAfter="After bedroom render"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Como funcionam as renderizações automáticas de quartos?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 1
            </h5>
            <h2>Envie sua foto do quarto</h2>
            <div className="value-prop-text">
              Envie qualquer foto do seu quarto para a plataforma Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="How to upload bedroom photos for automatic rendering in Pedra – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 2
            </h5>
            <h2>Escolha suas opções de renderização</h2>
            <div className="value-prop-text">
              Selecione como você quer transformar seu quarto.
              <p>
                Troque materiais, atualize iluminação, modifique layout,
                adicione móveis, e muito mais.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="How to choose options for automatic bedroom rendering – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 3
            </h5>
            <h2>Receba suas renderizações fotorrealistas</h2>
            <div className="value-prop-text">
              Receba suas imagens de quarto renderizadas profissionalmente em
              segundos.
              <p>Baixe e use suas renderizações imediatamente.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="How to download automatic bedroom renders – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Renderizações profissionais de quartos por €29 por mês. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sem compromisso necessário.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plano Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                por mês
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclui</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#F8792A" }}>
                100 renderizações
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mês</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Transforme quartos instantaneamente
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Troque materiais e acabamentos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Adicione móveis e decoração
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Limpeza automática de cena
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Saída em alta resolução
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Múltiplas opções de layout
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Iluminação profissional
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Projetos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartilhamento ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Marcas d'água personalizadas
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Suporte prioritário
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente o Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancele a qualquer momento.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Renderizações adicionais disponíveis por €0,20 cada, se
              necessário.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Perguntas sobre renderizações automáticas de quartos
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                O que são renderizações automáticas de quartos?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                As renderizações automáticas de quartos usam tecnologia avançada
                de IA para transformar fotos comuns de quartos em visualizações
                profissionais e fotorrealistas. Esta tecnologia permite que você
                crie renderizações de alta qualidade sem a necessidade de
                modelagem 3D manual ou software complexo.
                <br />
                <br />O sistema gerencia automaticamente iluminação, materiais,
                perspectivas e outros aspectos técnicos para produzir resultados
                profissionais instantaneamente.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais são os benefícios das renderizações automáticas de
                quartos?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                As renderizações automáticas de quartos oferecem numerosas
                vantagens:
                <br />
                <br />
                1. Velocidade: Gere renderizações em segundos, não horas ou dias
                <br />
                2. Custo-benefício: Sem necessidade de software 3D caro
                <br />
                3. Fácil de usar: Não requer experiência técnica
                <br />
                4. Múltiplas opções: Teste diferentes estilos rapidamente
                <br />
                5. Resultados fotorrealistas: Saída de qualidade profissional
                <br />
                6. Flexibilidade: Faça alterações instantaneamente
                <br />
                7. Consistência: Resultados confiáveis sempre
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                O que posso fazer com as renderizações de quarto da Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                O sistema de renderização automática de quartos da Pedra oferece
                uma ampla gama de recursos:
                <br />
                <br />
                1. Gere renderizações: Crie visualizações fotorrealistas de
                qualquer foto de quarto instantaneamente.
                <br />
                2. Otimização de iluminação: Aprimore automaticamente a
                iluminação e sombras para resultados profissionais.
                <br />
                3. Troca de materiais: Modifique paredes, pisos, móveis e outras
                superfícies com um clique.
                <br />
                4. Adicione móveis: Insira novas camas, cômodas, decoração e
                acessórios automaticamente.
                <br />
                5. Limpeza de cena: Remova objetos indesejados e desordem
                automaticamente.
                <br />
                6. Visualização de layout: Teste diferentes configurações de
                quarto rapidamente.
                <br />
                <br />
                Todas as transformações são gerenciadas automaticamente por
                nossa IA, entregando resultados profissionais em segundos.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderBedroomPt;
