import React from "react";

const HumanPricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.4375 5.4375C6.4375 3.31562 7.75313 2 9.875 2C11.9969 2 13.3125 3.31562 13.3125 5.4375C13.3125 7.55937 11.9969 8.875 9.875 8.875C7.75313 8.875 6.4375 7.55937 6.4375 5.4375ZM9.875 10.125C5.63438 10.125 3 12.7594 3 17H16.75C16.75 12.7594 14.1156 10.125 9.875 10.125Z"
        fill="#878787"
      />
    </svg>
  );
};

export default HumanPricingIcon;
