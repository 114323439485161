import React from "react";

const FurnishPricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.03262 9.11892C7.60561 9.11215 12.6374 9.11892 12.6374 9.11892L14.5 3H15L13.8815 10.6395L15 17H14.5L12.6374 11H7.03262L6.5 17H6.00229V10.3149C5.96196 9.82682 6.45962 9.12568 7.03262 9.11892Z"
        fill="#F8792A"
        stroke="#F8792A"
        strokeWidth="1.4"
      ></path>
    </svg>
  );
};

export default FurnishPricingIcon;
