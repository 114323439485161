import React from "react";

const WhatsappIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_4546_146)">
        <path
          d="M14.7993 11.4922C14.7603 11.4735 13.3023 10.7555 13.0432 10.6623C12.9374 10.6243 12.8241 10.5872 12.7036 10.5872C12.5067 10.5872 12.3413 10.6853 12.2125 10.878C12.0668 11.0945 11.6259 11.6099 11.4897 11.7639C11.4719 11.7842 11.4476 11.8085 11.4331 11.8085C11.42 11.8085 11.1944 11.7156 11.126 11.6859C9.56196 11.0065 8.37475 9.37265 8.21196 9.09713C8.18871 9.05752 8.18773 9.03954 8.18754 9.03954C8.19325 9.01856 8.24585 8.96584 8.27299 8.93863C8.35241 8.86006 8.43845 8.75648 8.5217 8.65629C8.56111 8.60882 8.60059 8.56129 8.63936 8.51648C8.76014 8.37597 8.8139 8.26687 8.87624 8.14051L8.9089 8.07486C9.06111 7.77246 8.93111 7.51726 8.8891 7.43486C8.85462 7.3659 8.23897 5.88006 8.17351 5.72395C8.01611 5.34726 7.80812 5.17188 7.5191 5.17188C7.49228 5.17188 7.5191 5.17187 7.40663 5.17662C7.26968 5.18239 6.5239 5.28058 6.19416 5.48843C5.84449 5.70889 5.25293 6.41162 5.25293 7.64746C5.25293 8.75973 5.95877 9.80993 6.26183 10.2093C6.26936 10.2194 6.28319 10.2399 6.30325 10.2692C7.46384 11.9641 8.91066 13.2202 10.3773 13.8062C11.7894 14.3702 12.458 14.4354 12.8381 14.4354H12.8382C12.9979 14.4354 13.1258 14.4228 13.2386 14.4117L13.3101 14.4049C13.7979 14.3617 14.8699 13.8062 15.1137 13.1286C15.3058 12.5949 15.3564 12.0118 15.2286 11.8002C15.1411 11.6563 14.9903 11.5839 14.7993 11.4922Z"
          fill="#25D366"
        />
        <path
          d="M10.1775 0C4.76089 0 0.354141 4.37364 0.354141 9.74955C0.354141 11.4883 0.819465 13.1903 1.70096 14.6799L0.0137512 19.6569C-0.0176774 19.7497 0.0056992 19.8523 0.0743356 19.9221C0.123881 19.9727 0.191089 20 0.259725 20C0.286024 20 0.312517 19.996 0.338362 19.9878L5.52797 18.3387C6.9481 19.0975 8.55317 19.498 10.1776 19.498C15.5937 19.4981 20 15.1249 20 9.74955C20 4.37364 15.5937 0 10.1775 0ZM10.1775 17.4671C8.64901 17.4671 7.16856 17.0258 5.89596 16.1907C5.85317 16.1626 5.80349 16.1482 5.75349 16.1482C5.72706 16.1482 5.70057 16.1522 5.67479 16.1604L3.07511 16.9868L3.91434 14.5108C3.94148 14.4307 3.92791 14.3423 3.87791 14.274C2.90882 12.9499 2.39654 11.3854 2.39654 9.74955C2.39654 5.49351 5.88706 2.03091 10.1775 2.03091C14.4673 2.03091 17.9575 5.49351 17.9575 9.74955C17.9575 14.0051 14.4675 17.4671 10.1775 17.4671Z"
          fill="#25D366"
        />
      </g>
      <defs>
        <clipPath id="clip0_4546_146">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsappIcon;
