// HomeEs.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";
import GoogleIcon from "./Icons/GoogleIcon.jsx";

// Images

import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import PepeGomez from "./Images/Pepe Gomez image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomeStep1 from "./Images/HomeStep1.png";
import HomeStep2 from "./Images/HomeStep2.png";
import HomeStep3 from "./Images/HomeStep3.png";

// Icons
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import ArrowIconOrange from "./Icons/ArrowIconOrange.jsx";

// Update the animation styles
const progressBarStyle = `
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fade-transition {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-transition.fade-out {
    opacity: 0;
  }
`;

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function SoftwareHomeStagingVirtualPt(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);
  const [FAQ7, setFAQ7] = useState(false);
  const [FAQ8, setFAQ8] = useState(false);
  const [FAQ9, setFAQ9] = useState(false);

  const examples = [
    {
      name: "Furnish",
      beforeImage: BeforeFurnish,
      afterImage: AfterFurnish,
      label: "Amueblar",
    },
    {
      name: "Empty",
      beforeImage: BeforeEmpty,
      afterImage: AfterEmpty,
      label: "Vaciar",
    },
    {
      name: "Renovate",
      beforeImage: BeforeRenovate,
      afterImage: AfterRenovate,
      label: "Renovar",
    },
    {
      name: "Enhance",
      beforeImage: BeforeEnhance,
      afterImage: AfterEnhance,
      label: "Embellecer",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Add styling for header
    const header = document.querySelector("header");
    if (header) {
      // Reset background color first
      header.style.backgroundColor = "#fff";

      // Update based on current path
      if (window.location.pathname.startsWith("/es")) {
        header.style.backgroundColor = "#f7f7f7";
      }
    }

    // Preload all example images
    examples.forEach((example) => {
      const beforeImg = new Image();
      const afterImg = new Image();
      beforeImg.src = example.beforeImage;
      afterImg.src = example.afterImage;
    });

    // Cleanup function to reset header style when component unmounts
    return () => {
      if (header) {
        header.style.backgroundColor = "#fff";
      }
    };
    // eslint-disable-next-line
  }, []); // Re-run when pathname changes

  // Add loading priority to first images
  const [example, setExample] = useState(examples[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progressKey, setProgressKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Start the interval when component mounts
    const newIntervalId = setInterval(() => {
      handleNextImage();
    }, 4000);
    setIntervalId(newIntervalId);

    // Cleanup interval on component unmount
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array means this only runs on mount/unmount

  const handleNextImage = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setExample((currentExample) => {
        const currentIndex = examples.findIndex(
          (ex) => ex.name === currentExample.name
        );
        const nextIndex = (currentIndex + 1) % examples.length;
        return examples[nextIndex];
      });
      setIsTransitioning(false);
      setProgressKey((prev) => prev + 1);
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Pedra",
              "url": "https://pedra.so/pt",
              "sameAs": "https://maps.app.goo.gl/uYEqAdxAUWjnucKk9",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "50"
              }
            }
          `}
        </script>
      </Helmet>
      <style>{progressBarStyle}</style>

      {/* NEW HERO */}
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px 20px 20px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <h1 className="new-hero-header">Software de home staging virtual</h1>

        <h2 className="new-hero-paragraph">
          O melhor software de home staging virtual para corretores e
          imobiliárias
        </h2>

        <button
          className="button-top-of-page"
          onClick={redirectToPage}
          style={{
            margin: "0 auto 16px",
            padding: "12px 32px",
            fontSize: "20px",
          }}
        >
          Experimente grátis
          <ArrowIcon />
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginBottom: "12px",
          }}
        >
          <div className="new-hero-reviews-container">
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <GoogleIcon />
              <span
                style={{ color: "#333", fontWeight: "400", fontSize: "16px" }}
              >
                5.0
              </span>
              <div style={{ color: "#FFD700", letterSpacing: "2px" }}>
                ★★★★★
              </div>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <img
                className="testimonials-on-header-image"
                src={VictorLaviosa}
                alt="Victor Laviosa, Diretor de Fotografia na aProperties"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={Soma}
                alt="Francisco Somacarrera, Corretor de Imóveis na Huspy"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={DanieldelaMata}
                alt="Daniel de la Mata, CEO da Actívox"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={MariaJoseGilart}
                alt="Maria José Gilart, CEO da Gilart Consultors"
              ></img>
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Emanuela Cosma"
              ></img>
            </div>
            <span style={{ color: "#333" }}> +20.000 confiam em nós</span>
          </div>
        </div>
      </div>
      <div
        className="section-one-column-left"
        style={{ margin: "20px 24px", padding: "20px 24px" }}
      >
        <div className="customer-logo-container" style={{ color: "#878787" }}>
          Equipes destas imobiliárias já confiam na Pedra:
          <CustomerLogos />
        </div>
      </div>

      <div
        style={{
          background: "linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2
              className="title-container"
              style={{
                position: "relative",
                display: "inline",
                background: "linear-gradient(90deg, #FF6B33 0%, #FF8A33 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
              }}
            >
              Funcionalidades do nosso software de home staging virtual
            </h2>
            <div className="title-secondary">
              Faça upload das suas imagens na Pedra e use nossas ferramentas de
              home staging virtual impulsionadas por IA para obter imagens e
              vídeos que acelerem suas vendas.
            </div>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="section-one-column-left" style={{ width: "100%" }}>
            <div
              className="feature-container-two-features"
              style={{ width: "100%" }}
            >
              {/* Row 1 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/ajuda/como-renovar-casas-virtualmente"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Renovação
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Renovação com seu estilo
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/home-staging-virtual"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Mobiliar
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>
              </div>

              {/* Row 2 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/ajuda/como-esvaziar-ambientes-virtualmente"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Esvaziar ambientes
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/ajuda/como-remover-objetos"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Apagar objeto
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Adicionar objeto
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 3 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/ajuda/como-adicionar-objetos-3d"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Adicionar objeto 3D
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/fotografia-imobiliaria"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Céu azul
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/fotografia-imobiliaria"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Embelezar imagem
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>
              </div>

              {/* Row 4 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/fotografia-imobiliaria"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Converter para HD
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={"/pt/render-desde-planta"}
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Render da planta
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Vídeo virtual
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 5 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div
                    className="feature-text-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={
                        "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual"
                      }
                      style={{ textDecoration: "none", padding: "0" }}
                    >
                      <h3
                        className="feature-title third"
                        style={{ fontWeight: 500 }}
                      >
                        Efeito cortina
                      </h3>
                    </Link>
                    <ArrowIconOrange />
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Remover marca d'água
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Mudar piso
                    </h3>
                  </div>
                </div>
              </div>

              {/* Row 6 */}
              <div
                className="feature-container-2-column software-home-staging-virtual"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Corrigir perspectiva
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Adicionar marca d'água
                    </h3>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div
                  className="feature-container-small third"
                  style={{ width: "100%" }}
                >
                  <div className="feature-text-container">
                    <h3 className="feature-title" style={{ fontWeight: 500 }}>
                      Legenda da foto
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Com a Pedra consegui vender um imóvel de €500k em menos de um
              dia. O apartamento que tinha à venda precisava de reforma e com a
              Pedra consegui criar renders que permitiram visualizar o potencial
              dos espaços após a reforma sem fazer a reforma!"
            </h2>
            <div className="quote-author-block">
              <img
                className="testimonials-on-header-image"
                src={EmanuelaCosma}
                alt="Foto de Emanuela Cosma"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Emanuela Cosma</div>
                <div className="quote-author-company">Corretora de Imóveis</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              Como funciona?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Passo 1 da Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Faça upload das imagens
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Faça upload de uma ou várias imagens do imóvel.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Passo 2 da Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transforme
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Clique em "Editar com IA" para transformar as imagens.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Passo 3 da Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Aguarde e baixe
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Baixe suas imagens editadas com IA.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Eu tinha que vender um apartamento que precisava de reforma, e
              usei a Pedra para criar os renders em questão de segundos. Os
              resultados foram incríveis! Me ajudou a vendê-lo muito mais
              rápido, agora eu recomendo para todo mundo."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="Logotipo da EXP para Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="Foto de José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Corretor de Imóveis</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Por 29€ por mês. <br />{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
              }}
            >
              E sem fidelização.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Plano Pro
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">29€</div>
                <div className="modal-product-plan-description-text-3">
                  por mês
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Inclui
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#F8792A" }}>
                  100 gerações de imagem
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>por mês</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Renovar e redecorar
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Esvaziar ambientes
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Mobiliar
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Remover objetos
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Aumentar resolução
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Mudar pisos
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Melhorar fotografia
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <VideoPricingIcon /> Gerar vídeos virtuais
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Plantas ilimitadas
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Compartilhamento ilimitado de projetos
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Adicionar marcas d'água
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Suporte ilimitado
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Experimente a Pedra
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Cancele quando quiser.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Compre mais imagens por 0,20€ por imagem se precisar.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Prueba Pedra <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Fundador de Pedra"
              />
              <div>
                ¿Preguntas? Envíanos un email a{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                o agenda una demo de 15min nuestro fundador Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  aquí
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoftwareHomeStagingVirtualPt;
