import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeTerraza from "./Images/curtain-effect-hero/BeforeTerraza.png";
import AfterTerraza from "./Images/curtain-effect-hero/AfterTerraza.png";
import BeforeTerrazaStaging1 from "./Images/curtain-effect/BeforeTerrazaStaging1.png";
import AfterTerrazaStaging1 from "./Images/curtain-effect/AfterTerrazaStaging1.png";
import BeforeTerrazaStaging2 from "./Images/curtain-effect/BeforeTerrazaStaging2.png";
import AfterTerrazaStaging2 from "./Images/curtain-effect/AfterTerrazaStaging2.png";
import BeforeTerrazaStaging3 from "./Images/curtain-effect/BeforeTerrazaStaging3.png";
import AfterTerrazaStaging3 from "./Images/curtain-effect/AfterTerrazaStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderTerrace() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeTerraza,
    afterImage: AfterTerraza,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeTerraza,
        afterImage: AfterTerraza,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeTerrazaStaging1,
        afterImage: AfterTerrazaStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeTerrazaStaging2,
        afterImage: AfterTerrazaStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeTerrazaStaging3,
        afterImage: AfterTerrazaStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What are automatic terrace renders?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatic terrace renders are AI-powered visualizations that transform terrace photos into professionally rendered images. This technology allows you to create photorealistic visualizations of terraces with different styles, furnishings, and layouts without the need for manual 3D modeling or complex software.",
        },
      },
      {
        "@type": "Question",
        name: "What are the benefits of automatic terrace renders?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatic terrace renders offer several benefits, including: instant transformation of outdoor spaces, the ability to visualize multiple design options quickly, high-quality photorealistic results, significant time and cost savings compared to traditional rendering, and the flexibility to experiment with different styles and configurations.",
        },
      },
      {
        "@type": "Question",
        name: "How does the automatic terrace rendering process work?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The process involves uploading photos of the current terrace to our platform, selecting desired transformations (such as changing flooring, adding furniture, or modifying layouts), and receiving photorealistic renders showing the transformed terrace within minutes. Our AI technology handles all the complex rendering work automatically.",
        },
      },
      {
        "@type": "Question",
        name: "How much do automatic terrace renders cost?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Our automatic terrace rendering service is available for €29 plus VAT per month. This plan allows you to generate up to 100 renders per month, with no long-term commitment required.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeTerrazaStaging1,
    afterImage: AfterTerrazaStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeTerrazaStaging1,
      afterImage: AfterTerrazaStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeTerrazaStaging2,
      afterImage: AfterTerrazaStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeTerrazaStaging3,
      afterImage: AfterTerrazaStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Terrace Renders</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Create photorealistic terrace renders automatically
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instant:</strong> Generate renders in seconds
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simple:</strong> No technical skills needed
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realistic:</strong> Photorealistic results
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Support:</strong> Expert assistance available
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Try Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 for 100 renders
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Before terrace render"
                  altAfter="After terrace render"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Modern Terrace
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Traditional Style
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Rustic Design
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Contemporary Look
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          TRUSTED BY OVER 20,000 PROFESSIONALS
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Create professional terrace renders{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatically
          </span>{" "}
          in seconds.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Transform terraces instantly</h3>
                <div className="feature-text">
                  Generate photorealistic renders with a single click
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Multiple decoration styles</h3>
                <div className="feature-text">
                  Visualize different styles and configurations easily
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perfect perspectives</h3>
                <div className="feature-text">
                  Automatically correct and optimize camera angles
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Natural lighting</h3>
                <div className="feature-text">
                  Perfect outdoor lighting in every render
                </div>
              </div>
            </div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Change materials</h3>
                <div className="feature-text">
                  Update flooring, walls and finishes automatically
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Clean scene setup</h3>
                <div className="feature-text">
                  Remove unwanted objects automatically
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Examples of our automatic terrace renders.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Before terrace render"
            altAfter="After terrace render"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          How do automatic terrace renders work?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 1
            </h5>
            <h2>Upload your terrace photo</h2>
            <div className="value-prop-text">
              Upload any photo of your terrace to Pedra's platform.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="How to upload terrace photos for automatic rendering in Pedra – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 2
            </h5>
            <h2>Choose your rendering options</h2>
            <div className="value-prop-text">
              Select how you want to transform your terrace.
              <p>
                Change materials, update lighting, modify layout, add outdoor
                furniture, and more.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="How to choose options for automatic terrace rendering – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 3
            </h5>
            <h2>Get your photorealistic renders</h2>
            <div className="value-prop-text">
              Receive your professionally rendered terrace images in seconds.
              <p>Download and use your renders immediately.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="How to download automatic terrace renders – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Professional terrace renders for €29 per month. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            No commitment required.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                per month
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">
              Includes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#F8792A" }}>
                100 renders
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>per month</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Transform terraces instantly
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Change materials and finishes
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Add outdoor furniture
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Automatic scene cleanup
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> High-resolution output
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Multiple decoration styles
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Natural outdoor lighting
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited projects
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Custom watermarks
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Priority support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancel anytime.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Additional renders available at €0.20 each if needed.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions about automatic terrace renders
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                What are automatic terrace renders?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Automatic terrace renders use advanced AI technology to
                transform regular terrace photos into professional,
                photorealistic visualizations. This technology allows you to
                create high-quality renders without the need for manual 3D
                modeling or complex rendering software.
                <br />
                <br />
                The system automatically handles outdoor lighting, materials,
                perspectives, and other technical aspects to produce
                professional results instantly.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What are the benefits of automatic terrace renders?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Automatic terrace renders offer numerous advantages:
                <br />
                <br />
                1. Speed: Generate renders in seconds, not hours or days
                <br />
                2. Cost-effective: No need for expensive 3D modeling software
                <br />
                3. Easy to use: No technical expertise required
                <br />
                4. Multiple options: Test different outdoor styles quickly
                <br />
                5. Photorealistic results: Professional-quality output
                <br />
                6. Flexibility: Make changes instantly
                <br />
                7. Consistency: Reliable results every time
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                What can I do with Pedra's terrace renders?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Pedra's automatic terrace rendering system offers a wide range
                of capabilities:
                <br />
                <br />
                1. Generate renders: Create photorealistic visualizations from
                any terrace photo instantly.
                <br />
                2. Lighting optimization: Automatically enhance natural lighting
                and shadows for professional results.
                <br />
                3. Material changes: Modify flooring, walls, and outdoor
                finishes with a click.
                <br />
                4. Add elements: Insert new outdoor furniture, plants, and
                decorative elements automatically.
                <br />
                5. Scene cleanup: Remove unwanted objects and clutter
                automatically.
                <br />
                6. Layout visualization: Test different terrace configurations
                quickly.
                <br />
                <br />
                All transformations are handled automatically by our AI,
                delivering professional results in seconds.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderTerrace;
