import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeSinkIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeBathroom from "./Images/curtain-effect-hero/BeforeBathroom.png";
import AfterBathroom from "./Images/curtain-effect-hero/AfterBathroom.png";
import BeforeBathroomStaging1 from "./Images/curtain-effect/BeforeBathroomStaging1.png";
import AfterBathroomStaging1 from "./Images/curtain-effect/AfterBathroomStaging1.png";
import BeforeBathroomStaging2 from "./Images/curtain-effect/BeforeBathroomStaging2.png";
import AfterBathroomStaging2 from "./Images/curtain-effect/AfterBathroomStaging2.png";
import BeforeBathroomStaging3 from "./Images/curtain-effect/BeforeBathroomStaging3.png";
import AfterBathroomStaging3 from "./Images/curtain-effect/AfterBathroomStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingBathroomEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [ejemplo, setEjemplo] = useState({
    nombre: "Moderno",
    imagenAntes: BeforeBathroom,
    imagenDespues: AfterBathroom,
  });

  const handleExample = (ejemplo) => {
    if (ejemplo === "Moderno") {
      setEjemplo({
        nombre: "Moderno",
        imagenAntes: BeforeBathroom,
        imagenDespues: AfterBathroom,
      });
    } else if (ejemplo === "Tradicional") {
      setEjemplo({
        nombre: "Tradicional",
        imagenAntes: BeforeBathroomStaging1,
        imagenDespues: AfterBathroomStaging1,
      });
    } else if (ejemplo === "Rustico") {
      setEjemplo({
        nombre: "Rustico",
        imagenAntes: BeforeBathroomStaging2,
        imagenDespues: AfterBathroomStaging2,
      });
    } else {
      setEjemplo({
        nombre: "Contemporaneo",
        imagenAntes: BeforeBathroomStaging3,
        imagenDespues: AfterBathroomStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "¿Qué es el home staging de baño?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging de baño es una técnica de marketing inmobiliario que se centra en preparar y presentar el baño de una propiedad de manera atractiva para posibles compradores o inquilinos. Esto puede incluir limpieza, organización, decoración estratégica y pequeñas mejoras para resaltar los puntos fuertes del baño y minimizar sus defectos.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuáles son los beneficios del home staging virtual para baños?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging virtual para baños ofrece varios beneficios, incluyendo: la capacidad de mostrar el potencial del baño sin hacer cambios físicos costosos, la posibilidad de experimentar rápidamente con diferentes estilos y configuraciones, mejorar la presentación en línea de la propiedad, y la oportunidad de destacar en un mercado inmobiliario competitivo.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cómo funciona el proceso de home staging virtual para baños?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El proceso de home staging virtual para baños implica subir fotos del baño actual a nuestra plataforma, seleccionar las opciones de edición deseadas (como cambiar acabados, agregar o quitar elementos, o cambiar la distribución), y luego recibir las imágenes editadas que muestran el baño transformado.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuánto cuesta el home staging virtual de baño?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El precio de nuestro servicio de home staging virtual, que incluye baños y otras áreas de la casa, es de 29€ más IVA al mes. Este plan te permite generar hasta 100 imágenes por mes, sin compromiso.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    nombre: "stagingExample1",
    imagenAntes: BeforeBathroomStaging1,
    imagenDespues: AfterBathroomStaging1,
  });

  const stagingExamples = [
    {
      nombre: "stagingExample1",
      imagenAntes: BeforeBathroomStaging1,
      imagenDespues: AfterBathroomStaging1,
    },
    {
      nombre: "stagingExample2",
      imagenAntes: BeforeBathroomStaging2,
      imagenDespues: AfterBathroomStaging2,
    },
    {
      nombre: "stagingExample3",
      imagenAntes: BeforeBathroomStaging3,
      imagenDespues: AfterBathroomStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.nombre === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging de Baño</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  El mejor home staging virtual de baño al alcance de tu mano
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rápido:</strong> Transforma tu baño en segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Fácil:</strong> Diseña baños atractivos sin esfuerzo
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Calidad:</strong> Resultados hiperrealistas para
                    baños
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Ayuda:</strong> Asistencia experta en diseño de
                    baños
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Prueba Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ por 100 imágenes
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director de Fotografía en aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agente Inmobiliario en Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO en Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO en Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Más de 100 testimonios
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={ejemplo.imagenAntes}
                  afterImage={ejemplo.imagenDespues}
                  altBefore={"Antes del home staging virtual de baño"}
                  altAfter={"Después del home staging virtual de baño"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Moderno" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Moderno")}
                    >
                      Ejemplo de baño 1
                    </div>
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Tradicional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Tradicional")}
                    >
                      Ejemplo de baño 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Rustico" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustico")}
                    >
                      Ejemplo de baño 3
                    </div>
                    <div
                      className={`hero-button ${
                        ejemplo.nombre === "Contemporaneo" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporaneo")}
                    >
                      Ejemplo de baño 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 20.000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtual de baño para{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            vender más rápido
          </span>{" "}
          cualquier tipo de propiedad.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeSinkIcon />
                <h3 className="feature-title">Renueva tu baño virtualmente</h3>
                <div className="feature-text">
                  Transforma tu baño con un clic, sin obras ni gastos
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Destaca características únicas
                </h3>
                <div className="feature-text">
                  Resalta los puntos fuertes de tu baño con texto descriptivo
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Mejora y corrige fotos de baños
                </h3>
                <div className="feature-text">
                  Optimiza la calidad y la iluminación de tus fotos de baño
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Añade accesorios elegantes</h3>
                <div className="feature-text">
                  Actualiza tu baño con accesorios modernos virtualmente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renueva acabados</h3>
                <div className="feature-text">
                  Cambia azulejos, paredes y acabados con un solo clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Limpia y organiza virtualmente
                </h3>
                <div className="feature-text">
                  Elimina el desorden y objetos no deseados para un baño
                  impecable
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Ejemplos de nuestro home staging virtual de baño.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.imagenAntes}
            afterImage={stagingExample.imagenDespues}
            altBefore={"Antes del home staging virtual de baño"}
            altAfter={"Después del home staging virtual de baño"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.nombre}
                  className={`hero-button ${
                    stagingExample.nombre === item.nombre ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.nombre)}
                >
                  <img
                    src={item.imagenAntes}
                    alt={item.nombre}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          ¿Cómo funciona el home staging virtual de baño?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 1
            </h5>
            <h2>Sube fotos de tu baño</h2>
            <div className="value-prop-text">
              Sube una o más imágenes de tu baño a Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Cómo subir fotos de baño para crear home staging virtual en Pedra - Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 2
            </h5>
            <h2>Elige opciones de transformación para tu baño</h2>
            <div className="value-prop-text">
              Selecciona entre varias opciones para renovar virtualmente tu
              baño.
              <p>
                Puedes cambiar azulejos, añadir accesorios, reorganizar el
                espacio y mucho más.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Cómo elegir opciones para crear home staging virtual de baño - Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 3
            </h5>
            <h2>Recibe y descarga tu baño transformado</h2>
            <div className="value-prop-text">
              En cuestión de minutos, obtendrás imágenes realistas de tu baño
              renovado.
              <p>
                Descarga las imágenes y úsalas para promocionar tu propiedad.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Cómo descargar el home staging virtual de baño - Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Home staging virtual de baño por 29€ al mes. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Y sin compromiso.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                al mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#F8792A" }}>
                100 generaciones de imágenes
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>al mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renueva y redecora baños
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Cambia acabados de baño
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Añade accesorios elegantes
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Elimina objetos no deseados
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumenta la resolución de fotos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Cambia el diseño del baño
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Mejora la fotografía de baño
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Planos de baño ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir proyectos ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Añade marcas de agua
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compra más imágenes a 0,20€ por imagen si las necesitas.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Preguntas y respuestas sobre el home staging de baño
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                ¿Qué es el home staging de baño?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                El home staging de baño es una técnica de marketing inmobiliario
                que se centra en preparar y presentar el baño de una propiedad
                de manera atractiva para posibles compradores o inquilinos. Esto
                puede incluir limpieza, organización, decoración estratégica y
                pequeñas mejoras para resaltar los puntos fuertes del baño y
                minimizar sus defectos.
                <br />
                <br />
                En el caso del home staging virtual de baEn el caso del home
                staging virtual de baño, utilizamos tecnología avanzada para
                transformar digitalmente el espacio, mostrando su potencial sin
                necesidad de costosos cambios físicos.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Cuáles son las ventajas del home staging virtual para baños?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                El home staging virtual para baños ofrece numerosas ventajas:
                <br />
                <br />
                1. Ahorro de costes: No se necesitan renovaciones físicas
                costosas.
                <br />
                2. Ahorro de tiempo: Las transformaciones se realizan en
                minutos, no en semanas.
                <br />
                3. Versatilidad: Puedes mostrar varios estilos y configuraciones
                del mismo baño.
                <br />
                4. Atractivo visual: Ayuda a los compradores a visualizar el
                potencial del baño.
                <br />
                5. Diferenciación en el mercado: Destaca con imágenes atractivas
                de baños.
                <br />
                6. Sin interrupciones: No hay necesidad de interrumpir la vida
                de los ocupantes actuales.
                <br />
                7. Mejora de la presentación en línea: Ideal para listados de
                propiedades en línea.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Qué puedo hacer con el home staging de baño de Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Con el home staging virtual de baño de Pedra, puedes realizar
                una gran variedad de transformaciones:
                <br />
                <br />
                1. Renovar baños: Puedes utilizar los estilos predefinidos de
                Pedra o crear tus propios estilos personalizados para
                transformar completamente la apariencia del baño.
                <br />
                2. Mejorar la luz y el color: Optimiza la iluminación y los
                colores de las fotografías del baño para hacerlas más atractivas
                y profesionales.
                <br />
                3. Eliminar objetos: Borra elementos no deseados de las fotos,
                como accesorios antiguos, desorden u objetos personales.
                <br />
                4. Añadir elementos: Incorpora nuevos objetos al baño, como
                accesorios modernos, decoración o complementos.
                <br />
                5. Cambiar acabados: Modifica la apariencia de azulejos, paredes
                y otros acabados para mostrar diferentes opciones de diseño.
                <br />
                6. Reorganizar el espacio: Visualiza diferentes distribuciones
                del baño sin necesidad de cambios físicos.
                <br />
                <br />
                Todas estas transformaciones se realizan virtualmente,
                permitiéndote mostrar rápida y económicamente el potencial del
                baño.
              </div>
            )}
          </div>

          {/* Añade más preguntas frecuentes aquí */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingBathroomEs;
