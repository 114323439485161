import React from "react";

function ApiDocsFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Documentation de l'API Pedra.so</h1>
          <h2 className="title-secondary gray">
            API pour créer des images de mise en scène virtuelle de maisons
          </h2>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <p>
              Bienvenue dans la documentation officielle de l'API Pedra.so.
              Notre API offre de puissantes capacités de traitement d'images
              pour diverses tâches d'amélioration et de conception de pièces.
            </p>
            <br />
            <h2 className="article-subtitleh2">URLs de Base</h2>
            <p>L'API peut être accédée via l'URL de base suivante :</p>
            <ul>
              <li>
                Production : <code>https://app.pedra.so/api</code>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Authentification</h2>
            <p>
              Tous les appels API nécessitent un paramètre <code>apiKey</code>{" "}
              pour l'authentification. Envoyez un e-mail à felix@pedra.so pour
              demander votre clé API.
            </p>
            <br />
            <h2 className="article-subtitleh2">Paramètres Communs</h2>
            <p>
              Les paramètres suivants sont communs à tous les points d'accès :
            </p>
            <ul>
              <li>
                <code>apiKey</code> (chaîne, obligatoire) : Votre clé API unique
                pour l'authentification.
              </li>
              <li>
                <code>imageUrl</code> (chaîne, obligatoire) : URL de l'image à
                traiter.
              </li>
              <li>
                <code>creativity</code> (chaîne, obligatoire) : Niveau de
                liberté créative pour l'IA.
                <ul>
                  <li>
                    <strong>Faible</strong> : L'architecture est préservée avec
                    peu de variations.
                  </li>
                  <li>
                    <strong>Moyenne</strong> : L'architecture est maintenue avec
                    des variations modérées.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Points d'Accès</h2>

            <h3 className="article-subtitle">Exécuter l'Amélioration</h3>
            <p>
              Améliore la qualité, la couleur, la lumière et la résolution de
              l'image fournie.
            </p>
            <p>
              <strong>Point d'accès :</strong> <code>/enhance</code>
            </p>
            <p>
              <strong>Méthode :</strong> POST
            </p>
            <p>
              <strong>Paramètres :</strong> Paramètres communs (voir ci-dessus)
            </p>
            <p>
              <strong>Temps de réponse attendu :</strong> Entre 25 secondes et 1
              minute
            </p>

            <br />
            <h3 className="article-subtitle">Exécuter Pièce Vide</h3>
            <p>
              Supprime les meubles et les objets de la pièce dans l'image
              fournie.
            </p>
            <p>
              <strong>Point d'accès :</strong> <code>/empty_room</code>
            </p>
            <p>
              <strong>Méthode :</strong> POST
            </p>
            <p>
              <strong>Paramètres :</strong> Paramètres communs (voir ci-dessus)
            </p>
            <p>
              <strong>Temps de réponse attendu :</strong> Entre 2 et 3 minutes
            </p>
            <br />
            <h3 className="article-subtitle">Exécuter l'Ameublement</h3>
            <p>
              Meuble (crée une mise en scène virtuelle) d'un espace selon les
              paramètres spécifiés.
            </p>
            <p>
              <strong>Point d'accès :</strong> <code>/furnish</code>
            </p>
            <p>
              <strong>Méthode :</strong> POST
            </p>
            <p>
              <strong>Paramètres :</strong>
            </p>
            <ul>
              <li>Paramètres communs (voir ci-dessus)</li>
              <li>
                <code>roomType</code> (chaîne, obligatoire) : Type de pièce à
                meubler.
              </li>
              <li>
                <code>style</code> (chaîne, obligatoire) : Style de design à
                appliquer.
              </li>
            </ul>
            <p>
              <strong>Temps de réponse attendu :</strong> Entre 2 et 3 minutes
            </p>

            <h4>
              Valeurs possibles pour <code>roomType</code> :
            </h4>
            <ul>
              <li>"Bedroom" (Chambre à coucher)</li>
              <li>"Living room" (Salon)</li>
              <li>"Kitchen" (Cuisine)</li>
              <li>"Office" (Bureau)</li>
              <li>"Terrace" (Terrasse)</li>
              <li>"Dining room" (Salle à manger)</li>
              <li>"Kitchen + Living room" (Cuisine + Salon)</li>
              <li>"Dining + Living room" (Salle à manger + Salon)</li>
              <li>"Entrance" (Entrée)</li>
            </ul>

            <h4>
              Valeurs possibles pour <code>style</code> :
            </h4>
            <ul>
              <li>"Traditional" (Traditionnel)</li>
              <li>"Minimalist" (Minimaliste)</li>
              <li>"Scandinavian" (Scandinave)</li>
              <li>"Mid-century" (Milieu du siècle)</li>
              <li>"Bohemian" (Bohème)</li>
              <li>"Industrial" (Industriel)</li>
              <li>"Mediterranean" (Méditerranéen)</li>
              <li>"Modern" (Moderne)</li>
              <li>"Pyrenees" (Pyrénées)</li>
            </ul>
            <br />
            <h3 className="article-subtitle">Exécuter la Rénovation</h3>
            <p>Rénove une pièce selon le style spécifié.</p>
            <p>
              <strong>Point d'accès :</strong> <code>/renovation</code>
            </p>
            <p>
              <strong>Méthode :</strong> POST
            </p>
            <p>
              <strong>Paramètres :</strong>
            </p>
            <ul>
              <li>Paramètres communs (voir ci-dessus)</li>
              <li>
                <code>style</code> (chaîne, obligatoire) : Style de design à
                appliquer pour la rénovation.
              </li>
              <li>
                <code>preserveWindows</code> (booléen, obligatoire) : S'il faut
                préserver les fenêtres existantes pendant la rénovation.
              </li>
            </ul>
            <p>
              <strong>Temps de réponse attendu :</strong> Entre 25 secondes et 2
              minutes
            </p>
            <br />
            <h2 className="article-subtitleh2">Format de Réponse</h2>
            <p>
              Tous les points d'accès de l'API renvoient des réponses JSON. Une
              réponse réussie aura un code d'état 200 et inclura les
              informations de l'image traitée.
            </p>
            <br />
            <h2 className="article-subtitleh2">Gestion des Erreurs</h2>
            <p>
              En cas d'erreur, l'API renverra un code d'état HTTP approprié avec
              une réponse JSON contenant les détails de l'erreur.
            </p>
            <br />
            <h2 className="article-subtitleh2">Support</h2>
            <p>
              Pour toute question ou problème concernant l'API, veuillez
              contacter notre équipe de support à felix@pedra.so.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApiDocsFr;
