import React, { useState, useEffect } from "react";
import CheckIcon from "../Icons/CheckIcon";
import MagicWandIconPurple from "../Icons/MagicWandIconPurple";
import EmptyIcon from "../Icons/EmptyIcon";
import HammerIconPricing from "../Icons/HammerIconPricing";
import BrushIconPricing from "../Icons/BrushIconPricing";
import HDIconPricing from "../Icons/HDIconPricing";
import RemoveObjectPricingIcon from "../Icons/RemoveObjectPricingIcon";
import FurnishPricingIcon from "../Icons/FurnishPricingIcon";
import PhotographyPricingIcon from "../Icons/PhotographyIconPricing";
import WallChangePricingIcon from "../Icons/WallChangePricingIcon";
import VideoPricingIcon from "../Icons/VideoPricingIcon";
import CustomerLogos from "../CustomerLogos.js";
import ArrowIcon from "../Icons/ArrowIcon";
import DoorPricingIcon from "../Icons/DoorPricingIcon";
import HumanPricingIcon from "../Icons/HumanPricingIcon";

// Format price based on currency
const formatPrice = (pricing) => {
  const amount = pricing.amount;
  const currency = pricing.currency;

  switch (currency) {
    case "$":
      return `${currency}${amount}`; // USD: $29
    case "€":
      return `${amount}€`; // EUR: 29€
    case "MXN":
      return `$${amount} ${currency}`; // MXN: $399.00 MXN
    case "S/":
      return `${currency} ${amount}`; // PEN: S/ 76.00
    case "COP":
      return `$${amount} ${currency}`; // COP: $85,000 COP
    case "Bs.":
      return `${currency} ${amount}`; // BOB: Bs. 132.00
    case "CLP":
      return `$${amount} ${currency}`; // CLP: $19,500 CLP
    default:
      return `${currency}${amount}`;
  }
};

// Pricing data for different regions
const regionPricing = {
  default: {
    amount: "29",
    currency: "€",
    manualService: { amount: "6", currency: "€" },
    extraImage: { amount: "0.20", currency: "€" },
  },
  US: {
    amount: "29",
    currency: "$",
    manualService: { amount: "6.50", currency: "$" },
    extraImage: { amount: "0.22", currency: "$" },
  },
  MX: {
    amount: "399.00",
    currency: "MXN",
    manualService: { amount: "131.72", currency: "MXN" },
    extraImage: { amount: "4.37", currency: "MXN" },
  },
  PE: {
    amount: "76.00",
    currency: "S/",
    manualService: { amount: "23.32", currency: "S/" },
    extraImage: { amount: "0.79", currency: "S/" },
  },
  CO: {
    amount: "85,000",
    currency: "COP",
    manualService: { amount: "27,700", currency: "COP" },
    extraImage: { amount: "0.93", currency: "COP" },
  },
  BO: {
    amount: "132.00",
    currency: "Bs.",
    manualService: { amount: "27.700", currency: "Bs." },
    extraImage: { amount: "1.40", currency: "Bs." },
  },
  CL: {
    amount: "19,500",
    currency: "CLP",
    manualService: { amount: "42.92", currency: "CLP" },
    extraImage: { amount: "160", currency: "CLP" },
  },
  // Add more countries as needed
};

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function PricingCardPt() {
  const [pricing, setPricing] = useState(regionPricing.default);

  useEffect(() => {
    const detectUserLocation = async () => {
      try {
        // Using geojs.io which has better CORS support
        const response = await fetch("https://get.geojs.io/v1/ip/country.json");

        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`);
        }

        const data = await response.json();

        if (data && data.country) {
          // geojs returns ISO country code in data.country
          setPricing(regionPricing[data.country] || regionPricing.default);
        } else {
          setPricing(regionPricing.default);
        }
      } catch (error) {
        setPricing(regionPricing.default);
      }
    };

    detectUserLocation();
  }, []);

  const formattedPrice = formatPrice(pricing);

  return (
    <div className="modal-product-tiers-container">
      <div className="modal-product-tier-current">
        <div className="modal-product-plan-description">
          <div className="modal-product-plan-description-text-1">Plano Pro</div>
          <div className="modal-product-plan-description-text-2-3-container">
            <div className="modal-product-plan-description-text-2">
              {formattedPrice}
            </div>
            <div className="modal-product-plan-description-text-3">por mês</div>
          </div>
        </div>

        <div className="modal-product-plan-description">
          <div className="modal-product-plan-description-text-4">Inclui</div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />{" "}
            <span style={{ fontWeight: "500", color: "#F8792A" }}>
              100 gerações de imagem
            </span>{" "}
            <MagicWandIconPurple />
            <div style={{ marginLeft: "2px" }}>por mês</div>
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <HammerIconPricing /> Renovar e redecorar
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <BrushIconPricing /> Esvaziar quartos
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <FurnishPricingIcon /> Mobiliar
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <RemoveObjectPricingIcon /> Remover objetos
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <HDIconPricing /> Aumentar resolução
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <WallChangePricingIcon /> Mudar pisos
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <PhotographyPricingIcon /> Melhorar fotografia
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <VideoPricingIcon /> Gerar vídeos virtuais
          </div>
          <div className="modal-product-plan-description-text-4">
            <EmptyIcon />
            <DoorPricingIcon /> Renderizar plantas
          </div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />
            Desenhar plantas ilimitadas
          </div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />
            Vídeos antes/depois ilimitados
          </div>
          <div className="modal-product-plan-description-text-4">
            <CheckIcon />
            Suporte ilimitado
          </div>
          <div className="modal-product-plan-description-text-4">
            <HumanPricingIcon />
            Edição profissional de imagem (desde{" "}
            {formatPrice(pricing.manualService)})
          </div>
          <div className="pricing-modal-button-container">
            <button className="button-top-of-page" onClick={redirectToPage}>
              Experimente Pedra
              <ArrowIcon />
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <div>Cancele quando quiser.</div>
          <div style={{ fontSize: "12px", lineHeight: "100%" }}>
            Compre mais imagens a {formatPrice(pricing.extraImage)} por imagem
            se precisar.
          </div>
        </div>
      </div>
    </div>
  );
}
export default PricingCardPt;
