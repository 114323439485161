import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageRoomUnderConstruction.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToRenovateFrame1.jpg";

function HowToVirtualStagingConstruction() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to transform images of properties under construction
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to create attractive virtual home stagings for
            properties under construction
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I improve the presentation of a property under
              construction?
            </h2>
            <p className="article-text">
              With Pedra you can transform images of properties under
              construction into attractive virtual home stagings in just a few
              clicks. To begin, access the{" "}
              <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu and
              select <span style={{ fontWeight: "500" }}>"Renovation"</span>.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="How to create a virtual home staging of a property under construction – Examples"
            />
            <br />
            <h2 className="article-subtitleh2">
              Steps to transform an image of a property under construction
            </h2>
            <p className="article-text">
              To achieve an attractive visualization of your property under
              construction, follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the image of the space under construction that you want
                to transform
              </li>
              <li>Select the image to start editing</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then on <span style={{ fontWeight: "500" }}>"Renovation"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Construction renovation menu"
            />
            <p className="article-text">
              In the <span style={{ fontWeight: "500" }}>"Renovation"</span>{" "}
              menu you should follow these steps:
            </p>
            <ol>
              <li>
                Select a <span style={{ fontWeight: "500" }}>style</span> as a
                reference for the final result
              </li>
              <li>
                Enable the{" "}
                <span style={{ fontWeight: "500" }}>"Preserve windows"</span>{" "}
                option to maintain the original structure
              </li>
              <li>
                Enable <span style={{ fontWeight: "500" }}>"Furnish"</span> to
                include furniture in the image
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>space type</span>{" "}
                according to the room (bedroom, living room, etc.)
              </li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Generate image"</span> and
                wait a few minutes for the process to complete
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for better results:
                <ul>
                  <li>
                    Make sure the photograph clearly shows the structure of the
                    space
                  </li>
                  <li>
                    You can first "Furnish" and then "Renovate" to get better
                    results
                  </li>
                  <li>
                    Choose a style that matches the target buyer's profile
                  </li>
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Why transform images of properties under construction?
            </h2>
            <p className="article-text">
              Presenting images of properties under construction can make
              selling more difficult. By transforming these images into
              attractive renders you will:
            </p>
            <ul>
              <li>Help buyers visualize the final result</li>
              <li>Generate more qualified leads</li>
              <li>Speed up the sales process</li>
              <li>Stand out from other new construction projects</li>
            </ul>
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingConstruction;
