import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeSinkIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeBathroom from "./Images/curtain-effect-hero/BeforeBathroom.png";
import AfterBathroom from "./Images/curtain-effect-hero/AfterBathroom.png";
import BeforeBathroomStaging1 from "./Images/curtain-effect/BeforeBathroomStaging1.png";
import AfterBathroomStaging1 from "./Images/curtain-effect/AfterBathroomStaging1.png";
import BeforeBathroomStaging2 from "./Images/curtain-effect/BeforeBathroomStaging2.png";
import AfterBathroomStaging2 from "./Images/curtain-effect/AfterBathroomStaging2.png";
import BeforeBathroomStaging3 from "./Images/curtain-effect/BeforeBathroomStaging3.png";
import AfterBathroomStaging3 from "./Images/curtain-effect/AfterBathroomStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingBathroomDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeBathroom,
    afterImage: AfterBathroom,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeBathroom,
        afterImage: AfterBathroom,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeBathroomStaging1,
        afterImage: AfterBathroomStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeBathroomStaging2,
        afterImage: AfterBathroomStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeBathroomStaging3,
        afterImage: AfterBathroomStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was ist Badezimmer Home Staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Badezimmer Home Staging ist eine Immobilienmarketing-Technik, die sich darauf konzentriert, das Badezimmer einer Immobilie für potenzielle Käufer oder Mieter attraktiv vorzubereiten und zu präsentieren. Dies kann Reinigung, Organisation, strategische Dekoration und kleine Verbesserungen umfassen, um die Stärken des Badezimmers hervorzuheben und seine Schwächen zu minimieren.",
        },
      },
      {
        "@type": "Question",
        name: "Was sind die Vorteile von virtuellem Home Staging für Badezimmer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Virtuelles Home Staging für Badezimmer bietet mehrere Vorteile, darunter: die Möglichkeit, das Potenzial des Badezimmers ohne kostspielige physische Änderungen zu zeigen, die Fähigkeit, schnell mit verschiedenen Stilen und Konfigurationen zu experimentieren, die Verbesserung der Online-Präsentation der Immobilie und die Möglichkeit, sich in einem wettbewerbsintensiven Immobilienmarkt abzuheben.",
        },
      },
      {
        "@type": "Question",
        name: "Wie funktioniert der virtuelle Home Staging-Prozess für Badezimmer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der virtuelle Home Staging-Prozess für Badezimmer beinhaltet das Hochladen von Fotos des aktuellen Badezimmers auf unsere Plattform, die Auswahl gewünschter Bearbeitungsoptionen (wie das Ändern von Oberflächen, Hinzufügen oder Entfernen von Elementen oder Ändern des Layouts) und dann den Erhalt der bearbeiteten Bilder, die das transformierte Badezimmer zeigen.",
        },
      },
      {
        "@type": "Question",
        name: "Wie viel kostet virtuelles Badezimmer Home Staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der Preis für unseren virtuellen Home Staging-Service, der Badezimmer und andere Bereiche des Hauses umfasst, beträgt 29 € plus MwSt. pro Monat. Dieser Plan ermöglicht es Ihnen, bis zu 100 Bilder pro Monat zu generieren, ohne Verpflichtung.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeBathroomStaging1,
    afterImage: AfterBathroomStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeBathroomStaging1,
      afterImage: AfterBathroomStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeBathroomStaging2,
      afterImage: AfterBathroomStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeBathroomStaging3,
      afterImage: AfterBathroomStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Badezimmer Home Staging</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Das beste virtuelle Badezimmer Home Staging auf Knopfdruck
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Schnell:</strong> Transformieren Sie Ihr Badezimmer
                    in Sekunden
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Gestalten Sie attraktive
                    Badezimmer mühelos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualität:</strong> Hyperrealistische Ergebnisse für
                    Badezimmer
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Hilfe:</strong> Experten-Unterstützung bei der
                    Badezimmergestaltung
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29 € für 100 Bilder
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Direktor der Fotografie bei aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO bei Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO bei Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Über 100 Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Vor virtuellem Badezimmer Home Staging"}
                  altAfter={"Nach virtuellem Badezimmer Home Staging"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Badezimmer 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Badezimmer 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Badezimmer 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Badezimmer 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          ÜBER 20.000 IMMOBILIENPROFIS VERTRAUEN BEREITS AUF PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Virtuelles Badezimmer Home Staging zum{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            schnelleren Verkauf
          </span>{" "}
          jeder Art von Immobilie.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeSinkIcon />
                <h3 className="feature-title">
                  Renovieren Sie Ihr Badezimmer virtuell
                </h3>
                <div className="feature-text">
                  Transformieren Sie Ihr Badezimmer mit einem Klick, ohne
                  Arbeiten oder Ausgaben
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Besondere Merkmale hervorheben
                </h3>
                <div className="feature-text">
                  Betonen Sie die Stärken Ihres Badezimmers mit beschreibendem
                  Text
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Badezimmerfotos verbessern und korrigieren
                </h3>
                <div className="feature-text">
                  Optimieren Sie die Qualität und Beleuchtung Ihrer
                  Badezimmerfotos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Stilvolle Armaturen hinzufügen
                </h3>
                <div className="feature-text">
                  Aktualisieren Sie Ihr Badezimmer virtuell mit modernen
                  Armaturen
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Oberflächen erneuern</h3>
                <div className="feature-text">
                  Ändern Sie Fliesen, Wände und Oberflächen mit einem Klick
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Virtuell aufräumen und organisieren
                </h3>
                <div className="feature-text">
                  Entfernen Sie Unordnung und unerwünschte Objekte für ein
                  makelloses Badezimmer
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Beispiele unseres virtuellen Badezimmer Home Stagings.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Vor virtuellem Badezimmer Home Staging"}
            altAfter={"Nach virtuellem Badezimmer Home Staging"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Wie funktioniert virtuelles Badezimmer Home Staging?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Laden Sie Fotos Ihres Badezimmers hoch</h2>
            <div className="value-prop-text">
              Laden Sie ein oder mehrere Bilder Ihres Badezimmers bei Pedra
              hoch.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Badezimmerfotos hochlädt, um virtuelles Home Staging auf Pedra zu erstellen - Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>Wählen Sie Transformationsoptionen für Ihr Badezimmer</h2>
            <div className="value-prop-text">
              Wählen Sie aus mehreren Optionen, um Ihr Badezimmer virtuell zu
              renovieren.
              <p>
                Sie können Fliesen ändern, Armaturen hinzufügen, den Raum neu
                organisieren und vieles mehr.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Wie man Optionen wählt, um virtuelles Badezimmer Home Staging zu erstellen - Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>
              Erhalten und laden Sie Ihr transformiertes Badezimmer herunter
            </h2>
            <div className="value-prop-text">
              In wenigen Minuten erhalten Sie realistische Bilder Ihres
              renovierten Badezimmers.
              <p>
                Laden Sie die Bilder herunter und nutzen Sie sie zur Vermarktung
                Ihrer Immobilie.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Wie man virtuelles Badezimmer Home Staging herunterlädt - Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Virtuelles Badezimmer Home Staging für 29 € pro Monat. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Und keine Bindung.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29 €</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Enthält</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#F8792A" }}>
                100 Bildgenerierungen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Badezimmer renovieren und neu gestalten
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Badezimmer-Oberflächen ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Stilvolle Armaturen hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Unerwünschte Objekte entfernen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Fotoauflösung erhöhen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Badezimmerdesign ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Badezimmerfotografie verbessern
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Badezimmerpläne
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Projekt-Sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Wasserzeichen hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzter Support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Jederzeit kündbar.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Kaufen Sie bei Bedarf weitere Bilder für 0,20 € pro Bild.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Fragen und Antworten zum Badezimmer Home Staging
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Was ist Badezimmer Home Staging?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Badezimmer Home Staging ist eine Immobilienmarketing-Technik,
                die sich darauf konzentriert, das Badezimmer einer Immobilie für
                potenzielle Käufer oder Mieter attraktiv vorzubereiten und zu
                präsentieren. Dies kann Reinigung, Organisation, strategische
                Dekoration und kleine Verbesserungen umfassen, um die Stärken
                des Badezimmers hervorzuheben und seine Schwächen zu minimieren.
                <br />
                <br />
                Im Fall von virtuellem Badezimmer Home Staging nutzen wir
                fortschrittliche Technologie, um den Raum digital zu
                transformieren und sein Potenzial ohne kostspielige physische
                Änderungen zu zeigen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was sind die Vorteile von virtuellem Home Staging für
                Badezimmer?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Virtuelles Home Staging für Badezimmer bietet zahlreiche
                Vorteile:
                <br />
                <br />
                1. Kosteneinsparung: Keine teuren physischen Renovierungen
                nötig.
                <br />
                2. Zeitersparnis: Transformationen erfolgen in Minuten, nicht in
                Wochen.
                <br />
                3. Vielseitigkeit: Sie können mehrere Stile und Konfigurationen
                desselben Badezimmers zeigen.
                <br />
                4. Visuelle Attraktivität: Hilft Käufern, das Potenzial des
                Badezimmers zu visualisieren.
                <br />
                5. Marktdifferenzierung: Heben Sie sich mit attraktiven
                Badezimmerbildern ab.
                <br />
                6. Keine Störung: Keine Unterbrechung des Alltags der aktuellen
                Bewohner.
                <br />
                7. Verbesserte Online-Präsentation: Ideal für
                Online-Immobilienanzeigen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Was kann ich mit Pedras Badezimmer Home Staging machen?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Mit Pedras virtuellem Badezimmer Home Staging können Sie eine
                Vielzahl von Transformationen durchführen:
                <br />
                <br />
                1. Badezimmer renovieren: Sie können Pedras vordefinierte Stile
                verwenden oder Ihre eigenen benutzerdefinierten Stile erstellen,
                um das Erscheinungsbild des Badezimmers komplett zu
                transformieren.
                <br />
                2. Licht und Farbe verbessern: Optimieren Sie die Beleuchtung
                und Farben von Badezimmerfotos, um sie attraktiver und
                professioneller erscheinen zu lassen.
                <br />
                3. Objekte entfernen: Löschen Sie unerwünschte Elemente aus
                Fotos, wie alte Armaturen, Unordnung oder persönliche
                Gegenstände.
                <br />
                4. Elemente hinzufügen: Fügen Sie dem Badezimmer neue Objekte
                hinzu, wie moderne Armaturen, Dekoration oder Accessoires.
                <br />
                5. Oberflächen ändern: Modifizieren Sie das Aussehen von
                Fliesen, Wänden und anderen Oberflächen, um verschiedene
                Designoptionen zu zeigen.
                <br />
                6. Raum neu organisieren: Visualisieren Sie verschiedene
                Badezimmer-Layouts ohne physische Änderungen.
                <br />
                <br />
                Alle diese Transformationen erfolgen virtuell und ermöglichen es
                Ihnen, das Potenzial des Badezimmers schnell und wirtschaftlich
                zu zeigen.
              </div>
            )}
          </div>

          {/* Add more FAQs here */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingBathroomDe;
